export function utledFagforeningskontigent(poster, erEktefelle, queryParams) {

  const fagforeningskontigent = poster.find(post => post.get('tekniskNavn') === 'inntektsfradragForFagforeningskontingent');
  const tolvdelVedArbeidsoppholdINorge = queryParams && queryParams[erEktefelle ? 'tolvdelVedArbeidsoppholdINorgeEF' : 'tolvdelVedArbeidsoppholdINorge'] || "12"

  if (!fagforeningskontigent) {
    return undefined;
  }
  const beloep = fagforeningskontigent.get('beloep');

  return !beloep
    ? undefined
    : {
      "tolvdelVedArbeidsoppholdIUtlandet": tolvdelVedArbeidsoppholdINorge,
      "betaltFagforeningskontingent": {
        "beloep": beloep
      }
    }
}

