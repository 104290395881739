import React, { Component } from 'react';
import { fromJS } from 'immutable';
import kategori_arbeid_pensjon from './img/kategori_arbeid_pensjon.svg';
import kategori_laan_formue_aksjer from './img/kategori_laan_formue_aksjer.svg';
import kategori_bolig_eiendeler from './img/kategori_bolig_eiendeler.svg';
import kategori_naering from './img/kategori_naering.svg';
import kategori_andre_fradrag from './img/kategori_andre_fradrag.svg';
import kategori_familie_helse from './img/kategori_familie_helse.svg';
import { hentPostdata } from './postlister/poster';
import shortid from 'shortid';
import Toppmeny from './components/Toppmeny/Toppmeny';
import byggNyPost from './felles/byggNyPost';
import Beregning from './components/Beregning/Beregning';
import queryString from 'query-string';
import beregnSkattPaaDetMesteHvertSekund from './http/beregnSkatt';
import byggStandardposter from './felles/byggStandardposter';
import { validerPoster } from './validering/poster/valider.poster';
import { getInntektsaar, giMainFokus, viserForsiden } from './felles/utils';
import { byggTekster, i18n } from './i18n/tekster';
import { getLocale } from './i18n/getLocale';
import Soek from './components/Soek/Soek';
import { kategori } from './felles/sidekategorier';
import ActionButton from '@skatteetaten/frontend-components/ActionButton';
import Grid from '@skatteetaten/frontend-components/Grid'
import { UtledSkvisVersjon } from './felles/UtledSkvisVersjon';
import Gruppe from './components/Gruppe/Gruppe';
import Tabs from './components/Tabs/Tabs';
import Styles from './app.module.css';
import utledResultatversjon from './http/fastsetting/utledResultatversjon';
import { utledSkattegrunnlagsobjektversjon } from './http/beregning/utledSkattegrunnlagsobjektversjon';
import Skattesubjekt from './Skattesubjekt';
import Footer from './components/Footer/Footer';
import MessageBar from '@skatteetaten/frontend-components/MessageBar';

const queryParams = queryString.parse(window.location.search);

const gruppeImg = {
  [kategori.arbeidTrygdPensjon.id]: kategori_arbeid_pensjon,
  [kategori.bankLaanForsikring.id]: kategori_laan_formue_aksjer,
  [kategori.boligOgEiendeler.id]: kategori_bolig_eiendeler,
  [kategori.naering.id]: kategori_naering,
  [kategori.finans.id]: kategori_andre_fradrag,
  [kategori.familieOgHelse.id]: kategori_familie_helse,
};

class App extends Component {

  state = {
    data: fromJS({
      aapnePostvelgere: {},
      allePostdata: hentPostdata(),
      poster: byggStandardposter(hentPostdata()),
      posterEktefelle: byggStandardposter(hentPostdata()),
      viserEktefelle: false,
      erGift: queryParams.sivilstand === 'GIFT',
      valideringer: [],
      inntektsaar: getInntektsaar(),
      locale: getLocale(),
      fastsettingHovedperson: {},
      fastsettingEktefelle: {},
      viserForsiden: viserForsiden()
    }),
  };

  componentDidMount() {

    byggTekster(getLocale());

    const sessionStoragePostdata = typeof Storage !== 'undefined'
      ? sessionStorage.getItem(getInntektsaar() + '_ny_skattekalkulator')
      : null;

    if (sessionStoragePostdata) {
      const dataFraSessionMedKorrektInntektsaar = fromJS(JSON.parse(sessionStoragePostdata))
        .set('inntektsaar', getInntektsaar())
        .set('erGift', queryParams.sivilstand === 'GIFT')
        .set('locale', getLocale())
        .set('viserEktefelle', false);
      this.setState({ data: dataFraSessionMedKorrektInntektsaar }, this.validerOgKjoerBeregnSkatt);
    }

    window.addEventListener('popstate',() => {
      this.updateData(
        this.getData().set('viserForsiden', viserForsiden())
      );
      giMainFokus();
    });
  }

  viserEktefelle = () => {
    return this.getData().get('viserEktefelle');
  }

  getPosterKey = () => {
    return this.viserEktefelle() ? 'posterEktefelle' : 'poster';
  }

  togglePostvelgerAapen = key => {
    const keyPath = ['aapnePostvelgere', key];
    this.setState({
      data: this.state.data.setIn(keyPath, !this.state.data.getIn(keyPath)),
    });
  };

  getPoster = kategori => {
    if (kategori) {
      return this.state.data
        .get(this.getPosterKey())
        .filter(x => x.getIn(['temakategori']) === kategori);
    }
    return this.state.data.get(this.getPosterKey());
  };

  getData = () => {
    return this.state.data;
  };

  updateData = (data, callback) => {
    if (typeof Storage !== 'undefined') {
      sessionStorage.setItem(getInntektsaar() + '_ny_skattekalkulator', JSON.stringify(data));
    }
    const sessionStorageSkattesubjekt = typeof Storage !== 'undefined'
      ? sessionStorage.getItem('skattesubjekt')
      : null;

    if (sessionStorageSkattesubjekt) {
      this.updateLocaleForSkattesubjekt(data.get('locale'), sessionStorageSkattesubjekt);
    }
    this.setState({ data }, callback);
  };

  updateLocaleForSkattesubjekt(locale, sessionStorageSkattesubjekt) {
    let eksisterendeSkattesubjekt = JSON.parse(sessionStorageSkattesubjekt);

    Object.keys({ locale: locale }).forEach(function(val, key) {
      eksisterendeSkattesubjekt[val] = { locale: locale }[val];
    });
    sessionStorage.setItem('skattesubjekt', JSON.stringify(eksisterendeSkattesubjekt));
  }

  onChange = (id, value) => {
    this.updateData(
      this.getData().update(
        this.getPosterKey(),
        poster => poster.map(post => (post.get('id') === id ? post.set('beloep', value) : post))
      ),
      this.validerOgKjoerBeregnSkatt
    );
  };

  onChangeLocale = nyLocale => {
    byggTekster(nyLocale);
    this.updateData(this.getData()
      .set('locale', nyLocale)
      // Maa oppdatere allePostdata pga. et ledetekst felt som maa oversettes for at soek skal fungere.
      // Burde haendteres paa en bedre maate naar man har tid til aa forbedre state-management logikken.
      .set('allePostdata', hentPostdata())
    );
  };

  beregnSkatt = () => {
    beregnSkattPaaDetMesteHvertSekund(
      this.getData(),
      response => {
        this.updateData(
          this.getData()
            .set('beregning', response.get('hovedperson'))
            .set('beregningEktefelle', response.get('ektefelle'))
        );
      },
      (response, noekkel) => {
        this.updateData(
          this.getData()
            .set(noekkel, response.get(utledResultatversjon(getInntektsaar())))
        );
      });
  };

  alleTilgjengeligePoster = () => {
    // Unntak for noen pensjonsposter som skal kunne legges til flere ganger
    const finnesAllerede = this.getData()
      .get(this.getPosterKey())
      .map(post => post.getIn(['tekniskNavn']))
      .filter(tekniskNavn =>
        tekniskNavn !== 'avtalefestetPensjon' &&
        tekniskNavn !== 'alderspensjonFraFolketrygden' &&
        tekniskNavn !== 'pensjonFraUtlandetSkattefriINorge' &&
        tekniskNavn !== 'pensjonFraUtlandetSkattefriINorge'
      );
    return this.getData()
      .getIn(['allePostdata'])
      .filter(post => !finnesAllerede.includes(post.getIn(['tekniskNavn'])));
  };

  tilgjengeligePoster = () => {
    return this.alleTilgjengeligePoster()
      .sort((postA, postB) => {
        if (postA.get('sorteringsNoekkel') > postB.get('sorteringsNoekkel')) return 1;
        if (postA.get('sorteringsNoekkel') < postB.get('sorteringsNoekkel')) return -1;
        return 0;
      })
      .groupBy(post => post.getIn(['temakategori']));
  };

  onLeggTilPost = (kategoriId, tekniskNavn) => {
    this.updateData(
      this.getData()
        .setIn(['aapnePostvelgere', kategoriId], false)
        .update(
          this.getPosterKey(),
          poster => poster.push(
            byggNyPost(
              this.getData().getIn(['allePostdata']).getIn([tekniskNavn]),
              shortid.generate(),
              true
            )
          )
        )
    );
  };

  onLeggTilPostFraPost = (kategoriId, post) => {
    this.updateData(
      this.getData()
        .setIn(['aapnePostvelgere', kategoriId], false)
        .update(
          this.getPosterKey(),
          poster => poster.push(
            byggNyPost(
              post,
              shortid.generate(),
              true
            )
          )
        )
    );
  };

  onLeggTilPostUtenKategori = tekniskNavn => {
    this.updateData(
      this.getData().update(this.getPosterKey(), poster =>
        poster.push(
          byggNyPost(
            this.getData().getIn(['allePostdata']).getIn([tekniskNavn]),
            shortid.generate(),
            true
          )
        )
      )
    );
  };

  oppdaterPostInnhold = post => {
    this.updateData(
      this.getData().update(
        this.getPosterKey(),
        poster => poster.map(mPost => (mPost.get('id') === post.get('id') ? post : mPost))
      ),
      this.validerOgKjoerBeregnSkatt
    );
  };

  oppdaterQueryString = query => {
    window.history.pushState(null, null, query);

    // Oppdater state ift query
    const queryParams = queryString.parse(query);
    byggTekster(getLocale());
    if(!viserForsiden()) {
      this.updateData( fromJS({
        aapnePostvelgere: {},
        allePostdata: hentPostdata(),
        poster: byggStandardposter(hentPostdata()),
        posterEktefelle: byggStandardposter(hentPostdata()),
        viserEktefelle: false,
        erGift: queryParams.sivilstand === 'GIFT',
        valideringer: [],
        inntektsaar: getInntektsaar(),
        locale: getLocale(),
        fastsettingHovedperson: {},
        fastsettingEktefelle: {},
        viserForsiden: false
      }))
    } else {
      this.updateData(
        this.getData().set('viserForsiden', true)
      );
    }

    giMainFokus();
  }

  validerOgKjoerBeregnSkatt = () => {
    const valideringer = validerPoster(this.getData().get(this.getPosterKey()));
    this.updateData(this.getData().set('valideringer', valideringer));
    if (valideringer.length === 0) {
      this.beregnSkatt();
    }
  };

  onDelete = post => {
    if (post.tekniskNavn) {
      this.updateData(
        this.getData().update(
          this.getPosterKey(),
          poster => poster.filter(x => x.getIn(['tekniskNavn']) !== post.tekniskNavn)
        ),
        this.beregnSkatt
      );
    } else if (post.id) {
      this.updateData(
        this.getData().update(
          this.getPosterKey(),
          poster => poster.filter(x => x.getIn(['id']) !== post.id)
        ),
        this.beregnSkatt
      );
    } else {
      this.updateData(
        this.getData().update(
          this.getPosterKey(),
          poster => poster.filter(x => !(x.get('tekniskNavn') === post.get('tekniskNavn')))
        ),
        this.beregnSkatt
      );
    }
  };

  renderGruppe = (tilgjengeligePoster, tittelKey, kategoriId) => {
    const viserEktefelle = this.getData().get('viserEktefelle');

    // Pass på at vi ikke aksesserer tilgjengeligePoster om den er tom (oppstår om man velger alle poster i GUI).
    let tilgjengeligePosterForKategori = tilgjengeligePoster.get(kategoriId);
    if(tilgjengeligePosterForKategori !== undefined) {
      tilgjengeligePosterForKategori = tilgjengeligePosterForKategori.valueSeq().groupBy(x => x.get('temaunderkategori'))
    }
    return (
      <Gruppe
        skattegrunnlagsobjekt={this.getData().getIn([viserEktefelle ? 'beregningEktefelle' : 'beregning', UtledSkvisVersjon(getInntektsaar()), utledSkattegrunnlagsobjektversjon(getInntektsaar()),])}
        viserEktefelle={viserEktefelle}
        tilgjengeligePoster={tilgjengeligePosterForKategori}
        fastsettingsberegningsresultat={this.getData().getIn([viserEktefelle ? 'fastsettingEktefelle' : 'fastsettingHovedperson'])}
        tittelKey={tittelKey}
        img={gruppeImg[kategoriId]}
        kategoriId={kategoriId}
        poster={this.getPoster(kategoriId)}
        postvelgerAapen={this.state.data.getIn(['aapnePostvelgere', kategoriId])}
        valideringer={this.state.data.get('valideringer')}
        onChange={this.onChange}
        togglePostvelgerAapen={this.togglePostvelgerAapen}
        onLeggTilPost={this.onLeggTilPost}
        onLeggTilPostFraPost={this.onLeggTilPostFraPost}
        oppdaterPostInnhold={this.oppdaterPostInnhold}
        onDelete={this.onDelete}
      />
    );
  };

  render() {

    if (!this.getData().get('allePostdata')) {
      return null;
    }

    const tilgjengeligePoster = this.tilgjengeligePoster();
    const erGift = this.getData().get('erGift');

    const renderGruppe = (...args) => (
      <div>
        {this.renderGruppe(tilgjengeligePoster, ...args)}
      </div>
    );

    if (viserForsiden()) {
      return (
        <Skattesubjekt postForside={(queryString) => this.oppdaterQueryString(queryString)} />
      );
    }
    const tittel = i18n('ledetekst.tittel') + ' ' + this.getData().get('inntektsaar');

    return (
      <div className={Styles.container}>
        <Toppmeny
          tittel={tittel}
          hopp={i18n('ledetekst.hopp.til.hovedinnhold')}
          locale={this.getData().get('locale')}
          onChangeLocale={this.onChangeLocale}
        />
        <main id="main-content-id" className={Styles.mainLayout} tabIndex={0} aria-label={tittel}>
          
          <Grid className={Styles.mainContent}>
            {erGift && (
              <Grid.Row rowSpacing={Grid.SPACE_NONE}>
                <Grid.Col lg={12}>
                  <Tabs options={[
                    {
                      text: i18n('ledetekst.hovedperson'),
                      active: !this.getData().get('viserEktefelle'),
                      itemKey: 'hovedperson',
                    },
                    {
                      text: i18n('ledetekst.ektefelle'),
                      active: this.getData().get('viserEktefelle'),
                      itemKey: 'ektefelle',
                    },
                  ]}
                  onTabChange={(visEktefelle) => {
                    this.updateData(this.getData().set('viserEktefelle', visEktefelle));
                  }}
                  />
                </Grid.Col>
              </Grid.Row>
            )}
            <Grid.Row rowSpacing={Grid.SPACE_NONE}>
              <Grid.Col xl={4} lg={3}>
                <ActionButton
                  className={Styles.backBtn}
                  icon={"ArrowBack"}
                  onClick={() => this.oppdaterQueryString('/')}
                  title={i18n('ledetekst.naviger.tilbake.tittel')}
                >
                  {i18n('ledetekst.naviger.tilbake')}
                </ActionButton>
              </Grid.Col>
              <Grid.Col xl={4} lg={6}>
                <Soek
                  onLeggTilPost={this.onLeggTilPostUtenKategori}
                  tilgjengeligePoster={this.alleTilgjengeligePoster().valueSeq().toJS()}
                  inntektsaar={getInntektsaar()}
                />
              </Grid.Col>
              <Grid.Col xl={4} lg={3}/>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col xl={4} lg={3}/>
              <Grid.Col xl={4} lg={6}>
                <MessageBar>{i18n('ledetekst.felter.marker.med.asterisk.er.paakrevd')}</MessageBar>
              </Grid.Col>
              <Grid.Col xl={4} lg={3}/>
            </Grid.Row>
            <Grid.Row rowSpacing={Grid.SPACE_LARGE}>
              <Grid.Col lg={12} xl={4}>
                {renderGruppe('kategori.arbeidTrygdPensjon', kategori.arbeidTrygdPensjon.id)}
              </Grid.Col>
              <Grid.Col lg={12} xl={4}>
                {renderGruppe('kategori.bankLaanForsikring', kategori.bankLaanForsikring.id)}
              </Grid.Col>
              <Grid.Col lg={12} xl={4}>
                {renderGruppe('kategori.boligOgEiendeler', kategori.boligOgEiendeler.id)}
              </Grid.Col>
            </Grid.Row>
            <Grid.Row rowSpacing={Grid.SPACE_LARGE}>
              <Grid.Col lg={12} xl={4}>
                {renderGruppe('kategori.naering', kategori.naering.id)}
              </Grid.Col>
              <Grid.Col lg={12} xl={4}>
                {renderGruppe('kategori.finans', kategori.finans.id)}
              </Grid.Col>
              <Grid.Col lg={12} xl={4}>
                {renderGruppe('kategori.familieOgHelse', kategori.familieOgHelse.id)}
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col lg={12}>
                <Beregning
                  beregning={this.getData().get('beregning')}
                  beregningEktefelle={this.getData().get('beregningEktefelle')}
                  erGift={erGift}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </main>
        <Footer />
      </div>
    );
  }
}

export default App;
