import React from 'react';
import { i18n } from '../../../i18n/tekster';
import PropTypes from 'prop-types';
import formatNumber from '../../../felles/formatNumber';
import { hentPostFraListe } from '../../../felles/HentPostFraListe';

const gyldigeTekniskeNavn = [
  "grunnlagForSaerfradragForLettereNedsattErvervsevne",
  "saerfradragForLettereNedsattErvervsevne",
  "grunnlagForSaerfradragForStoreSykdomskostnader",
  "saerfradragForStoreSykdomskostnader",
];

function utledBeloep(post, inntektsaar, fastsettingsberegningsresultat, skattegrunnlagsobjekt) {
  if (gyldigeTekniskeNavn.includes(post.get('tekniskNavn'))) {
    if (inntektsaar >= 2022) {
      return parseInt(fastsettingsberegningsresultat.getIn([post.get('tekniskNavn').replace('grunnlagForS', 's'), 'fradragsberettigetBeloep']), 10)
    } else {
      const samletPost = hentPostFraListe('samletSaerfradragForStoreSykdomsutgifter', skattegrunnlagsobjekt);
      return samletPost && parseInt(samletPost.get('beloep'), 10);
    }
  }
}

export const Saerfradrag = (props) => {
  const { post, inntektsaar, skattegrunnlagsobjekt, fastsettingsberegningsresultat } = props;

  let beloep = utledBeloep(post, inntektsaar, fastsettingsberegningsresultat, skattegrunnlagsobjekt);

  if (beloep && beloep > 0) {
    return (
      <div className="postbeholder_undertekst">
        {i18n('ledetekst.fradrag.blir').replace("{0}", formatNumber(beloep))}
      </div>
    );
  }

  return null;

};

Saerfradrag.propTypes = {
  post: PropTypes.object.isRequired
};

export default Saerfradrag;
