export function utledSkattesubjekt(inntektsaar, alder, tolvdel, kommune) {

  let skattesubjekt = {
    personligSkattesubjekt: {
      skattepliktTilNorge: 'GLOBAL',
      alderIInntektsaar: alder || 31,
      tolvdelVedArbeidsoppholdINorge: tolvdel,
    },
    skattested: kommune || '0301',
  };

  if (inntektsaar >= 2020) {
    skattesubjekt = {
      ...skattesubjekt,
      skattested: '0301',
      skattestedITiltakssone: kommune === '2019'
    }
  }

  return skattesubjekt;

}
