import React, { useRef } from 'react';
import { i18n } from '../../../i18n/tekster';
import PropTypes from 'prop-types';
import Styles from "./PostLabel.module.css";
import IconButton from '@skatteetaten/frontend-components/IconButton';
import Hjelpelenk from '../../Hjelpelenk';
import giForrigeElementFokus from '../../../felles/giForrigeElementFokus';

export const PostLabel = (props) => {

  const { post, onDelete, id, ledetekst, subtekst } = props;
  const ref = useRef(null)
  function performDelete() {
    giForrigeElementFokus(ref.current);
    onDelete(post);
  }

  return (
    <div className={Styles.container} ref={ref}>
      <div className={Styles.labelContainer}>
        <label htmlFor={id + '-input'} className={Styles.postLabel}>
          {ledetekst}
          {subtekst && (
            <span className={Styles.postLabelSubtekst}>
              <br />
              {subtekst}
            </span>
          )}
        </label>
      </div>
      <div className={Styles.buttonContainer}>
        {onDelete && (
          <IconButton
            title={
              i18n('ledetekst.slett') +
              ' ' +
              i18n(post.get('tekniskNavn') + '.ledetekst').toLowerCase()
            }
            icon={'delete'}
            onClick={performDelete}
          />
        )}
        {post.get('postnummer') && (
          <Hjelpelenk postnummer={post.get('postnummer')}>
            {`${i18n('ledetekst.vishjelp')} ${ledetekst.toLowerCase()}`}
          </Hjelpelenk>
        )}
      </div>
    </div>
  );
};

PostLabel.propTypes = {
  post: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  id: PropTypes.string.isRequired,
  ledetekst: PropTypes.string.isRequired,
  subtekst: PropTypes.string,
};

PostLabel.defaultProps = {
  onDelete: null,
  subtekst: undefined,
};

export default PostLabel;
