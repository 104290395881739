import React from 'react';
import { i18n } from '../../../i18n/tekster';
import PropTypes from 'prop-types';
import formatNumber from '../../../felles/formatNumber';
import { hentPostFraListe } from '../../../felles/HentPostFraListe';
import { getInntektsaar } from '../../../felles/utils';
import { Link } from '@skatteetaten/frontend-components/Link';
import { getLocale } from '../../../i18n/getLocale';
import { EN_US, NN_NO } from '../../../i18n/spraak';

const utledTilleggspostForGevinstVedRealisasjonAvAksje = (inntektsaar) => {
  if (inntektsaar === 2018) {
    return 'oppjustertTilleggTilSkattepliktigAksjegevinst';
  }
  return 'oppjustertTilleggTilGevinstVedRealisasjonAvAksje';
}

const utledTilleggspostForSkattepliktigUtbytteFraVerdipapirfondsandel = (inntektsaar) => {
  if (inntektsaar === 2018) {
    return 'oppjustertTilleggTilSkattepliktigUtbytteFraVerdipapirfondsandel';
  }
  return 'oppjustertTilleggTilUtbytteFraVerdipapirfond';
}

const utledTilleggspostForTapVedRealisasjonAvVerdipapirfondsandelKnyttetTilAksjedel = (inntektsaar) => {
  if (inntektsaar === 2018) {
    return 'oppjustertTilleggTilTapVedRealisasjonAvVerdipapirfondsandelKnyttetTilAksjedel';
  }
  return 'oppjustertTilleggTilTapVedRealisasjonAvAndelIAksjedelIVerdipapirfond';
}

const utledTilleggspostForGevinstVedRealisasjonAvVerdipapirfondsandelKnyttetTilAksjedel = (inntektsaar) => {
  if (inntektsaar === 2018) {
    return 'oppjustertTilleggTilGevinstVedRealisasjonAvVerdipapirfondsandelKnyttetTilAksjedel';
  }
  return 'oppjustertTilleggTilGevinstVedRealisasjonAvAndelIAksjedelIVerdipapirfond';
}

const utledOppjustertTilleggTilTapVedRealisasjonAvOgUttakFraAksjedelIFondskonto = (innktetsaar) => {
  if (innktetsaar >= 2022) {
    return 'oppjustertTilleggTilTapVedRealisasjonAvOgUttakFraAksjedelIFondskonto'
  }
  return 'oppjustertTilleggTilTapVedRealisajonAvOgUttakFraAksjedelIFondskonto'
}

const utledOppjustertTilleggTilAndelIFellesTilleggIAlminneligInntektFraSDFInnenAnnenNaering = (innktetsaar) => {
  if (innktetsaar >= 2022) {
    return 'oppjustertTilleggTilAndelIFellesTilleggIAlminneligInntektFraSDF'
  }
  return 'oppjustertTilleggTilAndelIFellesTilleggIAlminneligInntektFraSDFInnenAnnenNaering'
}

const fraTekniskNavnTilSkvisNavn = (tekniskNavn, inntektsaar) => {
  const mapping = {
    andelIFellesTapVedSalgAvAndelISDF: 'oppjustertTilleggTilAndelIFellesTapVedSalgAvAndelISDF',
    andelIFellesTilleggIAlminneligInntektFraSDFInnenAnnenNaering: utledOppjustertTilleggTilAndelIFellesTilleggIAlminneligInntektFraSDFInnenAnnenNaering(inntektsaar),
    andelIFellesTilleggIAlminneligInntektFraSDF: utledOppjustertTilleggTilAndelIFellesTilleggIAlminneligInntektFraSDFInnenAnnenNaering(inntektsaar),
    andelIFellesTilleggIAlminneligInntektFraSDFInnenBarnepassIBarnepasserensHjem: 'oppjustertTilleggTilAndelIFellesTilleggIAlminneligInntektFraSDFInnenBarnepassIBarnepasserensHjem',
    gevinstVedRealisasjonAvAksje: utledTilleggspostForGevinstVedRealisasjonAvAksje(inntektsaar),
    gevinstVedRealisasjonAvAksjesparekonto: 'oppjustertTilleggTilGevinstVedRealisasjonAvAksjesparekonto',
    gevinstVedRealisasjonAvOgUttakFraAksjedelIFondskonto: 'oppjustertTilleggTilGevinstVedRealisasjonAvOgUttakFraAksjedelIFondskonto',
    gevinstVedSalgAvAndelINOKUS: 'oppjustertTilleggTilGevinstVedSalgAvAndelINOKUS',
    skattepliktigUtbytteFraAksjeINOKUSIkkeRegistrertIVerdipapirsentralen: 'oppjustertTilleggTilSkattepliktigUtbytteFraAksjeINOKUSIkkeRegistrertIVerdipapirsentralen',
    tapVedRealisajonAvOgUttakFraAksjedelIFondskonto: 'oppjustertTilleggTilTapVedRealisajonAvOgUttakFraAksjedelIFondskonto',
    tapVedRealisasjonAvOgUttakFraAksjedelIFondskonto: utledOppjustertTilleggTilTapVedRealisasjonAvOgUttakFraAksjedelIFondskonto(inntektsaar),
    tapVedRealisasjonAvAksje: 'oppjustertTilleggTilTapVedRealisasjonAvAksje',
    tapVedRealisasjonAvAksjesparekonto: 'oppjustertTilleggTilTapVedRealisasjonAvAksjesparekonto',
    tapVedRealisasjonAvVerdipapirfondsandelIKombifondKnyttetTilAksjedel: 'oppjustertTilleggTilTapVedRealisasjonAvVerdipapirfondsandelIKombifondKnyttetTilAksjedel',
    tapVedSalgAvAndelINOKUS: 'oppjustertTilleggTilTapVedSalgAvAndelINOKUS',
    utbytteFraAksje: 'oppjustertTilleggTilUtbytteFraAksje',
    skattepliktigUtbytteFraAksjerRegistrertIVerdipapirsentralen: 'oppjustertTilleggTilSkattepliktigUtbytteFraAksjerRegistrertIVerdipapirsentralen',
    skattepliktigUtbytteFraVerdipapirfondsandel: utledTilleggspostForSkattepliktigUtbytteFraVerdipapirfondsandel(inntektsaar),
    utbytteFraVerdipapirfond: utledTilleggspostForSkattepliktigUtbytteFraVerdipapirfondsandel(inntektsaar),
    tapVedRealisasjonAvVerdipapirfondsandelKnyttetTilAksjedel: utledTilleggspostForTapVedRealisasjonAvVerdipapirfondsandelKnyttetTilAksjedel(inntektsaar),
    tapVedRealisasjonAvAndelIAksjedelIVerdipapirfond: utledTilleggspostForTapVedRealisasjonAvVerdipapirfondsandelKnyttetTilAksjedel(inntektsaar),
    gevinstVedRealisasjonAvVerdipapirfondsandelKnyttetTilAksjedel: utledTilleggspostForGevinstVedRealisasjonAvVerdipapirfondsandelKnyttetTilAksjedel(inntektsaar),
    gevinstVedRealisasjonAvAndelIAksjedelIVerdipapirfond: utledTilleggspostForGevinstVedRealisasjonAvVerdipapirfondsandelKnyttetTilAksjedel(inntektsaar),
    gevinstVedRealisasjonAvVerdipapirfondsandelIKombifondKnyttetTilAksjedel: 'oppjustertTilleggTilGevinstVedRealisasjonAvVerdipapirfondsandelIKombifondKnyttetTilAksjedel',
    renteinntektMedEkstrabeskatningForLaanTilSelskap: 'oppjustertTilleggTilRenteinntektMedEkstrabeskatningForLaanTilSelskap'
  };
  return mapping[tekniskNavn];
};

const tekniskeNavnSomSkalVisesMerknad = [
  'utbytteFraAksje',
  'gevinstVedRealisasjonAvOgUttakFraAksjedelIFondskonto',
  'utbytteFraVerdipapirfond',
  'gevinstVedRealisasjonAvAksje',
  'gevinstVedRealisasjonAvAksjesparekonto',
  'gevinstVedRealisasjonAvAndelIAksjedelIVerdipapirfond',
  'tapVedRealisasjonAvOgUttakFraAksjedelIFondskonto',
  'tapVedRealisasjonAvAksje',
  'tapVedRealisasjonAvAksjesparekonto',
  'tapVedRealisasjonAvAndelIAksjedelIVerdipapirfond',
];

export const OppjustertTilleggUndertekst = (props) => {

  const { post, skattegrunnlagsobjekt } = props;

  const inntektsaar = getInntektsaar();
  const skalVisesMerknadFor2022 = inntektsaar === 2022 && tekniskeNavnSomSkalVisesMerknad.includes(post.get('tekniskNavn'));

  const tillegspostNavn = fraTekniskNavnTilSkvisNavn(post.get('tekniskNavn'), inntektsaar);

  let url;
  switch (getLocale()) {
    case NN_NO:
      url = 'https://www.skatteetaten.no/nn/person/skatt/beregn-skatten-din/';
      break;
    case EN_US:
      url = 'https://www.skatteetaten.no/en/person/taxes/calculate-your-tax/';
      break;
    default:
      url = 'https://www.skatteetaten.no/person/skatt/beregn-skatten-din/';
      break;
  }

  if (tillegspostNavn) {
    const skvisPost = hentPostFraListe(tillegspostNavn, skattegrunnlagsobjekt);
    const skvisBeloep = skvisPost && parseInt(skvisPost.get('beloep'), 10);
    const postBeloep = parseInt(post.get('beloep'), 10);
    if (postBeloep && skvisBeloep && !skalVisesMerknadFor2022) {
      return (
        <div className="postbeholder_undertekst">
          {i18n('ledetekst.OppjustertTilleggsundertekst').replace("{0}", formatNumber(skvisPost.get('beloep')))}
        </div>
      )
    }
    if (postBeloep && skvisBeloep && skalVisesMerknadFor2022) {
      return (
        <div className="postbeholder_undertekst">
          {i18n('ledetekst.OppjustertTilleggsundertekst').replace("{0}", formatNumber(skvisPost.get('beloep')))}
          &nbsp;
          <Link
            path={url}
            text={i18n('ledetekst.OppjustertTilleggsundertekstMerknad')}
            openInNew={true}
          />
        </div>
      )
    }
  }

  return null;

};

OppjustertTilleggUndertekst.propTypes = {
  post: PropTypes.object.isRequired,
  skattegrunnlagsobjekt: PropTypes.object,
};

OppjustertTilleggUndertekst.defaultProps = {
  skattegrunnlagsobjekt: null,
};

export default OppjustertTilleggUndertekst;
