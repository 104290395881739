import React, { useCallback } from 'react';
import { i18n } from '../../../i18n/tekster';
import formatNumber from '../../../felles/formatNumber';
import { fjernFormateringKroner } from '../../../felles/utils';
import fjernUgyldigeTegn from '../../../validering/ugyldigeTegn';
import CheckBox from '@skatteetaten/frontend-components/CheckBox';
import TextField from '@skatteetaten/frontend-components/TextField';
import { BarnepensjonPostStatus } from './BarnepensjonPostStatus.enum';

export const EgenBarnepensjon = (props) => {

  const {post, oppdaterPostInnhold, onDelete} = props;

  const egenBarnepensjon = post.get('egenBarnepensjon');
  const mottarPensjonSelv = egenBarnepensjon === undefined ? true : egenBarnepensjon;

  const oppdaterPost = useCallback(
    (beloep, mottarPensjonSelv) => {
      oppdaterPostInnhold(post
        .set('beloep', beloep)
        .set('barnepensjonPostStatus', BarnepensjonPostStatus.EGEN_BARNEPENSJON)
        .set('egenBarnepensjon', mottarPensjonSelv)
      );
    },
    [oppdaterPostInnhold, post],
  );

  const oppdaterMottarBarnePensjonSelv = useCallback(
    () => {
      if (mottarPensjonSelv){
        oppdaterPost('', false);
      } else {
        oppdaterPost('', true);
      }
    },
    [mottarPensjonSelv, oppdaterPost],
  );

  return (
    <div className="post" key={'barnepensjonselv'}>
      <div>
        <CheckBox
          boxSide={'start'}
          label={i18n('ledetekst.egenBarnepensjon.avkrysningsboks')}
          checked={mottarPensjonSelv}
          onChange={oppdaterMottarBarnePensjonSelv}
          onDelete={onDelete}
        />
      </div>

      {mottarPensjonSelv &&
        <TextField
          key={post.get('id') + '-barnepensjonselv'}
          label={i18n('ledetekst.egenBarnepensjon.beloep')}
          value={post.get('beloep') ? formatNumber(post.get('beloep')) : ''}
          maxLength={12}
          onChange={(evt, value) => {
            oppdaterPost(fjernFormateringKroner(fjernUgyldigeTegn(value)), mottarPensjonSelv);
          }}
          onDelete={onDelete}
          autoFocus={post.get('autofokus')}
        />
      }
    </div>
  );

};

export default EgenBarnepensjon;