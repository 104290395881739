import React, { createRef, PureComponent } from 'react';
import Fuse from 'fuse.js';
import Autosuggest from 'react-autosuggest';
import './soek.css';
import { i18n } from '../../i18n/tekster';
import Styles from './soek.module.css';
import Icon from '@skatteetaten/frontend-components/Icon';
import Callout from '@skatteetaten/frontend-components/Callout';
import { utledFeilmeldingMedTekniskNavn } from '../../felles/UtledFeilmeldingForSaerfradrag';

function getSuggestions(tilgjengeligePoster, value) {
  const inputValue = value.trim().toLowerCase();
  const fuse = new Fuse(tilgjengeligePoster, {
    keys: ['ledetekst'],
    threshold: 0.4,
    tokenize: true,
  });
  return inputValue.length === 0 ? [] : fuse.search(inputValue).slice(0, 10);
}

function postErTilgjengelig(tilgjengeligePoster, tekniskNavn) {
  return tilgjengeligePoster.some(e => e.tekniskNavn === tekniskNavn)
}

class Soek extends PureComponent {

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.state = {
      value: '',
      suggestions: [],
      isCalloutVisible: false,
      disabledMessage: null
    };
    this.calloutButton = createRef()
  }

  closeButton() {
    this.setState({ isCalloutVisible: false });
  }

  onChange(event, { newValue }) {
    this.setState({ value: newValue });
  }

  onSuggestionsFetchRequested({ value }) {
    this.setState({ suggestions: getSuggestions(this.props.tilgjengeligePoster, value) });
  }

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    this.setState({ value: '' });

    const disabledMessage = utledFeilmeldingMedTekniskNavn(this.props.tilgjengeligePoster, suggestion.item.tekniskNavn)

    if (disabledMessage) {
      this.setState({
        disabledMessage: disabledMessage,
        isCalloutVisible: !this.state.isCalloutVisible
      })
    } else {
      this.props.onLeggTilPost(suggestion.item.tekniskNavn);
    }
  };

  renderSuggestion = (suggestion) => {
    const ervervValgt = !postErTilgjengelig(this.props.tilgjengeligePoster, 'saerfradragForLettereNedsattErvervsevne')
    const storeSykdommerValgt = !postErTilgjengelig(this.props.tilgjengeligePoster, 'saerfradragForStoreSykdomskostnader')

    if (this.props.inntektsaar >= 2022 && (
      suggestion.item.tekniskNavn === 'saerfradragForStoreSykdomskostnader' && ervervValgt ||
      suggestion.item.tekniskNavn === 'saerfradragForLettereNedsattErvervsevne' && storeSykdommerValgt)) {
      return (
        <div className="react-autosuggest__suggestion_feil">
          {i18n(suggestion.item.tekniskNavn + '.ledetekst')}
        </div>
      )
    }
    return (
      <div>
        {i18n(suggestion.item.tekniskNavn + '.ledetekst')}
      </div>
    );
  }

  render() {

    const inputProps = {
      placeholder: i18n('ledetekst.soek_etter_post'),
      value: this.state.value,
      onChange: this.onChange,
      id: 'soek-etter-post',
      'aria-label':  i18n('ledetekst.soek_etter_post'),
    };

    return (
      <>
        <div className={Styles.container} ref={this.calloutButton}>
          <Autosuggest
            suggestions={this.state.suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            focusInputOnSuggestionClick={false}
            onChange={this.onChange}
            getSuggestionValue={(suggestion) => suggestion.item.ledetekst}
            renderSuggestion={this.renderSuggestion}
            onSuggestionSelected={this.onSuggestionSelected}
            inputProps={inputProps}
          />


          <div className={Styles.imgContainer}>
            <Icon
              iconName={"Search"}
              className={Styles.img}/>
            </div>
          </div>
        <div className={Styles.srOnly} aria-live="assertive">
          {!!this.state.suggestions.length && (
            <>{this.state.suggestions.length + ' forslag vises.'}</>
          )}
        </div>

        {this.state.isCalloutVisible && (
          <Callout
            target={this.calloutButton}
            directionalHint={Callout.POS_BOTTOM_LEFT}
            color={Callout.INFO}
            onClose={() => this.closeButton()}
            onDismiss={() => this.closeButton()}
          >
            <p>{this.state.disabledMessage}</p>
          </Callout>
        )}

      </>
    );

  }

}

export default Soek;
