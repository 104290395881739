import { fromJS } from 'immutable';
import queryString from 'query-string';
import debounce from './debounce';
import { utledUrlTilBeregning } from './beregning/utledUrlTilBeregning';
import { utledArgumenterForFastsetting } from './fastsetting/utledArgumenterForFastsetting';
import { utledArgumenterBeregning } from './beregning/utledArgumenterBeregning';
import utledUrlTilFastsetting from './fastsetting/utledUrlTilFastsetting';
import { getInntektsaar } from '../felles/utils';
import utledResultatversjon from './fastsetting/utledResultatversjon';
import { hentSaerfradragEnsligForsoerger } from './fastsetting/hentSaerfradragEnsligForsoerger';
import { hentAlminneligInntektForFastsetting } from './fastsetting/hentAlminneligInntektForFastsetting';

const reiserIArbeidsforhold = ["besoeksreise", "arbeidsreise"];

const skalFjernePostForGamleTekniskeNavn = (post, inntektsaar) => {
  if (inntektsaar >= 2022) {
    return !reiserIArbeidsforhold.includes(post.get('tekniskNavn'));
  }
  return false;
}

const fradragForPengetilskuddTekniskeNavn = ['fradragForPengetilskudd', 'pengetilskuddTilVitenskapeligForskning'];

async function oppdaterDataMedFastsetting(data, inntektsaar, erEktefelle, fastsettingCallback, alminneligInntektForFastsetting) {

  const url = utledUrlTilFastsetting(inntektsaar);
  const posterKeyword = erEktefelle ? 'posterEktefelle' : 'poster';
  let funnetEnAvReiseneIArbeidsforhold = false;

  const args = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    body: utledArgumenterForFastsetting(data.get(posterKeyword), inntektsaar, erEktefelle, alminneligInntektForFastsetting)
  };

  return await fetch(url, args)
    .then(response => {
      if (response.status !== 200) {
        throw new Error("Uventet statuskode " + response.status);
      }
      return response.json()
    })
    .then(response => fromJS(response))
    .then(response => {
      fastsettingCallback(response, erEktefelle ? 'fastsettingEktefelle' : 'fastsettingHovedperson')
      return data.update(
        posterKeyword,
        poster => poster.map(post => {
          if (post.get('tekniskNavn') === 'formuesverdiForPrimaerbolig') {
            const fastEiendomSomFormuesobjekt = response.getIn([utledResultatversjon(inntektsaar), 'fastEiendomSomFormuesobjekt', 'formuesspesifikasjonForBolig']);

            if (fastEiendomSomFormuesobjekt) {
              const primaerbolig = fastEiendomSomFormuesobjekt.find(resultat => resultat.get('id') === "PRIMAERBOLIG");
              if (primaerbolig !== undefined && primaerbolig !== null) {
                post = post.set('beloep', primaerbolig.get('formuesverdiForFormuesandel').get('beloep'));
                post = post.set('verdiFoerVerdsettingsrabattForFastEiendom', primaerbolig.get('verdiFoerVerdsettingsrabattForFormuesandel'));
              }
            }
          }

          if (post.get('tekniskNavn') === 'formuesverdiForSekundaerbolig') {
            const fastEiendomSomFormuesobjekt = response.getIn([utledResultatversjon(inntektsaar), 'fastEiendomSomFormuesobjekt', 'formuesspesifikasjonForBolig']);

            if (fastEiendomSomFormuesobjekt) {
              const sekundaerbolig = fastEiendomSomFormuesobjekt.find(resultat => resultat.get('id') === "SEKUNDAERBOLIG");
              if (sekundaerbolig !== undefined && sekundaerbolig !== null) {
                post = post.set('beloep', sekundaerbolig.get('formuesverdiForFormuesandel').get('beloep'));
                post = post.set('verdiFoerVerdsettingsrabattForFastEiendom', sekundaerbolig.get('verdiFoerVerdsettingsrabattForFormuesandel'));
              }
            }
          }

          if (post.get('tekniskNavn') === 'saerfradragForEnsligForsoerger') {
            const saerfradragForEnsligForsoerger = hentSaerfradragEnsligForsoerger(inntektsaar, response);
            if (saerfradragForEnsligForsoerger) {
              post = post
                .set('beloep', saerfradragForEnsligForsoerger)
                .set('antallMaanederMedMottakAvUtvidetBarnetrygd', undefined)
                .set('omsorgsgradVedMottakAvUtvidetBarnetrygd', undefined);
            }
          }

          if (post.get('tekniskNavn') === 'saerfradragForLettereNedsattErvervsevne') {
            const saerfradragForLettereNedsattErvervsevne = response.getIn([utledResultatversjon(inntektsaar), 'saerfradragForLettereNedsattErvervsevne', 'fradragsberettigetBeloep']);
            if (saerfradragForLettereNedsattErvervsevne) {
              post = post.set('beloep', saerfradragForLettereNedsattErvervsevne);
            }
          }

          if (post.get('tekniskNavn') === 'saerfradragForStoreSykdomskostnader') {
            const saerfradragForStoreSykdomskostnader = response.getIn([utledResultatversjon(inntektsaar), 'saerfradragForStoreSykdomskostnader', 'fradragsberettigetBeloep']);
            post = post.set('beloep', saerfradragForStoreSykdomskostnader);
          }

          if ((reiserIArbeidsforhold.includes(post.get('tekniskNavn')) && funnetEnAvReiseneIArbeidsforhold === false) ||
            (post.get('tekniskNavn') === 'reisefradrag' && inntektsaar >= 2022)) {
            funnetEnAvReiseneIArbeidsforhold = true;

            const reisefradrag = response.getIn([utledResultatversjon(inntektsaar), 'reiserIArbeidsforhold', 'reisefradrag']);
            if (reisefradrag) {
              post = post
                .set('tekniskNavn', 'reisefradrag')
                .set('beloep', reisefradrag)
                .set('bompengerEllerFergeutgifter', undefined);
            }
          }

          if (post.get('tekniskNavn') === 'fradragForGaverTilFrivilligeOrganisasjoner') {
            const gaveTilFrivilligOrganisasjon = response.getIn([utledResultatversjon(inntektsaar), 'gaverTilFrivilligeOrganisasjoner']);
            if (gaveTilFrivilligOrganisasjon) {
              post = post.set('beloep', gaveTilFrivilligOrganisasjon.map(beloep => beloep.get(0).get('beloep')).get('fradragForGaverTilFrivilligeOrganisasjoner'));
            }
          }

          if (post.get('tekniskNavn') === 'saerskiltFradragForSjoefolk') {
            const beloepFraFastsetting = response.getIn([utledResultatversjon(inntektsaar), 'saerskiltFradragForSjoefolk', 'fradragsberettigetBeloep']);
            if (beloepFraFastsetting) {
              post = post.set('tekniskNavnFraFastsetting', 'saerskiltFradragForSjoefolk')
                .set('beloepFraFastsetting', beloepFraFastsetting)
                .set('tekniskNavn', 'loennsinntekt');
            }
          }

          if (fradragForPengetilskuddTekniskeNavn.includes(post.get('tekniskNavn'))) {
            const tilskuddTilVitenskapeligForskning = response.getIn([utledResultatversjon(inntektsaar), 'tilskuddTilVitenskapeligForskning']);
            if (tilskuddTilVitenskapeligForskning) {
              post = post.set('beloep', tilskuddTilVitenskapeligForskning.get('fradragForPengetilskudd').get(0).get('beloep'));
            }
          }

          if (post.get('tekniskNavn') === 'inntektsfradragForFagforeningskontingent') {
            const betaltFagforeningskontigent = response.getIn([utledResultatversjon(inntektsaar), 'fagforeningskontingent']);
            if (betaltFagforeningskontigent) {
              post = post.set('beloep', betaltFagforeningskontigent.get('inntektsfradragForFagforeningskontingent').get('beloep'));
            }
          }

          if (post.get('tekniskNavn') === 'formuesverdiForFritidsbaatMedSalgsverdiOverSalgsverdigrense') {
            const salgsverdi = response.getIn([utledResultatversjon(inntektsaar), 'fritidsbaaterMedSalgsverdiOverSalgsverdigrense', 'formuesspesifikasjonForFormuesverdiForFritidsbaatMedSalgsverdiOverSalgsverdigrense']);
            if (!salgsverdi || salgsverdi.size === 0) {
              post = post.set('beloep', 0);
            } else {
              const beloep = salgsverdi
                .get(0)
                .get('formuesverdiForFritidsbaatMedSalgsverdiOverSalgsverdigrense')
                .get('beloep');

              post = post.set('beloep', beloep);
            }
          }

          if (post.get('tekniskNavn') === 'fradragForIndividuellSparingTilPensjon') {
            const fradrag = response.getIn([utledResultatversjon(inntektsaar), 'individuellPensjonsordning']);
            if (fradrag) {
              post = post.set('beloep', fradrag.get('fradragForIndividuellSparingTilPensjon').get(0).get('beloep'));
            }
          }

          if (post.get('tekniskNavn') === 'saerskiltFradragForFiskereOgFangstfolk') {
            const fradragsberettigetBeloep = response.getIn(
              [utledResultatversjon(inntektsaar),
                'oevrigeFradragFraVirksomhetInnenPrimaernaering',
                'saerskiltFradragForFiskereOgFangstfolk',
                'fradragsberettigetBeloep']);

            if (fradragsberettigetBeloep) {
              post = post.set('beloep', fradragsberettigetBeloep);
            }
          }

          return post;
        })
          .filter(post => skalFjernePostForGamleTekniskeNavn(post, inntektsaar))
          .filter(post => !post.get('underpost')));
    });

}

export async function beregnSkatt(data, beregningCallback, fastsettingCallback) {

  try {

    const inntektsaar = getInntektsaar();
    const posterHovedperson = data.get('poster');
    const posterEktefelle = data.get('erGift') && data.get('posterEktefelle');
    const urlBeregning = utledUrlTilBeregning(inntektsaar);

    if (inntektsaar >= 2022) {

      const argsBeregningForFastsetting = utledArgumenterBeregning(queryString.parse(window.location.search), data);

      if (posterHovedperson) {
        const alminneligInntektForFastsetting = await hentAlminneligInntektForFastsetting(urlBeregning, 'hovedperson', argsBeregningForFastsetting, inntektsaar);
        data = await oppdaterDataMedFastsetting(data, inntektsaar, false, fastsettingCallback, alminneligInntektForFastsetting);
      }
      if (posterEktefelle) {
        const alminneligInntektForFastsetting = await hentAlminneligInntektForFastsetting(urlBeregning, 'ektefelle', argsBeregningForFastsetting, inntektsaar);
        data = await oppdaterDataMedFastsetting(data, inntektsaar, true, fastsettingCallback, alminneligInntektForFastsetting);
      }
    }

    const argsBeregning = utledArgumenterBeregning(queryString.parse(window.location.search), data);

    await fetch(urlBeregning, argsBeregning)
      .then(response => {
        if (response.status !== 200) {
          throw new Error("Uventet statuskode " + response.status);
        }
        return response.json()
      })
      .then(response => fromJS(response))
      .then(response => beregningCallback(response))

  } catch (error) {
    // Ingen god maate aa haendtere feil paa i kalkulatoren atm.
    console.error(error);
  }

}

const beregnSkattPaaDetMesteHvertSekund = debounce(beregnSkatt, 1000);
export default beregnSkattPaaDetMesteHvertSekund;