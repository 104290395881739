import { fromJS } from 'immutable';
import {
  alderspensjonPoster,
  alderspensjonXmlType,
  ensligForsoergerPoster,
  ensligForsoergerXmlType,
  reisefradragsposter,
  reisefradragXmlType,
  skattegrunnlagsobjektXmlType,
  barnepensjonPoster,
  IndividuellSparingTilPensjonPoster,
} from './postXmlTyper';
import { BarnepensjonPostStatus } from '../components/Gruppe/Barnepensjon/BarnepensjonPostStatus.enum';

export default function byggNyPost(post, id, autoFokus) {
  const y = fromJS(post)
    .set('id', id)
    .set('beloep', '')
    .set('autofokus', autoFokus);
  if (alderspensjonPoster.contains(y.get('tekniskNavn'))) {
    return y.set('type', alderspensjonXmlType);
  }
  if (reisefradragsposter.contains(y.get('tekniskNavn'))) {
    return y.set('type', reisefradragXmlType);
  }
  if (ensligForsoergerPoster.contains(y.get('tekniskNavn'))) {
    return y.set('type', ensligForsoergerXmlType);
  }
  if (barnepensjonPoster.contains(y.get('tekniskNavn'))) {
    return y.set('barnetsPersonidentifikator', '')
        .set('barnepensjonPostStatus', BarnepensjonPostStatus.IKKE_FYLT_INN)
        .set('type', skattegrunnlagsobjektXmlType)
        .set('egenBarnepensjon', false);
  }
  if (IndividuellSparingTilPensjonPoster.contains(y.get('tekniskNavn'))) {
    return y.set('beloepNyIPS', '')
      .set('beloepGammelEOES', '')
      .set('beloepNyEOES', '')
      .set('type', skattegrunnlagsobjektXmlType);
  }
  return y.set('type', skattegrunnlagsobjektXmlType);
}
