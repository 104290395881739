export function utledIndividuellPensjonssparing(poster, erEktefelle, queryParams, inntektsaar) {

  const individuellSparingTilPensjon = poster.find(post => post.get('tekniskNavn') === 'fradragForIndividuellSparingTilPensjon');
  const parseTall = (tall) => tall && parseInt(tall, 10) || 0;

  if (!individuellSparingTilPensjon) {
    return undefined;
  }

  const tolvdelVedArbeidsoppholdINorge = queryParams && queryParams[erEktefelle ? 'tolvdelVedArbeidsoppholdINorgeEF' : 'tolvdelVedArbeidsoppholdINorge'] || 12

  const beloepGammelIPS = parseTall(individuellSparingTilPensjon.get('beloep'));
  const beloepNyIPS = parseTall(individuellSparingTilPensjon.get('beloepNyIPS'));
  const beloepGammelEOES = parseTall(individuellSparingTilPensjon.get('beloepGammelEOES'));
  const beloepNyEOES = parseTall(individuellSparingTilPensjon.get('beloepNyEOES'));

  const utenlandsk = inntektsaar === 2022 ? "ieoes" : "iEOES";

  if (!beloepGammelIPS && !beloepNyIPS && !beloepGammelEOES && !beloepNyEOES) {
    return undefined;
  }

  let pensjonsinnbetalingTilIndividuellPensjonsavtale = [];
  if (beloepGammelIPS) {
    pensjonsinnbetalingTilIndividuellPensjonsavtale.push(
      {"beloep": {"beloep": beloepGammelIPS}}
    );
  }
  if (beloepGammelEOES) {
    pensjonsinnbetalingTilIndividuellPensjonsavtale.push(
      {
        "beloep": {"beloep": beloepGammelEOES},
        "land": {"landkode": "SE", [utenlandsk]: true}
      }
    );
  }

  let pensjonsinnbetalingTilSkattefavorisertIndividuellSparingTilPensjon = [];
  if (beloepNyIPS) {
    pensjonsinnbetalingTilSkattefavorisertIndividuellSparingTilPensjon.push(
      {
        "beloep": {"beloep": beloepNyIPS}
      }
    );
  }
  if (beloepNyEOES) {
    pensjonsinnbetalingTilSkattefavorisertIndividuellSparingTilPensjon.push(
      {
        "beloep": {"beloep": beloepNyEOES},
        "land": {"landkode": "SE", [utenlandsk]: true}
      }
    );
  }

  return {
    "pensjonsinnbetalingTilIndividuellPensjonsavtale": pensjonsinnbetalingTilIndividuellPensjonsavtale,
    "pensjonsinnbetalingTilSkattefavorisertIndividuellSparingTilPensjon": pensjonsinnbetalingTilSkattefavorisertIndividuellSparingTilPensjon,
    "tolvdelVedArbeidsoppholdIUtlandet": tolvdelVedArbeidsoppholdINorge
  }
}