import React from 'react';
import PropTypes from 'prop-types';
import { formaterTall } from '../../../felles/numberFormatter';
import Styles from './tittel.module.css';

const Tittel = (props) => {
  const { tekst, beloep } = props;
  return (
    <h2 className={Styles.heading}>
      {tekst + ': ' + formaterTall(beloep) + ' kr.'}
    </h2>
  );
};

Tittel.propTypes = {
  tekst: PropTypes.string.isRequired,
  beloep: PropTypes.number.isRequired,
};

export default Tittel;