export function utledLoennForSjoefolk(poster, queryParams, erEktefelle) {

  const loenn = poster.find(post => post.get('tekniskNavn') === 'saerskiltFradragForSjoefolk');
  if (!loenn) {
    return undefined;
  }

  const alder =  queryParams && queryParams[erEktefelle ? 'alderEF' : 'alder'] || 31;
  const beloep = loenn.get('beloep');

  return !beloep
    ? undefined
    : {
      "samledeYtelserFraArbeidsgiver": [{
        "id": 1,
        "girRettTilSaerskiltFradragForSjoefolk": true,
        "innberettetBeloep":{
         "beloep": beloep
        }
      }],
      "alderIInntektsaar": alder
    };
}



