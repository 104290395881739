import React, { useCallback, useState } from 'react';
import { i18n } from '../../../i18n/tekster';
import PostLabel from './../PostLabel/PostLabel';
import PropTypes from 'prop-types';
import CheckBox from '@skatteetaten/frontend-components/CheckBox';

export const PostToggle = (props) => {
  const { post, onChange, onDelete, label } = props;
  const [toggleAktiv, setToggleAktiv] = useState(!!post.get('beloep'));

  const oppdaterToggle = useCallback(
    () => {
      if (toggleAktiv) {
        onChange(post.get('id'), null)
        setToggleAktiv(false);
      } else {
        onChange(post.get('id'), 1)
        setToggleAktiv(true);
      }
    },
    [toggleAktiv, setToggleAktiv, onChange, post],
  );

  return (
    <div className="post">
      <PostLabel
        post={post}
        onDelete={onDelete}
        onChange={onChange}
        id={post.get('id')}
        ledetekst={i18n(post.get('tekniskNavn') + '.ledetekst')}
      />
      <div className="post_toggle_container">
        <CheckBox
          boxSide={'start'}
          checked={toggleAktiv}
          onChange={oppdaterToggle}
          inputProps={{ autoFocus: true }}
        />
        <label>{label}</label>
      </div>
    </div>
  );
};

PostToggle.propTypes = {
  post: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default PostToggle;
