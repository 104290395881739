import React from 'react';
import PostLabel from './../PostLabel/PostLabel';
import PropTypes from 'prop-types';
import { formaterTall } from '../../../felles/numberFormatter';
import { TextField } from '@skatteetaten/frontend-components/TextField';

export const Minstefradrag = (props) => {

  const { post, tekniskNavn, labeltekst, minstefradragIBarnepensjonTilBarn } = props;

  const beloep = minstefradragIBarnepensjonTilBarn ? minstefradragIBarnepensjonTilBarn : post.get('beloep');

  return (
    <React.Fragment>
      <div className="post">
        <PostLabel
          post={post}
          id={tekniskNavn + '-beloep'}
          ledetekst={labeltekst}
        />
        <TextField
          id={tekniskNavn + '-beloep'}
          value={formaterTall(beloep)}
          autoFocus
          disabled={true}
        />
      </div>
    </React.Fragment>
  );

};

Minstefradrag.propTypes = {
  post: PropTypes.object.isRequired,
  tekniskNavn: PropTypes.string.isRequired,
  labeltekst: PropTypes.string.isRequired,
};

export default Minstefradrag;