export default 
{
  "alderspensjonFraFolketrygden" : {
    "tekniskNavn" : "alderspensjonFraFolketrygden",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "pensjonTrygdInntekt",
    "sorteringsNoekkel" : "020201",
    "postnummer" : "2.2.1",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "andelAvAnnenFormueIBoligsameie" : {
    "tekniskNavn" : "andelAvAnnenFormueIBoligsameie",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerFormue",
    "sorteringsNoekkel" : "040503",
    "postnummer" : "4.5.3",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "andelAvFellesgjeldIBoligselskap" : {
    "tekniskNavn" : "andelAvFellesgjeldIBoligselskap",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerGjeld",
    "sorteringsNoekkel" : "040802",
    "postnummer" : "4.8.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "andelAvFellesgjeldISDF" : {
    "tekniskNavn" : "andelAvFellesgjeldISDF",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringGjeld",
    "sorteringsNoekkel" : "040801",
    "postnummer" : "4.8.1",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "andelAvFradragsberettigetFelleskostnadIBoligselskap" : {
    "tekniskNavn" : "andelAvFradragsberettigetFelleskostnadIBoligselskap",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerFradrag",
    "sorteringsNoekkel" : "030304",
    "postnummer" : "3.3.4",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "andelAvInntektIBoligselskap" : {
    "tekniskNavn" : "andelAvInntektIBoligselskap",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerInntekt",
    "sorteringsNoekkel" : "020801",
    "postnummer" : "2.8.1",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "andelAvUnderskuddFraTidligereAarVedDriftAvVaaningshusSomAnvendesIAaret" : {
    "tekniskNavn" : "andelAvUnderskuddFraTidligereAarVedDriftAvVaaningshusSomAnvendesIAaret",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringFradrag",
    "sorteringsNoekkel" : "030311",
    "postnummer" : "3.3.11",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "andelIFellesTapVedSalgAvAndelISDF" : {
    "tekniskNavn" : "andelIFellesTapVedSalgAvAndelISDF",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringFradrag",
    "sorteringsNoekkel" : "030307",
    "postnummer" : "3.3.7",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "andelIFellesTilleggIAlminneligInntektFraSDF" : {
    "tekniskNavn" : "andelIFellesTilleggIAlminneligInntektFraSDF",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringInntekt",
    "sorteringsNoekkel" : "020711",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "annenArbeidsinntekt" : {
    "tekniskNavn" : "annenArbeidsinntekt",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "loennsinntekter",
    "sorteringsNoekkel" : "020105",
    "postnummer" : "2.1.5",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "annenGjeld" : {
    "tekniskNavn" : "annenGjeld",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringGjeld",
    "sorteringsNoekkel" : "040801",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "annenInntekt" : {
    "tekniskNavn" : "annenInntekt",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetInntekt",
    "sorteringsNoekkel" : "030112",
    "postnummer" : "3.1.12",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "annenPensjonFoerAlderspensjon" : {
    "tekniskNavn" : "annenPensjonFoerAlderspensjon",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "pensjonTrygdInntekt",
    "sorteringsNoekkel" : "020202",
    "postnummer" : "2.2.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "annenSkattepliktigFormue" : {
    "tekniskNavn" : "annenSkattepliktigFormue",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetFormue",
    "sorteringsNoekkel" : "040504",
    "postnummer" : "4.5.4",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "annenSkattepliktigKapitalinntektFraAnnetFinansprodukt" : {
    "tekniskNavn" : "annenSkattepliktigKapitalinntektFraAnnetFinansprodukt",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetInntekt",
    "sorteringsNoekkel" : "030115",
    "postnummer" : "3.1.15",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "annetInntektsfradrag" : {
    "tekniskNavn" : "annetInntektsfradrag",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "arbeidOgPensjonFradrag",
    "sorteringsNoekkel" : "030307",
    "postnummer" : "3.3.7",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "avkastningAvKapitalforsikringsavtale" : {
    "tekniskNavn" : "avkastningAvKapitalforsikringsavtale",
    "temakategori" : "bankLaanForsikring",
    "temaunderkategori" : "bankInntekt",
    "sorteringsNoekkel" : "030104",
    "postnummer" : "3.1.4",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "avtalefestetPensjon" : {
    "tekniskNavn" : "avtalefestetPensjon",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "pensjonTrygdInntekt",
    "sorteringsNoekkel" : "020202",
    "postnummer" : "2.2.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "avtalefestetPensjonIPrivatSektorFom2011" : {
    "tekniskNavn" : "avtalefestetPensjonIPrivatSektorFom2011",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "pensjonTrygdInntekt",
    "sorteringsNoekkel" : "020202",
    "postnummer" : "2.2.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "barnepensjonFraFolketrygden" : {
    "tekniskNavn" : "barnepensjonFraFolketrygden",
    "temakategori" : "familieOgHelse",
    "temaunderkategori" : "fHInntekt",
    "sorteringsNoekkel" : "020202",
    "postnummer" : "2.6.3",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "beloepSpartIBSUIInntektsaar" : {
    "tekniskNavn" : "beloepSpartIBSUIInntektsaar",
    "temakategori" : "bankLaanForsikring",
    "temaunderkategori" : "bankinnskudd",
    "sorteringsNoekkel" : "010501",
    "postnummer" : "1.5.1",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "dagpengerForFisker" : {
    "tekniskNavn" : "dagpengerForFisker",
    "temakategori" : "naering",
    "temaunderkategori" : "personNaeringInntekt",
    "sorteringsNoekkel" : "020107",
    "postnummer" : "2.1.7",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "dagpengerForNaeringsdrivende" : {
    "tekniskNavn" : "dagpengerForNaeringsdrivende",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "loennsinntekter",
    "sorteringsNoekkel" : "020107",
    "postnummer" : "2.1.7",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "ektefelletilleggTilPensjon" : {
    "tekniskNavn" : "ektefelletilleggTilPensjon",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "pensjonTrygdInntekt",
    "sorteringsNoekkel" : "020204",
    "postnummer" : "2.2.4",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "ektefelletilleggTilUfoeretrygd" : {
    "tekniskNavn" : "ektefelletilleggTilUfoeretrygd",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "loennsinntekter",
    "sorteringsNoekkel" : "020110",
    "postnummer" : "2.1.10",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "etterlattepensjonFraFolketrygden" : {
    "tekniskNavn" : "etterlattepensjonFraFolketrygden",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "pensjonTrygdInntekt",
    "sorteringsNoekkel" : "020201",
    "postnummer" : "2.2.1",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "etterloennOgEtterpensjon" : {
    "tekniskNavn" : "etterloennOgEtterpensjon",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "pensjonTrygdInntekt",
    "sorteringsNoekkel" : "020602",
    "postnummer" : "2.6.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "foreldrefradrag" : {
    "tekniskNavn" : "foreldrefradrag",
    "temakategori" : "familieOgHelse",
    "temaunderkategori" : "fHFradrag",
    "sorteringsNoekkel" : "030210",
    "postnummer" : "3.2.10",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "formuesverdiAvPrivatUtestaaendeFordring" : {
    "tekniskNavn" : "formuesverdiAvPrivatUtestaaendeFordring",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetFormue",
    "sorteringsNoekkel" : "040106",
    "postnummer" : "4.1.6",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "formuesverdiForAndelIRentedelIVerdipapirfond" : {
    "tekniskNavn" : "formuesverdiForAndelIRentedelIVerdipapirfond",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetFormue",
    "sorteringsNoekkel" : "040105",
    "postnummer" : "4.1.5",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "formuesverdiForAnnenFastEiendomInnenforInntektsgivendeAktivitet" : {
    "tekniskNavn" : "formuesverdiForAnnenFastEiendomInnenforInntektsgivendeAktivitet",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerFormue",
    "sorteringsNoekkel" : "040305",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "formuesverdiForAnnenFastEiendomUtenforInntektsgivendeAktivitet" : {
    "tekniskNavn" : "formuesverdiForAnnenFastEiendomUtenforInntektsgivendeAktivitet",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerFormue",
    "sorteringsNoekkel" : "040305",
    "postnummer" : "4.3.5",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "formuesverdiForAnnetFinansprodukt" : {
    "tekniskNavn" : "formuesverdiForAnnetFinansprodukt",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetFormue",
    "sorteringsNoekkel" : "040110",
    "postnummer" : "4.1.10",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "formuesverdiForFormuesobjekterINaeringIkkeOmfattetAvVerdsettingsrabatt" : {
    "tekniskNavn" : "formuesverdiForFormuesobjekterINaeringIkkeOmfattetAvVerdsettingsrabatt",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringFormue",
    "sorteringsNoekkel" : "040403",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "formuesverdiForFritidsbaatMedSalgsverdiOverSalgsverdigrense" : {
    "tekniskNavn" : "formuesverdiForFritidsbaatMedSalgsverdiOverSalgsverdigrense",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerFormue",
    "sorteringsNoekkel" : "040204",
    "postnummer" : "4.2.4",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "formuesverdiForGaardsbruk" : {
    "tekniskNavn" : "formuesverdiForGaardsbruk",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerFormue",
    "sorteringsNoekkel" : "040305",
    "postnummer" : "4.3.5",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "formuesverdiForInnboLoesoereOgFritidsbaatUnderSalgsverdigrense" : {
    "tekniskNavn" : "formuesverdiForInnboLoesoereOgFritidsbaatUnderSalgsverdigrense",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerFormue",
    "sorteringsNoekkel" : "040203",
    "postnummer" : "4.2.3",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "formuesverdiForKjoeretoey" : {
    "tekniskNavn" : "formuesverdiForKjoeretoey",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerFormue",
    "sorteringsNoekkel" : "040206",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "formuesverdiForKontanterIAksjesparekonto" : {
    "tekniskNavn" : "formuesverdiForKontanterIAksjesparekonto",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetFormue",
    "sorteringsNoekkel" : "040108",
    "postnummer" : "4.1.8",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "formuesverdiForKontanterMvIFondskonto" : {
    "tekniskNavn" : "formuesverdiForKontanterMvIFondskonto",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetFormue",
    "sorteringsNoekkel" : "040502",
    "postnummer" : "4.5.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "formuesverdiForNaeringseiendom" : {
    "tekniskNavn" : "formuesverdiForNaeringseiendom",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerFormue",
    "sorteringsNoekkel" : "040305",
    "postnummer" : "4.3.5",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "formuesverdiForObligasjonOgSertifikat" : {
    "tekniskNavn" : "formuesverdiForObligasjonOgSertifikat",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetFormue",
    "sorteringsNoekkel" : "040108",
    "postnummer" : "4.1.8",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "formuesverdiForPrimaerbolig" : {
    "tekniskNavn" : "formuesverdiForPrimaerbolig",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerFormue",
    "sorteringsNoekkel" : "040302",
    "postnummer" : "4.3.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "formuesverdiForSekundaerbolig" : {
    "tekniskNavn" : "formuesverdiForSekundaerbolig",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerFormue",
    "sorteringsNoekkel" : "040302",
    "postnummer" : "4.3.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "formuesverdiForSelveidFritidseiendom" : {
    "tekniskNavn" : "formuesverdiForSelveidFritidseiendom",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerFormue",
    "sorteringsNoekkel" : "040303",
    "postnummer" : "4.3.3",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "formuesverdiForSkogeiendom" : {
    "tekniskNavn" : "formuesverdiForSkogeiendom",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerFormue",
    "sorteringsNoekkel" : "040304",
    "postnummer" : "4.3.4",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "formuesverdiForTomt" : {
    "tekniskNavn" : "formuesverdiForTomt",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerFormue",
    "sorteringsNoekkel" : "040305",
    "postnummer" : "4.3.5",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "fradragForGaverTilFrivilligeOrganisasjoner" : {
    "tekniskNavn" : "fradragForGaverTilFrivilligeOrganisasjoner",
    "temakategori" : "familieOgHelse",
    "temaunderkategori" : "fHFradrag",
    "sorteringsNoekkel" : "030307",
    "postnummer" : "3.3.7",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "fradragForIndividuellSparingTilPensjon" : {
    "tekniskNavn" : "fradragForIndividuellSparingTilPensjon",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "arbeidOgPensjonFradrag",
    "sorteringsNoekkel" : "030305",
    "postnummer" : "3.3.5",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "fradragForKostnaderKnyttetTilArbeid" : {
    "tekniskNavn" : "fradragForKostnaderKnyttetTilArbeid",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "arbeidOgPensjonFradrag",
    "sorteringsNoekkel" : "030202",
    "postnummer" : "3.2.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "fradragForPengetilskudd" : {
    "tekniskNavn" : "fradragForPengetilskudd",
    "temakategori" : "familieOgHelse",
    "temaunderkategori" : "fHFradrag",
    "sorteringsNoekkel" : "030307",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "fradragsberettigetTapPaaKapitalforsikringsavtale" : {
    "tekniskNavn" : "fradragsberettigetTapPaaKapitalforsikringsavtale",
    "temakategori" : "bankLaanForsikring",
    "temaunderkategori" : "bankLaanForsikringFradrag",
    "sorteringsNoekkel" : "030307",
    "postnummer" : "3.3.7",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "fradragsberettigetTapVedRealisasjonAvFastEiendom" : {
    "tekniskNavn" : "fradragsberettigetTapVedRealisasjonAvFastEiendom",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerFradrag",
    "sorteringsNoekkel" : "030306",
    "postnummer" : "3.3.6",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "fremfoerbartUnderskuddFraTidligereAar" : {
    "tekniskNavn" : "fremfoerbartUnderskuddFraTidligereAar",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringFradrag",
    "sorteringsNoekkel" : "030311",
    "postnummer" : "3.3.11",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "gevinstVedRealisasjonAvAksje" : {
    "tekniskNavn" : "gevinstVedRealisasjonAvAksje",
    "temakategori" : "finans",
    "temaunderkategori" : "aksjeInntekt",
    "sorteringsNoekkel" : "030108",
    "postnummer" : "3.1.8",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "gevinstVedRealisasjonAvAksjesparekonto" : {
    "tekniskNavn" : "gevinstVedRealisasjonAvAksjesparekonto",
    "temakategori" : "finans",
    "temaunderkategori" : "aksjeInntekt",
    "sorteringsNoekkel" : "030108",
    "postnummer" : "3.1.8",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "gevinstVedRealisasjonAvAndelIAksjedelIVerdipapirfond" : {
    "tekniskNavn" : "gevinstVedRealisasjonAvAndelIAksjedelIVerdipapirfond",
    "temakategori" : "finans",
    "temaunderkategori" : "aksjeInntekt",
    "sorteringsNoekkel" : "030109",
    "postnummer" : "3.1.9",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "gevinstVedRealisasjonAvAndelIRentedelIVerdipapirfond" : {
    "tekniskNavn" : "gevinstVedRealisasjonAvAndelIRentedelIVerdipapirfond",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetInntekt",
    "sorteringsNoekkel" : "030109",
    "postnummer" : "3.1.9",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "gevinstVedRealisasjonAvAnnetFinansprodukt" : {
    "tekniskNavn" : "gevinstVedRealisasjonAvAnnetFinansprodukt",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetInntekt",
    "sorteringsNoekkel" : "030113",
    "postnummer" : "3.1.13",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "gevinstVedRealisasjonAvFastEiendom" : {
    "tekniskNavn" : "gevinstVedRealisasjonAvFastEiendom",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerInntekt",
    "sorteringsNoekkel" : "020804",
    "postnummer" : "2.8.4",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "gevinstVedRealisasjonAvObligasjonOgSertifikat" : {
    "tekniskNavn" : "gevinstVedRealisasjonAvObligasjonOgSertifikat",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetInntekt",
    "sorteringsNoekkel" : "030110",
    "postnummer" : "3.1.10",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "gevinstVedRealisasjonAvOgUttakFraAksjedelIFondskonto" : {
    "tekniskNavn" : "gevinstVedRealisasjonAvOgUttakFraAksjedelIFondskonto",
    "temakategori" : "finans",
    "temaunderkategori" : "aksjeInntekt",
    "sorteringsNoekkel" : "030104",
    "postnummer" : "3.1.4",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "gevinstVedRealisasjonAvOgUttakFraRentedelIFondskonto" : {
    "tekniskNavn" : "gevinstVedRealisasjonAvOgUttakFraRentedelIFondskonto",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetInntekt",
    "sorteringsNoekkel" : "030104",
    "postnummer" : "3.1.4",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "gevinstVedSalgAvAndelINOKUS" : {
    "tekniskNavn" : "gevinstVedSalgAvAndelINOKUS",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringInntekt",
    "sorteringsNoekkel" : "030112",
    "postnummer" : "3.1.12",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "gjeld" : {
    "tekniskNavn" : "gjeld",
    "temakategori" : "bankLaanForsikring",
    "temaunderkategori" : "bankLaanForsikringGjeld",
    "sorteringsNoekkel" : "040801",
    "postnummer" : "4.8.1",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "gjeldsfradragForKapitalisertFesteavgift" : {
    "tekniskNavn" : "gjeldsfradragForKapitalisertFesteavgift",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerGjeld",
    "sorteringsNoekkel" : "040801",
    "postnummer" : "4.8.1",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "gjenkjoepsverdiForLivsforsikring" : {
    "tekniskNavn" : "gjenkjoepsverdiForLivsforsikring",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetFormue",
    "sorteringsNoekkel" : "040502",
    "postnummer" : "4.5.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "innskudd" : {
    "tekniskNavn" : "innskudd",
    "temakategori" : "bankLaanForsikring",
    "temaunderkategori" : "bankLaanForsikringFormue",
    "sorteringsNoekkel" : "040101",
    "postnummer" : "4.1.1",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "inntektFraGevinstOgTapskonto" : {
    "tekniskNavn" : "inntektFraGevinstOgTapskonto",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringInntekt",
    "sorteringsNoekkel" : "030112",
    "postnummer" : "3.1.12",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "inntektsfoeringAvNegativSaldo" : {
    "tekniskNavn" : "inntektsfoeringAvNegativSaldo",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringInntekt",
    "sorteringsNoekkel" : "030112",
    "postnummer" : "3.1.12",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "inntektsfradragForFagforeningskontingent" : {
    "tekniskNavn" : "inntektsfradragForFagforeningskontingent",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "arbeidOgPensjonFradrag",
    "sorteringsNoekkel" : "030206",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "inntektsfradragFraGevinstOgTapskonto" : {
    "tekniskNavn" : "inntektsfradragFraGevinstOgTapskonto",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringFradrag",
    "sorteringsNoekkel" : "030307",
    "postnummer" : "3.3.7",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "investeringIOppstartsselskap" : {
    "tekniskNavn" : "investeringIOppstartsselskap",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringFradrag",
    "sorteringsNoekkel" : "030314",
    "postnummer" : "3.3.14",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "kaarytelseUtenforJordOgSkogbruksvirksomhet" : {
    "tekniskNavn" : "kaarytelseUtenforJordOgSkogbruksvirksomhet",
    "temakategori" : "familieOgHelse",
    "temaunderkategori" : "fHFradrag",
    "sorteringsNoekkel" : "030303",
    "postnummer" : "3.3.3",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "kapitalinntektFraSkogbruk" : {
    "tekniskNavn" : "kapitalinntektFraSkogbruk",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringInntekt",
    "sorteringsNoekkel" : "030112",
    "postnummer" : "3.1.12",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "kapitalkostnadFraSkogbruk" : {
    "tekniskNavn" : "kapitalkostnadFraSkogbruk",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringFradrag",
    "sorteringsNoekkel" : "030307",
    "postnummer" : "3.3.7",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "kontantbeloep" : {
    "tekniskNavn" : "kontantbeloep",
    "temakategori" : "bankLaanForsikring",
    "temaunderkategori" : "bankLaanForsikringFormue",
    "sorteringsNoekkel" : "040103",
    "postnummer" : "4.1.3",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "livrenterIArbeidsforhold" : {
    "tekniskNavn" : "livrenterIArbeidsforhold",
    "temakategori" : "bankLaanForsikring",
    "temaunderkategori" : "bankInntekt",
    "sorteringsNoekkel" : "030112",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "livrenterSomGjelderBarn16AarEllerYngreUtenforArbeidsforhold" : {
    "tekniskNavn" : "livrenterSomGjelderBarn16AarEllerYngreUtenforArbeidsforhold",
    "temakategori" : "familieOgHelse",
    "temaunderkategori" : "fHInntekt",
    "sorteringsNoekkel" : "020602",
    "postnummer" : "2.6.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "loennsinntektNaturalytelseMv" : {
    "tekniskNavn" : "loennsinntektNaturalytelseMv",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "loennsinntekter",
    "sorteringsNoekkel" : "020101",
    "postnummer" : "2.1.1",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "merkostnaderForKostVedArbeidsoppholdUtenforHjemmet" : {
    "tekniskNavn" : "merkostnaderForKostVedArbeidsoppholdUtenforHjemmet",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "arbeidOgPensjonFradrag",
    "sorteringsNoekkel" : "030207",
    "postnummer" : "3.2.7",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "naeringsinntektFraAndelINOKUS" : {
    "tekniskNavn" : "naeringsinntektFraAndelINOKUS",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringInntekt",
    "sorteringsNoekkel" : "020708",
    "postnummer" : "2.7.8",
    "brukIKalkulator" : "meldingompersoninntekt"
  },
  "nettoinntektVedUtleieAvFastEiendomMv" : {
    "tekniskNavn" : "nettoinntektVedUtleieAvFastEiendomMv",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerInntekt",
    "sorteringsNoekkel" : "020802",
    "postnummer" : "2.8.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "opptjenteRenter" : {
    "tekniskNavn" : "opptjenteRenter",
    "temakategori" : "bankLaanForsikring",
    "temaunderkategori" : "bankInntekt",
    "sorteringsNoekkel" : "030101",
    "postnummer" : "3.1.1",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "opptjenteRenterFraPrivatUtestaaendeFordring" : {
    "tekniskNavn" : "opptjenteRenterFraPrivatUtestaaendeFordring",
    "temakategori" : "bankLaanForsikring",
    "temaunderkategori" : "bankInntekt",
    "sorteringsNoekkel" : "030102",
    "postnummer" : "3.1.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "opptjenteRenterFraUtestaaendeFordring" : {
    "tekniskNavn" : "opptjenteRenterFraUtestaaendeFordring",
    "temakategori" : "bankLaanForsikring",
    "temaunderkategori" : "bankInntekt",
    "sorteringsNoekkel" : "030102",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "overgangsstoenadTilEnsligForelder" : {
    "tekniskNavn" : "overgangsstoenadTilEnsligForelder",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "loennsinntekter",
    "sorteringsNoekkel" : "020101",
    "postnummer" : "2.1.1",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "overskuddAvEnkeltpersonforetakInnenAnnenNaering" : {
    "tekniskNavn" : "overskuddAvEnkeltpersonforetakInnenAnnenNaering",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringInntekt",
    "sorteringsNoekkel" : "020706",
    "postnummer" : "2.7.6",
    "brukIKalkulator" : "meldingompersoninntekt"
  },
  "overskuddAvEnkeltpersonforetakInnenAnnenNaeringFraSykepenger" : {
    "tekniskNavn" : "overskuddAvEnkeltpersonforetakInnenAnnenNaeringFraSykepenger",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringInntekt",
    "sorteringsNoekkel" : "020713",
    "postnummer" : "2.7.13",
    "brukIKalkulator" : "meldingompersoninntekt"
  },
  "overskuddAvEnkeltpersonforetakInnenBarnepassIBarnepasserensHjem" : {
    "tekniskNavn" : "overskuddAvEnkeltpersonforetakInnenBarnepassIBarnepasserensHjem",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringInntekt",
    "sorteringsNoekkel" : "020103",
    "postnummer" : "2.1.3",
    "brukIKalkulator" : "meldingompersoninntekt"
  },
  "overskuddAvEnkeltpersonforetakInnenFiskeOgFangst" : {
    "tekniskNavn" : "overskuddAvEnkeltpersonforetakInnenFiskeOgFangst",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringInntekt",
    "sorteringsNoekkel" : "020703",
    "postnummer" : "2.7.3",
    "brukIKalkulator" : "meldingompersoninntekt"
  },
  "overskuddAvEnkeltpersonforetakInnenFiskeOgFangstFraSykepenger" : {
    "tekniskNavn" : "overskuddAvEnkeltpersonforetakInnenFiskeOgFangstFraSykepenger",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringInntekt",
    "sorteringsNoekkel" : "020713",
    "postnummer" : "2.7.13",
    "brukIKalkulator" : "meldingompersoninntekt"
  },
  "overskuddAvEnkeltpersonforetakInnenJordbrukGartneriPelsdyrMv" : {
    "tekniskNavn" : "overskuddAvEnkeltpersonforetakInnenJordbrukGartneriPelsdyrMv",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringInntekt",
    "sorteringsNoekkel" : "020701",
    "postnummer" : "2.7.1",
    "brukIKalkulator" : "meldingompersoninntekt"
  },
  "overskuddAvEnkeltpersonforetakInnenReindrift" : {
    "tekniskNavn" : "overskuddAvEnkeltpersonforetakInnenReindrift",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringInntekt",
    "sorteringsNoekkel" : "020704",
    "postnummer" : "2.7.4",
    "brukIKalkulator" : "meldingompersoninntekt"
  },
  "overskuddAvEnkeltpersonforetakInnenSkiferproduksjon" : {
    "tekniskNavn" : "overskuddAvEnkeltpersonforetakInnenSkiferproduksjon",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringInntekt",
    "sorteringsNoekkel" : "020705",
    "postnummer" : "2.7.5",
    "brukIKalkulator" : "meldingompersoninntekt"
  },
  "overskuddAvEnkeltpersonforetakInnenSkogbruk" : {
    "tekniskNavn" : "overskuddAvEnkeltpersonforetakInnenSkogbruk",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringInntekt",
    "sorteringsNoekkel" : "020702",
    "postnummer" : "2.7.2",
    "brukIKalkulator" : "meldingompersoninntekt"
  },
  "overskuddAvSDF" : {
    "tekniskNavn" : "overskuddAvSDF",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringInntekt",
    "sorteringsNoekkel" : "020708",
    "postnummer" : null,
    "brukIKalkulator" : "meldingompersoninntekt"
  },
  "overskuddAvUtgiftsgodtgjoerelseForAnnenUtgiftsgodtgjoerelse" : {
    "tekniskNavn" : "overskuddAvUtgiftsgodtgjoerelseForAnnenUtgiftsgodtgjoerelse",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "loennsinntekter",
    "sorteringsNoekkel" : "020104",
    "postnummer" : "2.1.4",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "paaloepteRenter" : {
    "tekniskNavn" : "paaloepteRenter",
    "temakategori" : "bankLaanForsikring",
    "temaunderkategori" : "bankLaanForsikringFradrag",
    "sorteringsNoekkel" : "030301",
    "postnummer" : "3.3.1",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "paaloepteRenterFraInkassoselskap" : {
    "tekniskNavn" : "paaloepteRenterFraInkassoselskap",
    "temakategori" : "bankLaanForsikring",
    "temaunderkategori" : "bankLaanForsikringFradrag",
    "sorteringsNoekkel" : "030301",
    "postnummer" : "3.3.1",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "pensjonFraAndreEnnFolketrygden" : {
    "tekniskNavn" : "pensjonFraAndreEnnFolketrygden",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "pensjonTrygdInntekt",
    "sorteringsNoekkel" : "020202",
    "postnummer" : "2.2.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "pensjonFraUtlandetSkattefriINorge" : {
    "tekniskNavn" : "pensjonFraUtlandetSkattefriINorge",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "pensjonTrygdInntekt",
    "sorteringsNoekkel" : "020205",
    "postnummer" : "2.2.5",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "pensjonFraUtlandetSkattepliktigINorge" : {
    "tekniskNavn" : "pensjonFraUtlandetSkattepliktigINorge",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "pensjonTrygdInntekt",
    "sorteringsNoekkel" : "020202",
    "postnummer" : "2.2.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "pensjonsinnbetaling" : {
    "tekniskNavn" : "pensjonsinnbetaling",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "arbeidOgPensjonFradrag",
    "sorteringsNoekkel" : "030212",
    "postnummer" : "3.2.12",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "pensjonsinntektIEOESTilsvarendeAlderspensjonEllerAFP" : {
    "tekniskNavn" : "pensjonsinntektIEOESTilsvarendeAlderspensjonEllerAFP",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "pensjonTrygdInntekt",
    "sorteringsNoekkel" : "020203",
    "postnummer" : "2.2.3",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "personinntektFraSDFInnenAnnenNaering" : {
    "tekniskNavn" : "personinntektFraSDFInnenAnnenNaering",
    "temakategori" : "naering",
    "temaunderkategori" : "personNaeringInntekt",
    "sorteringsNoekkel" : "010704",
    "postnummer" : "1.7.4",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "personinntektFraSDFInnenFiskeEllerBarnepassIBarnepasserensHjem" : {
    "tekniskNavn" : "personinntektFraSDFInnenFiskeEllerBarnepassIBarnepasserensHjem",
    "temakategori" : "naering",
    "temaunderkategori" : "personNaeringInntekt",
    "sorteringsNoekkel" : "010701",
    "postnummer" : "1.7.1",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "personinntektInnenAnnenNaering" : {
    "tekniskNavn" : "personinntektInnenAnnenNaering",
    "temakategori" : "naering",
    "temaunderkategori" : "personNaeringInntekt",
    "sorteringsNoekkel" : "010602",
    "postnummer" : "1.6.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "personinntektInnenBarnepassIBarnepasserensHjem" : {
    "tekniskNavn" : "personinntektInnenBarnepassIBarnepasserensHjem",
    "temakategori" : "naering",
    "temaunderkategori" : "personNaeringInntekt",
    "sorteringsNoekkel" : "010601",
    "postnummer" : "1.6.1",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "personinntektInnenFiskeOgFangst" : {
    "tekniskNavn" : "personinntektInnenFiskeOgFangst",
    "temakategori" : "naering",
    "temaunderkategori" : "personNaeringInntekt",
    "sorteringsNoekkel" : "010601",
    "postnummer" : "1.6.1",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "premieTilEgenTilleggstrygdForNaeringsdrivende" : {
    "tekniskNavn" : "premieTilEgenTilleggstrygdForNaeringsdrivende",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringFradrag",
    "sorteringsNoekkel" : "030218",
    "postnummer" : "3.2.18",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "premiefondIndividuellPensjonsavtale" : {
    "tekniskNavn" : "premiefondIndividuellPensjonsavtale",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetFormue",
    "sorteringsNoekkel" : "040501",
    "postnummer" : "4.5.1",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "premiefondLivsforsikring" : {
    "tekniskNavn" : "premiefondLivsforsikring",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetFormue",
    "sorteringsNoekkel" : "040501",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "reisefradrag" : {
    "tekniskNavn" : "reisefradrag",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "arbeidOgPensjonFradrag",
    "sorteringsNoekkel" : "030208",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "renteinntektAvAnnetFinansprodukt" : {
    "tekniskNavn" : "renteinntektAvAnnetFinansprodukt",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetInntekt",
    "sorteringsNoekkel" : "030102",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "renteinntektAvLivsforsikring" : {
    "tekniskNavn" : "renteinntektAvLivsforsikring",
    "temakategori" : "bankLaanForsikring",
    "temaunderkategori" : "bankInntekt",
    "sorteringsNoekkel" : "030102",
    "postnummer" : "3.1.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "renteinntektAvObligasjon" : {
    "tekniskNavn" : "renteinntektAvObligasjon",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetInntekt",
    "sorteringsNoekkel" : "030102",
    "postnummer" : "3.1.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "renteinntektAvSkadeforsikring" : {
    "tekniskNavn" : "renteinntektAvSkadeforsikring",
    "temakategori" : "bankLaanForsikring",
    "temaunderkategori" : "bankInntekt",
    "sorteringsNoekkel" : "030102",
    "postnummer" : "3.1.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "renteinntektAvVerdipapirfondsandel" : {
    "tekniskNavn" : "renteinntektAvVerdipapirfondsandel",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetInntekt",
    "sorteringsNoekkel" : "030102",
    "postnummer" : "3.1.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "renteinntektMedEkstrabeskatningForLaanTilSelskap" : {
    "tekniskNavn" : "renteinntektMedEkstrabeskatningForLaanTilSelskap",
    "temakategori" : "bankLaanForsikring",
    "temaunderkategori" : "bankInntekt",
    "sorteringsNoekkel" : "030103",
    "postnummer" : "3.1.3",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "saerfradragForEnsligForsoerger" : {
    "tekniskNavn" : "saerfradragForEnsligForsoerger",
    "temakategori" : "familieOgHelse",
    "temaunderkategori" : "fHFradrag",
    "sorteringsNoekkel" : "030505",
    "postnummer" : "3.5.5",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "saerfradragForLettereNedsattErvervsevne" : {
    "tekniskNavn" : "saerfradragForLettereNedsattErvervsevne",
    "temakategori" : "familieOgHelse",
    "temaunderkategori" : "fHFradrag",
    "sorteringsNoekkel" : "030503",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "saerfradragForStoreSykdomskostnader" : {
    "tekniskNavn" : "saerfradragForStoreSykdomskostnader",
    "temakategori" : "familieOgHelse",
    "temaunderkategori" : "fHFradrag",
    "sorteringsNoekkel" : "030504",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "saerskiltFradragForFiskereOgFangstfolk" : {
    "tekniskNavn" : "saerskiltFradragForFiskereOgFangstfolk",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringFradrag",
    "sorteringsNoekkel" : "030214",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "saerskiltFradragForSjoefolk" : {
    "tekniskNavn" : "saerskiltFradragForSjoefolk",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "loennsinntekter",
    "sorteringsNoekkel" : "020102",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "skattepliktigAvkastningEllerKundeutbytte" : {
    "tekniskNavn" : "skattepliktigAvkastningEllerKundeutbytte",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetInntekt",
    "sorteringsNoekkel" : "030114",
    "postnummer" : "3.1.14",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "skjermingstilleggTilAlderspensjon" : {
    "tekniskNavn" : "skjermingstilleggTilAlderspensjon",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "pensjonTrygdInntekt",
    "sorteringsNoekkel" : "02010801",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "skyldigRestskatt" : {
    "tekniskNavn" : "skyldigRestskatt",
    "temakategori" : "bankLaanForsikring",
    "temaunderkategori" : "bankLaanForsikringGjeld",
    "sorteringsNoekkel" : "040801",
    "postnummer" : "4.8.1",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "skyldigUnderholdsbidrag" : {
    "tekniskNavn" : "skyldigUnderholdsbidrag",
    "temakategori" : "familieOgHelse",
    "temaunderkategori" : "fHGjeld",
    "sorteringsNoekkel" : "040801",
    "postnummer" : "4.8.1",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "slitertillegg" : {
    "tekniskNavn" : "slitertillegg",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "pensjonTrygdInntekt",
    "sorteringsNoekkel" : "020202",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "supplerendeStoenad" : {
    "tekniskNavn" : "supplerendeStoenad",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "pensjonTrygdInntekt",
    "sorteringsNoekkel" : "020202",
    "postnummer" : "2.2.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "supplerendeStoenadTilUfoerFlyktning" : {
    "tekniskNavn" : "supplerendeStoenadTilUfoerFlyktning",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "loennsinntekter",
    "sorteringsNoekkel" : "020108",
    "postnummer" : "2.1.8",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "sykepengerMvForBarnepassIBarnepasserensHjem" : {
    "tekniskNavn" : "sykepengerMvForBarnepassIBarnepasserensHjem",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringInntekt",
    "sorteringsNoekkel" : "020103",
    "postnummer" : "2.1.3",
    "brukIKalkulator" : "meldingompersoninntekt"
  },
  "sykepengerMvSomPersoninntektInnenAnnenNaering" : {
    "tekniskNavn" : "sykepengerMvSomPersoninntektInnenAnnenNaering",
    "temakategori" : "naering",
    "temaunderkategori" : "personNaeringInntekt",
    "sorteringsNoekkel" : "010604",
    "postnummer" : "1.6.4",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "sykepengerMvSomPersoninntektInnenBarnepassIBarnepasserensHjem" : {
    "tekniskNavn" : "sykepengerMvSomPersoninntektInnenBarnepassIBarnepasserensHjem",
    "temakategori" : "naering",
    "temaunderkategori" : "personNaeringInntekt",
    "sorteringsNoekkel" : "010603",
    "postnummer" : "1.6.3",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "sykepengerMvSomPersoninntektInnenFiskeOgFangst" : {
    "tekniskNavn" : "sykepengerMvSomPersoninntektInnenFiskeOgFangst",
    "temakategori" : "naering",
    "temaunderkategori" : "personNaeringInntekt",
    "sorteringsNoekkel" : "010603",
    "postnummer" : "1.6.3",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "tapVedRealisasjonAvAksje" : {
    "tekniskNavn" : "tapVedRealisasjonAvAksje",
    "temakategori" : "finans",
    "temaunderkategori" : "aksjeFradrag",
    "sorteringsNoekkel" : "030308",
    "postnummer" : "3.3.8",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "tapVedRealisasjonAvAksjesparekonto" : {
    "tekniskNavn" : "tapVedRealisasjonAvAksjesparekonto",
    "temakategori" : "finans",
    "temaunderkategori" : "aksjeFradrag",
    "sorteringsNoekkel" : "030308",
    "postnummer" : "3.3.8",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "tapVedRealisasjonAvAndelIAksjedelIVerdipapirfond" : {
    "tekniskNavn" : "tapVedRealisasjonAvAndelIAksjedelIVerdipapirfond",
    "temakategori" : "finans",
    "temaunderkategori" : "aksjeFradrag",
    "sorteringsNoekkel" : "030309",
    "postnummer" : "3.3.9",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "tapVedRealisasjonAvAndelIRentedelIVerdipapirfond" : {
    "tekniskNavn" : "tapVedRealisasjonAvAndelIRentedelIVerdipapirfond",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetFradrag",
    "sorteringsNoekkel" : "030309",
    "postnummer" : "3.3.9",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "tapVedRealisasjonAvAnnetFinansprodukt" : {
    "tekniskNavn" : "tapVedRealisasjonAvAnnetFinansprodukt",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetFradrag",
    "sorteringsNoekkel" : "030313",
    "postnummer" : "3.3.13",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "tapVedRealisasjonAvObligasjoner" : {
    "tekniskNavn" : "tapVedRealisasjonAvObligasjoner",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetFradrag",
    "sorteringsNoekkel" : "030310",
    "postnummer" : "3.3.10",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "tapVedRealisasjonAvOgUttakFraAksjedelIFondskonto" : {
    "tekniskNavn" : "tapVedRealisasjonAvOgUttakFraAksjedelIFondskonto",
    "temakategori" : "finans",
    "temaunderkategori" : "aksjeFradrag",
    "sorteringsNoekkel" : "030307",
    "postnummer" : "3.3.7",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "tapVedRealisasjonAvOgUttakFraRentedelIFondskonto" : {
    "tekniskNavn" : "tapVedRealisasjonAvOgUttakFraRentedelIFondskonto",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetFradrag",
    "sorteringsNoekkel" : "030307",
    "postnummer" : "3.3.7",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "tapVedSalgAvAndelINOKUS" : {
    "tekniskNavn" : "tapVedSalgAvAndelINOKUS",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringFradrag",
    "sorteringsNoekkel" : "030307",
    "postnummer" : "3.3.7",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "ufoererenterOgLivrenterUtenforArbeidsforhold" : {
    "tekniskNavn" : "ufoererenterOgLivrenterUtenforArbeidsforhold",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "pensjonTrygdInntekt",
    "sorteringsNoekkel" : "020601",
    "postnummer" : "2.6.1",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "ufoeretrygdFoerAlderspensjon" : {
    "tekniskNavn" : "ufoeretrygdFoerAlderspensjon",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "loennsinntekter",
    "sorteringsNoekkel" : "020108",
    "postnummer" : "2.1.8",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "ufoeretrygdFraFolketrygden" : {
    "tekniskNavn" : "ufoeretrygdFraFolketrygden",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "loennsinntekter",
    "sorteringsNoekkel" : "020108",
    "postnummer" : "2.1.8",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "ufoereytelseFraAndreEnnFolketrygden" : {
    "tekniskNavn" : "ufoereytelseFraAndreEnnFolketrygden",
    "temakategori" : "arbeidTrygdPensjon",
    "temaunderkategori" : "loennsinntekter",
    "sorteringsNoekkel" : "020109",
    "postnummer" : "2.1.9",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "underholdsbidragTilGode" : {
    "tekniskNavn" : "underholdsbidragTilGode",
    "temakategori" : "familieOgHelse",
    "temaunderkategori" : "fHFormue",
    "sorteringsNoekkel" : "040106",
    "postnummer" : "4.1.6",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "underskuddAvEnkeltpersonforetakInnenAnnenNaering" : {
    "tekniskNavn" : "underskuddAvEnkeltpersonforetakInnenAnnenNaering",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringFradrag",
    "sorteringsNoekkel" : "030219",
    "postnummer" : "3.2.19",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "underskuddFraSDF" : {
    "tekniskNavn" : "underskuddFraSDF",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringFradrag",
    "sorteringsNoekkel" : "030219",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "underskuddVedUtleieMvAvFastEiendomUtenforNaering" : {
    "tekniskNavn" : "underskuddVedUtleieMvAvFastEiendomUtenforNaering",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerFradrag",
    "sorteringsNoekkel" : "030312",
    "postnummer" : "3.3.12",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "utbytteFraAksje" : {
    "tekniskNavn" : "utbytteFraAksje",
    "temakategori" : "finans",
    "temaunderkategori" : "aksjeInntekt",
    "sorteringsNoekkel" : "030105",
    "postnummer" : "3.1.5",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "utbytteFraVerdipapirfond" : {
    "tekniskNavn" : "utbytteFraVerdipapirfond",
    "temakategori" : "finans",
    "temaunderkategori" : "aksjeInntekt",
    "sorteringsNoekkel" : "030106",
    "postnummer" : "3.1.6",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "utgiftVedRefinansieringAvLaan" : {
    "tekniskNavn" : "utgiftVedRefinansieringAvLaan",
    "temakategori" : "bankLaanForsikring",
    "temaunderkategori" : "bankLaanForsikringFradrag",
    "sorteringsNoekkel" : "030302",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "verdiFoerVerdsettingsrabattForAksje" : {
    "tekniskNavn" : "verdiFoerVerdsettingsrabattForAksje",
    "temakategori" : "finans",
    "temaunderkategori" : "aksjeFormue",
    "sorteringsNoekkel" : "040108",
    "postnummer" : "4.1.8",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "verdiFoerVerdsettingsrabattForAksjeOgAksjefondIFondskonto" : {
    "tekniskNavn" : "verdiFoerVerdsettingsrabattForAksjeOgAksjefondIFondskonto",
    "temakategori" : "finans",
    "temaunderkategori" : "aksjeFormue",
    "sorteringsNoekkel" : "040502",
    "postnummer" : "4.5.2",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "verdiFoerVerdsettingsrabattForAksjedelIAksjesparekonto" : {
    "tekniskNavn" : "verdiFoerVerdsettingsrabattForAksjedelIAksjesparekonto",
    "temakategori" : "finans",
    "temaunderkategori" : "aksjeFormue",
    "sorteringsNoekkel" : "040108",
    "postnummer" : "4.1.8",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "verdiFoerVerdsettingsrabattForAndelIAksjedelIVerdipapirfond" : {
    "tekniskNavn" : "verdiFoerVerdsettingsrabattForAndelIAksjedelIVerdipapirfond",
    "temakategori" : "finans",
    "temaunderkategori" : "aksjeFormue",
    "sorteringsNoekkel" : "040104",
    "postnummer" : "4.1.4",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "verdiFoerVerdsettingsrabattForAndelIFellesNettoformueISDF" : {
    "tekniskNavn" : "verdiFoerVerdsettingsrabattForAndelIFellesNettoformueISDF",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringFormue",
    "sorteringsNoekkel" : "040504",
    "postnummer" : "4.5.4",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "verdiFoerVerdsettingsrabattForFormueInnenforInntektsgivendeAktivitet" : {
    "tekniskNavn" : "verdiFoerVerdsettingsrabattForFormueInnenforInntektsgivendeAktivitet",
    "temakategori" : "finans",
    "temaunderkategori" : "finansAnnetFormue",
    "sorteringsNoekkel" : "040108",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "verdiFoerVerdsettingsrabattForFormuesobjekterINaeringOmfattetAvVerdsettingsrabatt" : {
    "tekniskNavn" : "verdiFoerVerdsettingsrabattForFormuesobjekterINaeringOmfattetAvVerdsettingsrabatt",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringFormue",
    "sorteringsNoekkel" : "040401",
    "postnummer" : null,
    "brukIKalkulator" : "BrukesDirekte"
  },
  "verdiFoerVerdsettingsrabattForKapitalisertFesteavgift" : {
    "tekniskNavn" : "verdiFoerVerdsettingsrabattForKapitalisertFesteavgift",
    "temakategori" : "boligOgEiendeler",
    "temaunderkategori" : "boligEindelerFormue",
    "sorteringsNoekkel" : "040504",
    "postnummer" : "4.5.4",
    "brukIKalkulator" : "BrukesDirekte"
  },
  "verdiFoerVerdsettingsrabattForSkipFiskeOgFangstfartoey" : {
    "tekniskNavn" : "verdiFoerVerdsettingsrabattForSkipFiskeOgFangstfartoey",
    "temakategori" : "naering",
    "temaunderkategori" : "naeringFormue",
    "sorteringsNoekkel" : "040404",
    "postnummer" : "4.4.4",
    "brukIKalkulator" : "BrukesDirekte"
  }
}