export function utledSkattepliktversjon(inntektsaar) {
  if (inntektsaar === 2018) {
    return 'skattepliktV5';
  }
  if (inntektsaar === 2019) {
    return 'skattepliktV6';
  }
  if (inntektsaar === 2020) {
    return 'skattepliktV7';
  }
  if (inntektsaar === 2021) {
    return 'skattepliktV8';
  }
  if (inntektsaar === 2022) {
    return 'skattepliktV9';
  }
  if (inntektsaar === 2023) {
    return 'skatteplikt';
  }
  return 'skatteplikt';
}
