import { i18n } from '../../i18n/tekster';

export function getValideringsmelding(valideringer, tekniskNavn) {
  if (valideringer) {
    const valideringForTekniskNavn = valideringer.find(x => x.gjelderFelt === tekniskNavn);
    if (valideringForTekniskNavn) {
      return i18n('ledetekst.' + valideringForTekniskNavn.kode);
    }
  }
  return undefined;
}

export function getValideringsmeldingPensjonsgrad(valideringer, tekniskNavn) {
  if (valideringer) {
    const valideringForTekniskNavn = valideringer.filter(validering => validering.gjelderFelt === tekniskNavn);

    const manglerPensjonsgrad = valideringForTekniskNavn.find(validering => validering.kode === 'validering.alderspensjon.manglerPensjonsgrad');
    const ugyldigPensjonsgrad = valideringForTekniskNavn.find(validering => validering.kode === 'validering.alderspensjon.ugyldigPensjonsgrad');

    if (manglerPensjonsgrad){
      return i18n('ledetekst.validering.alderspensjon.manglerPensjonsgrad');
    }
    if (ugyldigPensjonsgrad){
      return i18n('ledetekst.validering.alderspensjon.ugyldigPensjonsgrad');
    }
  }
}

export function getValideringsmeldingAntallMaaneder(valideringer, tekniskNavn) {
  if (valideringer) {
    const valideringForTekniskNavn = valideringer.filter(validering => validering.gjelderFelt === tekniskNavn);

    const manglerAntallMaaneder = valideringForTekniskNavn.find(validering => validering.kode === 'validering.alderspensjon.manglerAntallMaaneder');

    if (manglerAntallMaaneder){
      return i18n('ledetekst.validering.alderspensjon.manglerAntallMaaneder');
    }
  }
}
