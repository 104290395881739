import Rad from './Rad/Rad';
import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '../../../i18n/tekster';
import Styles from './detaljer.module.css';
import utledBeregnetSkattVersjon from '../utledBeregnetSkattVersjon';
import Header from './Header/Header';

const utledFormueskattTilStat = (inntektsaar) => {
  if (inntektsaar >= 2022) {
    return 'formuesskattTilStat'
  }
  return 'formueskattTilStat'
}

const utledFormueskattTilKommune = (inntektsaar) => {
  if (inntektsaar >= 2022) {
    return 'formuesskattTilKommune'
  }
  return 'formueskattTilKommune'
}

const getGrunnlag = (skatteobjekt, noekkel) => {
  return skatteobjekt && skatteobjekt.getIn([noekkel, 'grunnlag']) || 0;
}

const getBeloep = (skatteobjekt, noekkel, inntektsaar) => {
  if (inntektsaar >= 2023) {
    return skatteobjekt && skatteobjekt.getIn([noekkel]) || 0;
  }
  return skatteobjekt && skatteobjekt.getIn([noekkel, 'beloep']) || 0;
}

const Detaljer = (props) => {

  const { inntektsaar, beregning } = props;

  const beregnetSkatt = beregning.get(utledBeregnetSkattVersjon(inntektsaar));
  const skattOgAvgift = beregnetSkatt && beregnetSkatt.get('skattOgAvgift');

  return (
    <table className={Styles.tabellContainer}>
      <Header />
      <tbody>
        <Rad
          grunnlag={getGrunnlag(
            skattOgAvgift,
            utledFormueskattTilStat(inntektsaar)
          )}
          beloep={getBeloep(
            skattOgAvgift,
            utledFormueskattTilStat(inntektsaar)
          )}
          label={i18n('ledetekst.formueskattTilStat')}
        />
        <Rad
          grunnlag={getGrunnlag(
            skattOgAvgift,
            utledFormueskattTilKommune(inntektsaar)
          )}
          beloep={getBeloep(
            skattOgAvgift,
            utledFormueskattTilKommune(inntektsaar)
          )}
          label={i18n('ledetekst.formueskattTilKommune')}
        />
        <Rad
          grunnlag={getGrunnlag(skattOgAvgift, 'inntektsskattTilKommune')}
          beloep={getBeloep(skattOgAvgift, 'inntektsskattTilKommune')}
          label={i18n('ledetekst.inntektsskattTilKommune')}
        />
        <Rad
          grunnlag={getGrunnlag(
            skattOgAvgift,
            'inntektsskattTilFylkeskommune'
          )}
          beloep={getBeloep(skattOgAvgift, 'inntektsskattTilFylkeskommune')}
          label={i18n('ledetekst.inntektsskattTilFylkeskommune')}
        />
        <Rad
          grunnlag={getGrunnlag(skattOgAvgift, 'fellesskatt')}
          beloep={getBeloep(skattOgAvgift, 'fellesskatt')}
          label={i18n('ledetekst.fellesskatt')}
        />
        <Rad
          grunnlag={getGrunnlag(skattOgAvgift, 'trinnskatt')}
          beloep={getBeloep(skattOgAvgift, 'trinnskatt')}
          label={i18n('ledetekst.trinnskatt')}
        />
        <Rad
          grunnlag={getGrunnlag(skattOgAvgift, 'sumTrygdeavgift')}
          beloep={getBeloep(skattOgAvgift, 'sumTrygdeavgift')}
          label={i18n('ledetekst.sumTrygdeavgift')}
        />
        <Rad
          grunnlag={''}
          beloep={getBeloep(beregnetSkatt, 'sumSkattefradrag', inntektsaar)}
          label={i18n('ledetekst.sumSkattefradrag')}
        />
      </tbody>
    </table>);
}

Detaljer.propTypes = {
  inntektsaar: PropTypes.number.isRequired,
  beregning: PropTypes.object.isRequired,
};

export default Detaljer;