
const numberFormatter = new Intl.NumberFormat('nb-NO');

export const formaterTall = (tall) => tall !== undefined && tall !== null && tall !== ''
  ? numberFormatter.format(tall)
    .replace(/\u2212/g, '-')
    .replace("kr", "")
    .replace("NOK", "")
    .replace(/,/g, " ")
    .replace(/\s/g, " ")
    .trim()
  : '';