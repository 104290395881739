import Styles from './header.module.css';
import { i18n } from '../../../../i18n/tekster';
import React from 'react';

const Header = () => {
  return (
    <thead>
      <tr className={Styles.container}>
        <th className={Styles.headerLabel}>
          {i18n('ledetekst.skatt')}
        </th>
        <th className={Styles.headerNumber}>
          {i18n('ledetekst.grunnlag')}
        </th>
        <th className={Styles.headerNumber}>
          {i18n('ledetekst.beloep')}
        </th>
      </tr>
    </thead>
  );
}

export default Header;