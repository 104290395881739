import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import SkeBasis from '@skatteetaten/frontend-components/SkeBasis';
import { byggTekster } from './i18n/tekster';
import { getLocale } from './i18n/getLocale';

byggTekster(getLocale());

ReactDOM.render(
  <SkeBasis>
    <App/>
  </SkeBasis>,
  document.getElementById('root')
);
