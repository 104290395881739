import React from 'react';
import fjernUgyldigeTegn, { ertstatteKomma } from '../../../validering/ugyldigeTegn';
import { byggMaaneder } from '../../../felles/byggStandardposter';
import {
  utledPostnavnForAntallMaenederMedPensjon,
  utledPostnavnForPensjonsgrad,
} from '../../../felles/utledAarsavhengigPostnavn';
import { i18n } from '../../../i18n/tekster';
import {
    getValideringsmeldingPensjonsgrad,
    getValideringsmeldingAntallMaaneder
} from '../../../validering/poster/valideringsmeldinger';
import Dropdown from '@skatteetaten/frontend-components/Dropdown';
import TextField from '@skatteetaten/frontend-components/TextField';
import PostLabel from './../PostLabel/PostLabel';
import Styles from './pensjon.module.css';
import { fjernFormateringKroner } from '../../../felles/utils';
import { formaterTall } from '../../../felles/numberFormatter';

const pensjonsPosterUtenPensjonsgradOgMnder = [
  'pensjonFraUtlandetSkattefriINorge',
  'pensjonFraUtlandetSkattepliktigINorge',
];

const Pensjon = (props) => {

  const { post, oppdaterPostInnhold, valideringer, onDelete } = props;

  const mndOptions = byggMaaneder();
  const validerPensjonsgrad = getValideringsmeldingPensjonsgrad(valideringer, post.get('tekniskNavn'));
  const validerAntallMaaneder = getValideringsmeldingAntallMaaneder(valideringer, post.get('tekniskNavn'));
  const visPensjonsgradOgMnder = !pensjonsPosterUtenPensjonsgradOgMnder.find(elm => post.get('tekniskNavn') === elm);

  return (
    <div className="post">
      <PostLabel
        post={post}
        onDelete={onDelete}
        id={post.get('id')}
        ledetekst={i18n(post.get('tekniskNavn') + '.ledetekst')}
      />
      <TextField
        id={post.get('id')}
        value={formaterTall(post.get('beloep'))}
        autoFocus
        onChange={(event, value) =>
          oppdaterPostInnhold(
            post.set('beloep', fjernFormateringKroner(fjernUgyldigeTegn(value)))
          )
        }
      />
      {visPensjonsgradOgMnder && (
        <div className={Styles.pensjonsgradOgMnderContainer}>
          <div className={Styles.pensjonsgradContainer}>
            <TextField
              label={i18n('ledetekst.pensjonsgrad')}
              requiredWithMark
              value={post.get(utledPostnavnForPensjonsgrad())}
              maxLength={5}
              onChange={(evt, value) => {
                oppdaterPostInnhold(
                  post.set(
                    utledPostnavnForPensjonsgrad(),
                    ertstatteKomma(value)
                  )
                );
              }}
              errorMessage={validerPensjonsgrad}
            />
          </div>
          <div className={Styles.antallMaenederContainer}>
            <Dropdown
              label={i18n('ledetekst.antallMaaneder')}
              requiredWithMark
              placeholder={i18n('ledetekst.velgISelectboks')}
              options={mndOptions}
              selectedKey={post.get(utledPostnavnForAntallMaenederMedPensjon())}
              onChange={(evt, value) => {
                oppdaterPostInnhold(
                  post.set(
                    utledPostnavnForAntallMaenederMedPensjon(),
                    value.key
                  )
                );
              }}
              errorMessage={validerAntallMaaneder}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Pensjon;