import React from 'react';
import Styles from './tabs.module.css';

import { Tabs as DesignTabs } from "@skatteetaten/frontend-components/Tabs";
import { TabItem } from "@skatteetaten/frontend-components/Tabs/TabItem";

const Tabs = ({ options, onTabChange }) => (
  <DesignTabs
    className={Styles.tabs}
    underline
    headersOnly
    onLinkClick={function (item) {
      onTabChange(item.props.itemKey === "ektefelle");
    }}
  >
    {options.map((option, i) => (
      <TabItem
        headerText={option.text}
        key={option.text}
        itemKey={option.itemKey}
      />
    ))}
  </DesignTabs>
);

export default Tabs;
