import React from 'react';

import {
  TopStripe,
  LanguagePicker,
  LanguageEnum,
} from "@skatteetaten/frontend-components/TopStripe";
import TopBanner from '@skatteetaten/frontend-components/TopBanner'
import Styles from './toppmeny.module.css';
import { EN_US, NB_NO, NN_NO } from '../../i18n/spraak';
import Link from '@skatteetaten/frontend-components/Link';

const fromEnum = (value) => {
  if (value === LanguageEnum.ENGLISH) {
    return EN_US;
  }
  if (value === LanguageEnum.NYNORSK) {
    return NN_NO;
  }
  return NB_NO;
}

const toEnum = (value) => {
  if (value === EN_US) {
    return LanguageEnum.ENGLISH;
  }
  if (value === NN_NO) {
    return LanguageEnum.NYNORSK;
  }
  return LanguageEnum.BOKMAAL;
}


const Toppmeny = (props) => {

  const { tittel, locale, onChangeLocale, hopp } = props;

  return (
    <TopBanner
      external={true}
      title={tittel}
      homeText={"Til skatteetaten.no"}
      homeUrl={"https://www.skatteetaten.no"}

      logoLink={true}
      topStripe={
        <TopStripe>
          <Link
            path={'#main-content-id'}
            text={hopp}
            skipLink
          />
          <LanguagePicker
            className={Styles.localeBtn}
            selectedLanguage={toEnum(locale)}
            setLanguage={(value) => onChangeLocale(fromEnum(value))}
            showOnMobile={true}
          />
        </TopStripe>
      }
    />
  );

}

export default Toppmeny;