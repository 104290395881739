const utledResultatversjon = (inntektsaar) => {
  switch (inntektsaar) {
  case 2022:
    return 'resultatV4';
  case 2023:
    return 'resultat';
  default:
    return 'resultat';
  }
}

export default utledResultatversjon;