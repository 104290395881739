export const UtledSkvisVersjon = (inntektsaar) => {
  switch (inntektsaar) {
  case 2018:
  case 2019:
    return 'summertSkattegrunnlagForVisningV5';
  case 2020:
  case 2021:
    return 'summertSkattegrunnlagForVisningV6';
  case 2022:
    return 'summertSkattegrunnlagForVisningV7';
  case 2023:
    return 'summertSkattegrunnlagForVisning';
  default:
    return 'summertSkattegrunnlagForVisning';
  }
}