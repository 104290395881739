const tekniskeNavnSomSkalNullstilles = [
  "pensjonsinnbetalingTilIPSIEOES",
  "pensjonsinnbetalingTilAvtaleOmSkattefavorisertIndividuellSparingTilPensjonIEOESTegnetFomNov2017"
];

export function nullstillPostbeloep(poster) {

  const ips = poster.find(post => post.get('tekniskNavn') === "pensjonsinnbetalingTilIPS");
  const ipsBeleoep = (ips && ips.get('beloep')) || 0;

  const avtale = poster.find(post => post.get('tekniskNavn') === "pensjonsinnbetalingTilAvtaleOmSkattefavorisertIndividuellSparingTilPensjonTegnetFomNov2017");
  const avtaleBeleoep = (avtale && avtale.get('beloep')) || 0;

  if (ipsBeleoep > 0 || avtaleBeleoep > 0) {
    return poster.map(post => {
      if (tekniskeNavnSomSkalNullstilles.indexOf(post.get('tekniskNavn')) > -1) {
        return post.set('beloep', 0)
      }
      return post;
    });
  }

  return poster;

}