export function byggNyePoster(poster) {

  const tekniskeNavnSomKreverVerdiFoerVerdsettingsrabattForFastEiendom = [
    "formuesverdiForPrimaerbolig",
    "formuesverdiForSekundaerbolig",
  ];

  const tekniskeNavnSomGjelderPensjonsgrad = [
    "alderspensjonFraFolketrygden",
    "pensjonsinntektIEOESTilsvarendeAlderspensjonEllerAFP",
    "avtalefestetPensjon",
    "supplerendeStoenad",
  ];

  const loennTilSjoefolkTekniskeNavn = ['saerskiltFradragForSjoefolk', 'loennsinntekt'];

  const tekniskeNavnSomHindrerBSU = [
    "formuesverdiForPrimaerbolig",
    "formuesverdiForSekundaerbolig",
    "formuesverdiForGaardsbruk"
  ];

  let harFastEiendomSomFormuesobjekt = poster
    .filter(post => post.get('beloep') !== '')
    .find(post => tekniskeNavnSomHindrerBSU.includes(post.get('tekniskNavn')));

  const erPostForBSUGyldig = (post) => {
    return harFastEiendomSomFormuesobjekt
      ? post.get('tekniskNavn') !== 'beloepSpartIBSUIInntektsaar'
      : post;
  }

  let alleSpesielleTekniskeNavn = [];
  alleSpesielleTekniskeNavn.push(
    ...tekniskeNavnSomKreverVerdiFoerVerdsettingsrabattForFastEiendom,
    ...tekniskeNavnSomGjelderPensjonsgrad,
    ...loennTilSjoefolkTekniskeNavn,
    "barnepensjonFraFolketrygden"
    );

  let nyePoster = [];

  poster
    .filter(post => post.get('beloep') !== '')
    .filter(post => erPostForBSUGyldig(post))
    .map(post => {
      if (tekniskeNavnSomKreverVerdiFoerVerdsettingsrabattForFastEiendom.includes(post.get('tekniskNavn'))
        && post.get('verdiFoerVerdsettingsrabattForFastEiendom') !== undefined) {
        nyePoster.push({
          tekniskNavn: post.get('tekniskNavn'),
          beloep: post.get('beloep'),
          verdiFoerVerdsettingsrabattForFastEiendom: post.get('verdiFoerVerdsettingsrabattForFastEiendom')
        });
      }
      if (post.get('tekniskNavn') === 'barnepensjonFraFolketrygden' && post.get('barnetsPersonidentifikator') !== undefined) {
        nyePoster.push({
          tekniskNavn: post.get('tekniskNavn'),
          beloep: post.get('beloep'),
          barnetsPersonidentifikator: post.get('barnetsPersonidentifikator')
        });
      }
      if (tekniskeNavnSomGjelderPensjonsgrad.includes(post.get('tekniskNavn'))) {
        nyePoster.push({
          tekniskNavn: post.get('tekniskNavn'),
          beloep: post.get('beloep'),
          antallMaanederMedPensjon: post.get('antallMaanederMedPensjon'),
          gjennomsnittligVektetPensjonsgrad: post.get('gjennomsnittligVektetPensjonsgrad')
        });
      }
      //post for saerskiltFradragForSjoefolk før fastsettingsberegning
      if (post.get('tekniskNavn') === 'saerskiltFradragForSjoefolk' && !post.get('tekniskNavnFraFastsetting')) {
        nyePoster.push({
          tekniskNavn: 'loennsinntekt',
          beloep: post.get('beloep')
        });
      }

      //post for saerskiltFradragForSjoefolk etter fastsettingsberegning
      if (post.get('tekniskNavn') === 'loennsinntekt' && post.get('beloepFraFastsetting') !== undefined) {
        const saerskiltFradragForSjoefolk =
          {
            tekniskNavn: post.get('tekniskNavnFraFastsetting'),
            beloep: post.get('beloepFraFastsetting')
          }
        const loennsinntekt =
          {
            tekniskNavn: 'loennsinntekt',
            beloep: post.get('beloep')
          }
        nyePoster.push(saerskiltFradragForSjoefolk, loennsinntekt);
      }

      if (!alleSpesielleTekniskeNavn.includes(post.get('tekniskNavn'))) {
        nyePoster.push({
          tekniskNavn: post.get('tekniskNavn'),
          beloep: post.get('beloep')
        });
      }
      return undefined;

    });
  return nyePoster;
}