import React from 'react';
import { i18n } from '../../../i18n/tekster';
import PropTypes from 'prop-types';
import { hentPostFraListe } from '../../../felles/HentPostFraListe';
import { formaterTall } from '../../../felles/numberFormatter';

const gyldigeTekniskeNavn = [
  "gaveTilGodkjentFrivilligOrganisasjonIUtlandet",
  "gaveTilFrivilligOrganisasjon",
  "fradragForGaverTilFrivilligeOrganisasjoner"
];

export const OrganisasjonsgaverUndertekst = (props) => {

  const { post, skattegrunnlagsobjekt } = props;

  if (gyldigeTekniskeNavn.includes(post.get('tekniskNavn')) && post.get('beloep')) {
    const skvisPost = hentPostFraListe('fradragForGaverTilFrivilligeOrganisasjoner', skattegrunnlagsobjekt);
    const skvisBeloep = skvisPost && parseInt(skvisPost.get('beloep'), 10);
    const postBeloep = parseInt(post.get('beloep'), 10);
    if (postBeloep && skvisBeloep) {
      return (
        <div className="postbeholder_undertekst">
          { i18n('ledetekst.fradrag.blir').replace("{0}", formaterTall(skvisBeloep)) }
        </div>
      )
    }
  }

  return null;

};

OrganisasjonsgaverUndertekst.propTypes = {
  post: PropTypes.object.isRequired,
  skattegrunnlagsobjekt: PropTypes.object,
};

OrganisasjonsgaverUndertekst.defaultProps = {
  skattegrunnlagsobjekt: null,
};

export default OrganisasjonsgaverUndertekst;
