import { hentGodkjentInstituttOrganisasjonsnummer } from '../../felles/utils';

export function utledGaverTilFrivilligeOrganisasjoner(poster) {

  const tilskudd = poster.find(post => post.get('tekniskNavn') === 'fradragForGaverTilFrivilligeOrganisasjoner');
  const godkjentOrganisasjonsnummer = hentGodkjentInstituttOrganisasjonsnummer();

  if (!tilskudd || !godkjentOrganisasjonsnummer) {
    return undefined;
  }
  const beloep = tilskudd.get('beloep');

  return !beloep
    ? undefined
    : {
      "gave": [{
        "godkjentGavemottaker": {
          organisasjonsnummer: godkjentOrganisasjonsnummer
        },
        "beloep": {
          beloep: beloep
        }
      }]
    };
}

