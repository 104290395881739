import { NB_NO } from '../spraak';
import { getInntektsaar } from '../../felles/utils';

function oversettInntektsaarTilTekstlisteaar() {
  switch (getInntektsaar()) {
  case 2018:
  case 2019:
  case 2020:
    return 2018;
  case 2021:
  case 2022:
  case 2023:
    return 2021;
  default:
    return 2021;
  }
}

export function byggFasteTekster(locale) {
  const aar = oversettInntektsaarTilTekstlisteaar();
  return JSON.parse(JSON.stringify(
    require('./' + aar + '/ledetekst_' + (locale || NB_NO) + '.json')
  ));
}



