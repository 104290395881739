
export function utledPrimaerboligForFastsetting(poster) {

  const formuesspesifikasjonForBolig = [];

  const formuesverdiForPrimaerbolig = poster.find(post => post.get('tekniskNavn') === 'formuesverdiForPrimaerbolig');
  const formuesverdiForSekundaerbolig = poster.find(post => post.get('tekniskNavn') === 'formuesverdiForSekundaerbolig');

  if (formuesverdiForPrimaerbolig && formuesverdiForPrimaerbolig.get('beloep')) {
    formuesspesifikasjonForBolig.push({
      "id": "PRIMAERBOLIG",
      "formuesverdiPrEiendom": {"beloep": formuesverdiForPrimaerbolig.get('beloep')},
      "andelAvFormuesverdiPrEiendom": formuesverdiForPrimaerbolig.get('andelAvFormuesverdiPrEiendom'),
      "boligbruk": "PRIMAERBOLIG"
    })
  }

  if (formuesverdiForSekundaerbolig && formuesverdiForSekundaerbolig.get('beloep')) {
    formuesspesifikasjonForBolig.push({
      "id": "SEKUNDAERBOLIG",
      "formuesverdiPrEiendom": {"beloep": formuesverdiForSekundaerbolig.get('beloep')},
      "andelAvFormuesverdiPrEiendom": 100,
      "boligbruk": "SEKUNDAERBOLIG"
    })
  }

  if (formuesspesifikasjonForBolig.length === 0) {
    return undefined;
  }

  return { formuesspesifikasjonForBolig };
}

