export function hentPostFraListe(tekniskNavn, poster) {
  let funnetPost = null;
  if (poster) {
    poster.forEach(post => {
      if (post.get('tekniskNavn') === tekniskNavn) {
        funnetPost = post;
      }
    });
  }
  return funnetPost;
}
