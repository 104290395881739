import React, { Component } from 'react';
import Styles from './app.module.css';
import Toppmeny from './components/Toppmeny/Toppmeny';
import Grid from '@skatteetaten/frontend-components/Grid';
import { RadioButtonGroup } from '@skatteetaten/frontend-components/RadioButtonGroup';
import Dropdown from '@skatteetaten/frontend-components/Dropdown';
import { TextField } from '@skatteetaten/frontend-components/TextField';
import CheckBox from '@skatteetaten/frontend-components/CheckBox';
import { Button } from '@skatteetaten/frontend-components/Button';
import { byggTekster, i18n } from './i18n/tekster';
import { fromJS } from 'immutable';
import { getLocale } from './i18n/getLocale';
import { MessageBar } from '@skatteetaten/frontend-components/MessageBar';
import Footer from './components/Footer/Footer';
import { Card } from '@skatteetaten/frontend-components/Card';
import BannerImage from './img/bannerimage.svg'
import { Image } from '@skatteetaten/frontend-components/Image';
import { ImageFit } from '@fluentui/react';


class Skattesubjekt extends Component {

  state = {
    sivilstand: 'INGEN',
    foedselsaar: undefined,
    foedselsaarEF: undefined,
    erFinnmarksfradrag: false,
    tolvdelVedArbeidsoppholdINorge: 12,
    tolvdelVedArbeidsoppholdINorgeEF: 12,
    visMaanederValg: false,
    aar: new Date().getFullYear(),
    locale: getLocale(),
    errors: {}
  };

  componentDidMount() {
    //hent skattesubjekt fra browser session-storage dersom dette finnes
    const sessionStorageSkattesubjekt = typeof Storage !== 'undefined'
      ? sessionStorage.getItem('skattesubjekt')
      : null;

    if (sessionStorageSkattesubjekt) {
      const dataFraSession = fromJS(JSON.parse(sessionStorageSkattesubjekt));

      this.setState({
        sivilstand: dataFraSession.get('sivilstand'),
        foedselsaar: dataFraSession.get('foedselsaar'),
        foedselsaarEF: dataFraSession.get('foedselsaarEF'),
        erFinnmarksfradrag: dataFraSession.get('erFinnmarksfradrag'),
        tolvdelVedArbeidsoppholdINorge: dataFraSession.get('tolvdelVedArbeidsoppholdINorge'),
        tolvdelVedArbeidsoppholdINorgeEF: dataFraSession.get('tolvdelVedArbeidsoppholdINorgeEF'),
        aar: dataFraSession.get('aar'),
        locale: dataFraSession.get('locale'),
        visMaanederValg: dataFraSession.get('visMaanederValg')
      });
    }
  }

  onChangeLocale = nyLocale => {
    this.setState({ locale: nyLocale });
    byggTekster(nyLocale);

    this.setState({ errors: {} });
  };

  erSkattesubjektGyldig() {

    const { sivilstand, foedselsaar, foedselsaarEF } = this.state;
    let alleFeil = {};

    if (sivilstand === 'INGEN') {
      alleFeil[sivilstand] = i18n('validering.sivilstand.paakrevet');
    }
    if (!this.gyldigAlder(foedselsaar)) {
      alleFeil[foedselsaar] = i18n('validering.foedselsaar.paakrevet');
    }
    if (sivilstand === 'GIFT' && !this.gyldigAlder(foedselsaarEF)) {
      alleFeil[foedselsaarEF] = i18n('validering.foedselsaar.paakrevet');
    }
    this.setState({ errors: alleFeil });

    return Object.keys(alleFeil).length === 0;
  }

  gyldigAlder(fodselsaar) {
    if (isNaN(fodselsaar)) {
      return false;
    }
    return parseInt(fodselsaar, 10) > 1900 && parseInt(fodselsaar, 10) <= this.state.aar;
  }

  onVidere = () => {

    const {
      sivilstand,
      foedselsaar,
      foedselsaarEF,
      erFinnmarksfradrag,
      tolvdelVedArbeidsoppholdINorge,
      tolvdelVedArbeidsoppholdINorgeEF,
      aar,
      locale
    } = this.state;
    const alder = aar - foedselsaar > 0 ? aar - foedselsaar : undefined;
    const alderEF = aar - foedselsaarEF > 0 && sivilstand === 'GIFT' ? aar - foedselsaarEF : undefined;
    const kommune = erFinnmarksfradrag ? '2019' : undefined;
    const skattesubjekt = {
      aar: aar,
      alder: [alder],
      alderEF: [alderEF],
      kommune: [kommune],
      locale: locale,
      sivilstand: sivilstand,
      tolvdelVedArbeidsoppholdINorge: tolvdelVedArbeidsoppholdINorge,
      tolvdelVedArbeidsoppholdINorgeEF: tolvdelVedArbeidsoppholdINorgeEF,
    };

    if (this.erSkattesubjektGyldig()) {
      const urlParametre = new URLSearchParams(skattesubjekt).toString();
      //ta vare på dataene fra valgt Skattesubjekt i browser-sessionStorage, slik at disse kan hentes frem igjen ved tilbake-klikk i nettleser
      sessionStorage.setItem('skattesubjekt', JSON.stringify(this.state));

      this.props.postForside('?' + urlParametre);
    }
  }


  render() {
    const hideImage = window.innerWidth <= 900; // Check the browser window width
    const lgValueColText = hideImage ? 12 : 7;
    const lgValueColImage = hideImage ? 0 : 5;

    const {
      sivilstand,
      foedselsaar,
      foedselsaarEF,
      erFinnmarksfradrag,
      tolvdelVedArbeidsoppholdINorge,
      tolvdelVedArbeidsoppholdINorgeEF,
      visMaanederValg,
      aar,
      locale,
      errors
    } = this.state;

    byggTekster(locale);

    const oppdaterToggleMaanederValg = () => {
      if (visMaanederValg) {
        this.setState({ visMaanederValg: false });
      } else {
        this.setState({ visMaanederValg: true });
      }
      this.setState({ tolvdelVedArbeidsoppholdINorge: 12, tolvdelVedArbeidsoppholdINorgeEF: 12 });
    }

    const oppdaterFinnmarksfradrag = () => {
      this.setState({ erFinnmarksfradrag: !erFinnmarksfradrag });
    }
    const oppdaterFoedselsaarEF = (foedselsaar, sivilstand) => {
      this.setState({ foedselsaarEF: sivilstand === 'GIFT' ? foedselsaar : undefined });
    }

    const oppdaterAlder = (foedselsaar, sivilstand) => {
      return sivilstand === 'GIFT'
        ? this.setState({ foedselsaarEF: foedselsaar })
        : this.setState({ foedselsaar: foedselsaar })
    }

    const aarValg = [2018, 2019, 2020, 2021, 2022, 2023].map((aar) => ({ key: aar, text: aar }));

    const maanederValg = [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map((mnd) => (
      { key: mnd, text: i18n('iMaaneder').replace('{0}', mnd) }
    ));

    const sivilstandValg = [
      { key: 'INGEN', text: i18n('ingenValgt') },
      { key: 'GIFT', text: i18n('gift') },
      { key: 'UGIFT', text: i18n('ugift') },
      { key: 'SEPARERT', text: i18n('separert') },
      { key: 'SKILT', text: i18n('skilt') },
      { key: 'ENKE', text: i18n('enke') },
      { key: 'ENKEMANN', text: i18n('enkemann') },
    ];

    const currentYear = new Date().getFullYear();

    const tittel = i18n('ledetekst.tittel') + ' ' + aar;
    const bannertitle = i18n('bannerTitle').replace('{0}', currentYear);
    const bannerbrodtekst = i18n('bannerBrodtekst').replace('{0}', currentYear);
    const bannerknappetekst = i18n('bannerknappeTekst').replace('{0}', currentYear);
    const logintekst = i18n('loginTekst').replace('{0}', currentYear);
    const bannerbrodekstekstra = i18n('bannerBrodTekstEkstra');
    const underBannerTitle = i18n('underBannerTitle');

    return (
      <div className={Styles.container}>
        <Toppmeny
          tittel={tittel}
          hopp={i18n('ledetekst.hopp.til.hovedinnhold')}
          locale={locale}
          onChangeLocale={this.onChangeLocale}
        />

        <main className={Styles.mainLayout} id="main-content-id" tabIndex={-1} aria-label={tittel}>
          <Grid padding={'0'} >
            <Grid.Row rowSpacing={Grid.SPACE_NONE}>
              <Grid.Col noSpacing lg={2} xl={3}></Grid.Col>
              <Grid.Col noSpacing sm={12} md={12} lg={8} xl={6} xxl={6} xxxl={4}>
                <Grid>
                  <Grid.Row rowSpacing={Grid.SPACE_NONE} >
                   <Card
                      aria-rowspan={0}
                      color={Card.Color.GREEN}
                      border={Card.Border.WHITE_BORDER}
                    >
                      <Grid.Col noSpacing lg={lgValueColText} xl={7}>
                        <div className={Styles.text}>
                          <h2>{bannertitle} </h2>
                          <p>{bannerbrodtekst}</p>
                          <p>{logintekst}</p>
                          <Button
                            text={bannerknappetekst}
                            buttonStyle={'primary'}
                            type="submit"
                            hoved
                            tabindex={0}
                            onClick={() => {
                              window.open('https://skatt.skatteetaten.no/web/minskatteside/#/','_blank');
                            }}/>
                          <p>{bannerbrodekstekstra}</p>
                        </div>
                      </Grid.Col>
                      <Grid.Col noSpacing lg={lgValueColImage} xl={5}
                                className={Styles.bannerImageholder}>
                        <Image className={Styles.bannerImageholder}
                          src={BannerImage}
                          alt='Skattekort Illustration'
                          imageFit={ImageFit.contain}
                          aria-hidden={true}
                          role={'presentation'}
                        />
                      </Grid.Col>
                    </Card>
                  </Grid.Row>
                </Grid>
              </Grid.Col>
              <Grid.Col lg={2} xl={3}></Grid.Col>
            </Grid.Row>
            <Grid.Row rowSpacing={Grid.SPACE_NONE}>
              <Grid.Col lg={2} xl={3}/>
              <Grid.Col lg={8} xl={6}>
                <div className="ramme-boks">
                  <h2>{underBannerTitle}</h2>
                  <div className={Styles.info}>
                    <MessageBar>{i18n('ledetekst.felter.marker.med.asterisk.er.paakrevd')}</MessageBar>
                  </div>

                  <div className={Styles.skattesubjektRadio}>
                    <RadioButtonGroup
                      label={i18n('velgAar')}
                      options={aarValg}
                      selectedKey={aar}
                      onChange={(e, option) => {
                        this.setState({ aar: option.key });
                        oppdaterAlder(foedselsaar);
                      }}
                    />
                  </div>

                  <div className={Styles.skattesubjekt}>
                    <Dropdown
                      label={i18n('sivilstatus')}
                      requiredWithMark
                      placeholder={i18n('ingenValgt')}
                      options={sivilstandValg}
                      selectedKey={sivilstand}
                      onChange={(e, option) => {
                        this.setState({ sivilstand: option.key });
                        oppdaterFoedselsaarEF(foedselsaarEF, option.key);
                      }}
                      errorMessage={errors[sivilstand]}
                    />
                  </div>

                  <div className={Styles.skattesubjekt}>
                    <TextField
                      label={i18n('foedselsaar')}
                      requiredWithMark
                      placeholder={i18n('foedselsaar.placeholder')}
                      inputMode="numeric"
                      maskChar={''}
                      mask={'9999'}
                      value={foedselsaar}
                      onChange={(e, value) => oppdaterAlder(value)}
                      errorMessage={errors[foedselsaar]}
                    />
                  </div>

                  <div className={Styles.skattesubjekt}>
                    {sivilstand === 'GIFT' &&
                      <TextField
                        label={i18n('foedselsaarEktefelle')}
                        requiredWithMark
                        placeholder={i18n('foedselsaar.placeholder')}
                        inputMode="numeric"
                        maskChar={''}
                        mask={'9999'}
                        value={foedselsaarEF}
                        onChange={(e, value) => oppdaterAlder(value, sivilstand)}
                        errorMessage={errors[foedselsaarEF]}
                      />
                    }
                  </div>

                  <div style={{ marginTop: '20px' }}>
                    <CheckBox
                      boxSide={'start'}
                      label={i18n('skattekalkulator.tolvdelerAvhukningsboks')}
                      checked={visMaanederValg}
                      onChange={oppdaterToggleMaanederValg}
                    />
                  </div>

                  <div className={Styles.skattesubjekt}>
                    {visMaanederValg &&
                      <Dropdown
                        label={i18n('skattekalkulator.tolvdelerNedtrekksliste')}
                        options={maanederValg}
                        selectedKey={tolvdelVedArbeidsoppholdINorge}
                        onChange={(e, option) => this.setState({ tolvdelVedArbeidsoppholdINorge: option.key })}
                      />
                    }
                  </div>

                  <div className={Styles.skattesubjekt}>
                    {visMaanederValg && sivilstand === 'GIFT' &&
                      <Dropdown
                        label={i18n('skattekalkulator.tolvdelerNedtrekkslisteEktefelle')}
                        options={maanederValg}
                        selectedKey={tolvdelVedArbeidsoppholdINorgeEF}
                        onChange={(e, option) => this.setState({ tolvdelVedArbeidsoppholdINorgeEF: option.key })}
                      />
                    }
                  </div>

                  <div style={{ marginTop: '20px' }}>
                    <CheckBox
                      boxSide={'start'}
                      label={i18n('finnmarksfradrag')}
                      checked={erFinnmarksfradrag}
                      onChange={oppdaterFinnmarksfradrag}
                    />
                  </div>

                  <div style={{ marginTop: '40px' }}>
                    <Button
                      buttonStyle={'primary'}
                      type="submit"
                      hoved
                      tabindex={0}
                      onClick={this.onVidere}
                    >
                      {i18n('videre')}
                    </Button>
                  </div>

                </div>
              </Grid.Col>
              <Grid.Col lg={2} xl={3}/>
            </Grid.Row>
          </Grid>
        </main>
        <Footer/>
      </div>
    );
  };
}

export default Skattesubjekt;