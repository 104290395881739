import { Omsorgsgrad } from './Omsorgsgrad.enum';

function omsorgsgradTilEnum(omsorgsgrad) {
  if (omsorgsgrad === 'FULL') {
    return Omsorgsgrad.HEL;
  }
  if (omsorgsgrad === 'DELT') {
    return Omsorgsgrad.DELT;
  }
  return undefined;
}

export function utledSaerfradragEnsligForsoerger(poster, erEktefelle, queryParams) {

  const saerfradragForEnsligForsoerger = poster.find(post => post.get('tekniskNavn') === 'saerfradragForEnsligForsoerger');

  if (!saerfradragForEnsligForsoerger) {
    return undefined;
  }

  const antallMaanederMedMottakAvUtvidetBarnetrygd = saerfradragForEnsligForsoerger.get('antallMaanederMedMottakAvUtvidetBarnetrygd');
  const omsorgsgrad = saerfradragForEnsligForsoerger.get('omsorgsgradVedMottakAvUtvidetBarnetrygd');
  const alder =  queryParams && queryParams[erEktefelle ? 'alderEF' : 'alder'] || 31;

  if (!antallMaanederMedMottakAvUtvidetBarnetrygd || !omsorgsgrad) {
    return undefined;
  }

  return {
    utvidetBarnetrygd: [{
      antallMaaneder: antallMaanederMedMottakAvUtvidetBarnetrygd,
      ytelsesgrad: omsorgsgradTilEnum(omsorgsgrad)
    }],
    personligSkattesubjekt: {
      "alderIInntektsaar": alder,
      "sambeskatningsrolle": []
    }
  }

}

