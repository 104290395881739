import React from 'react';
import { i18n } from '../../../i18n/tekster';
import PropTypes from 'prop-types';
import formatNumber from '../../../felles/formatNumber';
import { hentPostFraListe } from '../../../felles/HentPostFraListe';

export const FradragForFiskereOgFangstfolkUndertekst = (props) => {

  const { post, skattegrunnlagsobjekt } = props;

  const gyldigeTekniskeNavn = ['grunnlagForSaerskiltFradragForFiskereOgFangstfolk', 'saerskiltFradragForFiskereOgFangstfolk']

  if (gyldigeTekniskeNavn.includes(post.get('tekniskNavn'))) {
    const skvisPost = hentPostFraListe('saerskiltFradragForFiskereOgFangstfolk', skattegrunnlagsobjekt);
    const skvisBeloep = skvisPost && parseInt(skvisPost.get('beloep'), 10);
    const postBeloep = parseInt(post.get('beloep'), 10)
    if (postBeloep && skvisBeloep) {
      return (
        <div className="postbeholder_undertekst">
          { i18n('ledetekst.fradrag.blir').replace("{0}", formatNumber(skvisBeloep)) }
        </div>
      )
    }
  }

  return null;

};

FradragForFiskereOgFangstfolkUndertekst.propTypes = {
  post: PropTypes.object.isRequired,
  skattegrunnlagsobjekt: PropTypes.object,
};

FradragForFiskereOgFangstfolkUndertekst.defaultProps = {
  skattegrunnlagsobjekt: undefined,
};

export default FradragForFiskereOgFangstfolkUndertekst;
