import React from 'react';
import { i18n } from '../../../i18n/tekster';
import Styles from './reisefradrag.module.css';
import { formaterTall } from '../../../felles/numberFormatter';
import { fjernFormateringKroner } from '../../../felles/utils';

const Reisefradrag = (props) => {

  const { antallKmReiseFradrag, reisefradrag, inntektsaar } = props;

  const km = antallKmReiseFradrag && parseInt(fjernFormateringKroner(antallKmReiseFradrag), 10) || 0;
  const fradrag = inntektsaar >= 2022
    ? reisefradrag && parseInt(fjernFormateringKroner(reisefradrag), 10) || 0
    : reisefradrag && parseInt(fjernFormateringKroner(reisefradrag.get('beloep')), 10) || 0;

  if (reisefradrag) {
    return (
      <div className={Styles.container}>
        {km > 0 && inntektsaar < 2022 && (
          <div className={Styles.label}>
            {i18n('ledetekst.reisefradrag.totalt.antall.km') + ' ' + formaterTall(km)}
          </div>
        )}

        {inntektsaar >= 2022 && (
          <div className={Styles.label}>
            {i18n('ledetekst.reisefradrag.totalt.antall.km') + ' ' + formaterTall(km)}
          </div>
        )}

        {<div className={Styles.label}>
          {i18n('ledetekst.reisefradrag.blir') + ' ' + formaterTall(fradrag)}
        </div>}
      </div>
    );

  }
  return null;
}

export default Reisefradrag;