import {
  utledPostnavnForAntallMaenederMedPensjon,
  utledPostnavnForPensjonsgrad
} from '../../felles/utledAarsavhengigPostnavn';
import { postHarTallverdi } from './felles/postHarTallverdi';
import { erPensjonsgradGyldig } from '../ugyldigeTegn';

export function validerPensjonspost(post) {

  if (post === null || post === undefined) {
    return [];
  }

  const feiler = [];

  const pensjonsgrad = post.get(utledPostnavnForPensjonsgrad());

  if (pensjonBeloepUtfyltMenIkkePensjongradEllerTolvdel(post, pensjonsgrad)) {
    feiler.push({
      gjelderFelt: post.get('tekniskNavn'),
      kode: 'validering.alderspensjon.manglerPensjonsgrad',
      alvorlighet: 'fatal'
    });
  }

  if (!erPensjonsgradGyldig(pensjonsgrad) && pensjonsgrad !== '') {
    feiler.push({
      gjelderFelt: post.get('tekniskNavn'),
      kode: 'validering.alderspensjon.ugyldigPensjonsgrad',
      alvorlighet: 'fatal'
    });
  }

  const harAntallMaeneder = postHarTallverdi(post, utledPostnavnForAntallMaenederMedPensjon());
  if (!harAntallMaeneder) {
    feiler.push({
      gjelderFelt: post.get('tekniskNavn'),
      kode: 'validering.alderspensjon.manglerAntallMaaneder',
      alvorlighet: 'fatal'
    });
  }

  return feiler;
}

function pensjonBeloepUtfyltMenIkkePensjongradEllerTolvdel(post, pensjonsgrad) {
  const beloep = postHarTallverdi(post, 'beloep');
  return beloep && beloep !== 0 && !pensjonsgrad;
}