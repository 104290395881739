import { getInntektsaar } from './utils';

export const utledPostnavnForAntallMaenederMedPensjon = () => {
  switch (getInntektsaar()) {
    case 2018:
    case 2019:
      return 'tolvdelVedPensjonsinntekt';
    case 2020:
    case 2021:
    case 2022:
    case 2023:
      return 'antallMaanederMedPensjon';
    default:
      return 'antallMaanederMedPensjon';
  }
};

export const utledPostnavnForPensjonsgrad = () => {
  switch (getInntektsaar()) {
    case 2018:
    case 2019:
      return 'pensjonsgrad';
    case 2020:
    case 2021:
    case 2022:
    case 2023:
      return 'gjennomsnittligVektetPensjonsgrad';
    default:
      return 'gjennomsnittligVektetPensjonsgrad';
  }
};
