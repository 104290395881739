import React from 'react';
import Grid from '@skatteetaten/frontend-components/Grid';
import { getInntektsaar } from '../../felles/utils';
import utledBeregnetSkattVersjon from './utledBeregnetSkattVersjon';
import Detaljer from './Detaljer/Detaljer';
import Tittel from './Tittel/Tittel';
import { i18n } from '../../i18n/tekster';
import Styles from './beregning.module.css';

const Beregning = (props) => {

  const { beregning, beregningEktefelle, erGift } = props;

  const inntektsaar = getInntektsaar();
  const beregnetSkattVersjon = utledBeregnetSkattVersjon(inntektsaar);
  const beregnetSkattFelt = inntektsaar >= 2023
    ? [beregnetSkattVersjon, 'beregnetSkatt']
    : [beregnetSkattVersjon, 'beregnetSkatt', 'beloep'];

  const beloepHovedperson = (beregning && beregning.getIn(beregnetSkattFelt)) || 0;
  const beloepEktefelle = (beregningEktefelle && beregningEktefelle.getIn(beregnetSkattFelt)) || 0;

  return (
    <Grid>
      <Grid.Row rowSpacing={Grid.SPACE_LARGE} className={Styles.container}>
        <Grid.Col lg={12} xl={6}>
          <Tittel
            tekst={i18n('ledetekst.din_skatt')}
            beloep={beloepHovedperson}
          />
          {beregning && (
            <Detaljer
              beregning={beregning}
              inntektsaar={inntektsaar}
            />
          )}
        </Grid.Col>
        {erGift && (
          <Grid.Col lg={12} xl={6}>
            <div className={Styles.beregningEktefelle}>
              <Tittel
                tekst={i18n('ledetekst.ektefelles_skatt')}
                beloep={beloepEktefelle}
              />
              {beregningEktefelle && (
                <Detaljer
                  beregning={beregningEktefelle}
                  inntektsaar={inntektsaar}
                />
              )}
            </div>
          </Grid.Col>
        )}
      </Grid.Row>
    </Grid>
  );

};

export default Beregning;
