export default function formatNumber(number) {
  const num = `${number}`;
  let output = '';
  let c = 0;
  for (let i = num.length - 1; i > -1; i--) {
    if (/[\d<>]/.test(num[i])) {
      let taken = false;
      if (c === 3) {
        if (/</.test(num[i])) {
          output = ` ${num[i]}${output}`;
          taken = true;
        } else {
          output = ` ${output}`;
        }
        c = 0;
      }

      if (!taken) {
        output = num[i] + output;
      }

      if (/\d/.test(num[i])) {
        c++;
      }
    }
  }

  return output.replace(/^[<>]\s/, '>').trim();
}
