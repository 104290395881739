import { hentGodkjentInstituttOrganisasjonsnummer } from '../../felles/utils';

export function utledTilskuddTilVitenskapeligForskning(poster, alminneligInntektForFastsetting) {

  const fradragForPengetilskuddTekniskeNavn = ['fradragForPengetilskudd', 'pengetilskuddTilVitenskapeligForskning'];

  const tilskudd = poster.find(post => fradragForPengetilskuddTekniskeNavn.includes(post.get('tekniskNavn')));
  const godkjentOrganisasjonsnummer = hentGodkjentInstituttOrganisasjonsnummer();

  if (!tilskudd || !godkjentOrganisasjonsnummer) {
    return undefined;
  }
  const beloep = tilskudd.get('beloep');

  return !beloep
    ? undefined
    : {"tilskudd": [
      {
        "pengetilskudd": {
          "beloep": beloep
        },
        "godkjentInstituttsOrganisasjonsnummer": godkjentOrganisasjonsnummer
      }
    ],
    "alminneligInntekt": [alminneligInntektForFastsetting ? alminneligInntektForFastsetting : 0]
  };
}

