import React from 'react';
import formatNumber from '../../../felles/formatNumber';
import { i18n } from '../../../i18n/tekster';
import { hentPostFraListe } from '../../../felles/HentPostFraListe';

export const SjofolkUndertekst = (props) => {

  const { post, skattegrunnlagsobjekt } = props;

  if (post.get('tekniskNavn') === 'loennsinntektSomGrunnlagForSaerskiltFradragForSjoefolk' ||
      post.get('tekniskNavn') === 'saerskiltFradragForSjoefolk') {
    const skvisPost = hentPostFraListe('saerskiltFradragForSjoefolk', skattegrunnlagsobjekt);
    const skvisBeloep = skvisPost && parseInt(skvisPost.get('beloep'), 10);
    const postBeloep = parseInt(post.get('beloep'), 10);
    if (postBeloep && skvisBeloep) {
      return (
        <div className="postbeholder_undertekst">
          {i18n('ledetekst.fradrag.blir').replace("{0}", formatNumber(skvisBeloep))}
        </div>
      );
    }

  }

  return null;

};

export default SjofolkUndertekst;