import shortid from 'shortid';
import byggNyPost from './byggNyPost';
import { i18n } from '../i18n/tekster';

export default function byggStandardposter(allePostdata) {
  return [
    byggNyPost(allePostdata.get('loennsinntektNaturalytelseMv'), shortid.generate()),
    byggNyPost(allePostdata.get('opptjenteRenter'), shortid.generate()),
    byggNyPost(allePostdata.get('paaloepteRenter'), shortid.generate()),
    byggNyPost(allePostdata.get('formuesverdiForPrimaerbolig'), shortid.generate()),
    byggNyPost(allePostdata.get('innskudd'), shortid.generate()),
    byggNyPost(allePostdata.get('gjeld'), shortid.generate()),
  ];
}

export function byggMaaneder() {
  const options = [];
  options.push({ key: '', text: i18n('ledetekst.velgISelectboks') });
  for (let i = 1; i < 13; i++) {
    options.push({ key: i, text: i });
  }
  return options;
}
