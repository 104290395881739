import React, { useRef } from 'react';
import Pensjon from './Pensjon/Pensjon';
import Minstefradrag from './Minstefradrag/Minstefradrag';
import { getInntektsaar, reisefradragAntallKm } from '../../felles/utils';
import IpsUndertekst from './Undertekster/IpsUndertekst';
import FagforeningUndertekst from './Undertekster/FagforeningUndertekst';
import PersoninntektUndertekst from './Undertekster/PersoninntektUndertekst';
import SjofolkUndertekst from './Undertekster/SjofolkUndertekst';
import { i18n } from '../../i18n/tekster';
import { alderspensjonXmlType, ensligForsoergerXmlType, skattegrunnlagsobjektXmlType } from '../../felles/postXmlTyper';
import OrganisasjonsgaverUndertekst from './Undertekster/OrganisasjonsgaverUndertekst';
import Saerfradrag from './Undertekster/Saerfradrag';
import IpsUtenlandskUndertekst from './Undertekster/IpsUtenlandskUndertekst';
import JordbruksfradragUndertekst from './Undertekster/JordbruksfradragUndertekst';
import { hentPostFraListe } from '../../felles/HentPostFraListe';
import EnsligForsoerger from './EnsligForsoerger/EnsligForsoerger';
import OppjustertTilleggUndertekst from './Undertekster/OppjustertTilleggUndertekst';
import FradragForFiskereOgFangstfolkUndertekst from './Undertekster/FradragForFiskereOgFangstfolkUndertekst';
import Post from './Post/Post';
import Primaerbolig from './Primaerbolig/Primaerbolig';
import Styles from './gruppe.module.css';
import GruppeHeader from './GruppeHeader/GruppeHeader';
import Besoeksreise from './Besoeksreise/Besoeksreise';
import Arbeidsreise from './Arbeidsreise/Arbeidsreise';
import Reisefradrag from './Reisefradrag/Reisefradrag';
import PostvelgerKnapp from './PostvelgerKnapp/PostvelgerKnapp';
import Postvelger from './Postvelger/Postvelger';
import BarnepensjonForBarn from './Barnepensjon/BarnepensjonForBarn';
import EgenBarnepensjon from './Barnepensjon/EgenBarnepensjon';
import { BarnepensjonPostStatus } from './Barnepensjon/BarnepensjonPostStatus.enum';
import MinstefradragBarnepensjon from './Barnepensjon/MinstefradragBarnepensjon';
import { hentReisefradragFraFastsettingsberegning } from '../../felles/HentReisefradragFraFastsettingsberegning';
import {
  hentSamletReiselengdeFraFastsettingsberegning
} from '../../felles/HentSamletReiselengdeFraFastsettingsberegning';
import PostToggle from './Post/PostToggle';
import { utledFeilmelding } from '../../felles/UtledFeilmeldingForSaerfradrag';
import ReisefradragContainer from './Reisefradrag/ReisefradragContainer';
import FradragForIndividuellPensjonssparing from "./Pensjon/FradragForIndividuellPensjonssparing";
import BSU from './BSU/BSU';

export default function Gruppe(props) {

  const {
    tittelKey,
    img,
    viserEktefelle,
    poster,
    onDelete,
    oppdaterPostInnhold,
    valideringer,
    onLeggTilPost,
    onLeggTilPostFraPost,
    kategoriId,
    skattegrunnlagsobjekt,
    postvelgerAapen,
    togglePostvelgerAapen,
    tilgjengeligePoster,
    onChange,
    fastsettingsberegningsresultat,
  } = props;

  const barnepensjonRef = useRef(null);

  const inntektsaar = getInntektsaar();
  const antallKmReiseFradrag = inntektsaar >= 2022
    ? hentSamletReiselengdeFraFastsettingsberegning(fastsettingsberegningsresultat)
    : reisefradragAntallKm([poster]);

  const reisefradrag = inntektsaar >= 2022
    ? hentReisefradragFraFastsettingsberegning(fastsettingsberegningsresultat)
    : hentPostFraListe('reisefradrag', skattegrunnlagsobjekt);

  let vistArbeidsreiser = false;
  let vistBesoeksreiser = false;
  let vistBarnepensjon = false;
  let vistReisefradrag = false;

  const DEFAULT_BARNEPENSJON_LISTE_ANTALL = 2;

  const barnepensjonIkkeFyltInnAntall = inntektsaar >= 2022 && poster
      .filter(post => post.get('tekniskNavn') === 'barnepensjonFraFolketrygden')
      .filter(post => post.get('barnepensjonPostStatus') === BarnepensjonPostStatus.IKKE_FYLT_INN)
      .size;

  const egenBarnepensjonAntall = inntektsaar >= 2022 && poster
      .filter(post => post.get('tekniskNavn') === 'barnepensjonFraFolketrygden')
      .filter(post => post.get('barnepensjonPostStatus') === BarnepensjonPostStatus.EGEN_BARNEPENSJON)
      .size;

  const posterForBarnepensjonUtenEgenBarnepensjon = inntektsaar >= 2022 && poster
      .filter(post => post.get('tekniskNavn') === 'barnepensjonFraFolketrygden')
      .filter(post => post.get('barnepensjonPostStatus') !== BarnepensjonPostStatus.EGEN_BARNEPENSJON);

  return (
    <React.Fragment>
      <GruppeHeader
        tittelKey={tittelKey}
        img={img}
        viserEktefelle={viserEktefelle}
      />
      <div className={Styles.innhold}>

        {poster.map(post => {
          const tekniskNavn = post.get('tekniskNavn');
          if (tekniskNavn === 'formuesverdiForPrimaerbolig' && inntektsaar >= 2022) {
            return <Primaerbolig
              key={tekniskNavn}
              post={post}
              onDelete={onDelete}
              oppdaterPostInnhold={oppdaterPostInnhold}
              valideringer={valideringer}
            />
          }
          if (tekniskNavn === 'arbeidsreise' && vistArbeidsreiser === false) {
            vistArbeidsreiser = true;
            return <Arbeidsreise
              key={tekniskNavn}
              post={post}
              poster={poster}
              onDelete={onDelete}
              oppdaterPostInnhold={oppdaterPostInnhold}
              onLeggTilPost={onLeggTilPost}
              kategoriId={kategoriId}
            />
          }
          if (tekniskNavn === 'besoeksreise' && vistBesoeksreiser === false) {
            vistBesoeksreiser = true;
            return <Besoeksreise
              key={tekniskNavn}
              post={post}
              poster={poster}
              onDelete={onDelete}
              oppdaterPostInnhold={oppdaterPostInnhold}
              onLeggTilPost={onLeggTilPost}
              kategoriId={kategoriId}
            />
          }

          if (tekniskNavn === 'reisefradrag' && vistReisefradrag === false && inntektsaar >= 2022) {
            vistReisefradrag = true;
            return <ReisefradragContainer
              key={tekniskNavn}
              post={post}
              poster={poster}
              onDelete={onDelete}
              oppdaterPostInnhold={oppdaterPostInnhold}
              onLeggTilPostFraPost={onLeggTilPostFraPost}
              kategoriId={kategoriId}
              inntektsaar={inntektsaar}
              reisefradrag={reisefradrag}
              antallKmReiseFradrag={antallKmReiseFradrag}
            />
          }
          const erIndividuellPensjonssparing2022 = tekniskNavn === 'fradragForIndividuellSparingTilPensjon' && inntektsaar >= 2022;
          if (erIndividuellPensjonssparing2022) {
            return <FradragForIndividuellPensjonssparing
              key={tekniskNavn}
              post={post}
              onDelete={onDelete}
              oppdaterPostInnhold={oppdaterPostInnhold}
              fastsettingsberegningsresultat={fastsettingsberegningsresultat}
            />
          }
          if (post.get('type') === ensligForsoergerXmlType) {
            let beloep;
            const saerfradragForEnsligForsoerger = hentPostFraListe('saerfradragForEnsligForsoerger', skattegrunnlagsobjekt);
            if (saerfradragForEnsligForsoerger) {
              beloep = saerfradragForEnsligForsoerger.get('beloep');
            }

            return (
              <EnsligForsoerger
                key={tekniskNavn}
                post={post}
                oppdaterPostInnhold={oppdaterPostInnhold}
                onDelete={onDelete}
                saerfradragForEnsligForsoerger={beloep}
              />
            );
          }
          if (post.get('type') === alderspensjonXmlType) {
            return (
              <Pensjon
                key={post.get('id')}
                post={post}
                oppdaterPostInnhold={oppdaterPostInnhold}
                onDelete={onDelete}
                valideringer={valideringer}
              />
            );
          }
          const erBarnepensjonInntektsaar2022 = tekniskNavn === 'barnepensjonFraFolketrygden' && inntektsaar >= 2022;
          const erSaerfradragForLettereNedsattErvervsevne2022 = "saerfradragForLettereNedsattErvervsevne" === tekniskNavn && inntektsaar >= 2022
          const erReisefradragAar2022 = tekniskNavn === 'reisefradrag' && inntektsaar >= 2022;
          const erBSU2022 = tekniskNavn === 'beloepSpartIBSUIInntektsaar' && inntektsaar >= 2022;

          if (post.get('type') === skattegrunnlagsobjektXmlType &&
            !erBarnepensjonInntektsaar2022 &&
            !erSaerfradragForLettereNedsattErvervsevne2022 &&
            !erReisefradragAar2022 &&
            !erIndividuellPensjonssparing2022 &&
            !erBSU2022) {

            return (
              <div key={skattegrunnlagsobjektXmlType + post.get('id')}>
                <Post
                  key={post.get('id')}
                  post={post}
                  onChange={onChange}
                  onDelete={onDelete}
                />
                <PersoninntektUndertekst post={post}/>
                <Saerfradrag
                  post={post}
                  inntektsaar={inntektsaar}
                  skattegrunnlagsobjekt={skattegrunnlagsobjekt}
                  fastsettingsberegningsresultat={fastsettingsberegningsresultat}
                />
                <FagforeningUndertekst
                  post={post}
                  skattegrunnlagsobjekt={skattegrunnlagsobjekt}
                />
                <SjofolkUndertekst
                  post={post}
                  skattegrunnlagsobjekt={skattegrunnlagsobjekt}
                />
                <OrganisasjonsgaverUndertekst
                  post={post}
                  skattegrunnlagsobjekt={skattegrunnlagsobjekt}
                />
                <JordbruksfradragUndertekst
                  post={post}
                  skattegrunnlagsobjekt={skattegrunnlagsobjekt}
                />
                <OppjustertTilleggUndertekst
                  post={post}
                  skattegrunnlagsobjekt={skattegrunnlagsobjekt}
                />
                <FradragForFiskereOgFangstfolkUndertekst
                  post={post}
                  skattegrunnlagsobjekt={skattegrunnlagsobjekt}
                />
                <IpsUtenlandskUndertekst
                  post={post}
                  poster={poster}
                  skattegrunnlagsobjekt={skattegrunnlagsobjekt}
                />
                <IpsUndertekst
                  post={post}
                  poster={poster}
                  skattegrunnlagsobjekt={skattegrunnlagsobjekt}
                />
              </div>
            );
          }

          if (erSaerfradragForLettereNedsattErvervsevne2022) {
            return (
              <div key={skattegrunnlagsobjektXmlType + post.get('id') + "-toggle"}>
                <PostToggle
                  post={post}
                  onChange={onChange}
                  onDelete={onDelete}
                  label={i18n('ledetekst.valgSaerfradragForLettereNedsattErvervsevne')}
                />
                <Saerfradrag
                  post={post}
                  inntektsaar={inntektsaar}
                  skattegrunnlagsobjekt={skattegrunnlagsobjekt}
                  fastsettingsberegningsresultat={fastsettingsberegningsresultat}
                />
              </div>
            )
          }

          if (erBSU2022) {
            return (
              <div key={skattegrunnlagsobjektXmlType + post.get('id')}>
                <BSU
                  key={post.get('id')}
                  post={post}
                  onChange={onChange}
                  onDelete={onDelete}
                />
              </div>
            )
          }

          return null;
        })
          .filter(x => x !== null)
          .toJS()
        }

        {vistBarnepensjon === false &&
            posterForBarnepensjonUtenEgenBarnepensjon &&
            posterForBarnepensjonUtenEgenBarnepensjon.map(post => {
              if (egenBarnepensjonAntall === 0 && barnepensjonIkkeFyltInnAntall < DEFAULT_BARNEPENSJON_LISTE_ANTALL) {
                onLeggTilPost(kategoriId, 'barnepensjonFraFolketrygden');

                /**
                 * Kallet over (onLeggTilPost) fører til at React-renderer får såpass "hikke" at vi mister fokus på input-feltet.
                 * Denne hacken setter fokus tilbake igjen på korrekt input-felt.
                 */
                window.setTimeout(function hackFix() {
                  if(barnepensjonRef.current) {

                    const input = barnepensjonRef.current.querySelector('input');

                    input && input.focus();
                  }
                }, 40);
              }

              if (vistBarnepensjon === false) {
                vistBarnepensjon = true;

                return (
                    <div key={post.get('id')} ref={barnepensjonRef}>
                      <BarnepensjonForBarn
                          key={post.get('id')}
                          post={post}
                          poster={posterForBarnepensjonUtenEgenBarnepensjon}
                          oppdaterPostInnhold={oppdaterPostInnhold}
                          onLeggTilPost={onLeggTilPost}
                          kategoriId={kategoriId}
                          onDelete={onDelete}
                      />
                    </div>
                )
              }

              return null;
            })
                .filter(post => post !== null)
                .toJS()
        }

        {vistBarnepensjon === true && poster
            .filter(post => post.get('tekniskNavn') === 'barnepensjonFraFolketrygden'
                && inntektsaar >= 2022
                && post.get('barnepensjonPostStatus') === BarnepensjonPostStatus.IKKE_FYLT_INN
                && barnepensjonIkkeFyltInnAntall === DEFAULT_BARNEPENSJON_LISTE_ANTALL)
            .map(post => {

              if (egenBarnepensjonAntall === 0 ) {
                oppdaterPostInnhold(post.set('barnepensjonPostStatus', BarnepensjonPostStatus.EGEN_BARNEPENSJON));
                vistBarnepensjon = false;

                return (
                    <div key={post.get('id')}>
                      <EgenBarnepensjon
                          key={post.get('id')}
                          post={post}
                          oppdaterPostInnhold={oppdaterPostInnhold}
                          onDelete={onDelete}
                      />
                    </div>
                )
              }

              return null;
            })
            .filter(post => post !== null)
            .toJS()
        }

        {vistBarnepensjon === true && poster
            .filter(post => post.get('tekniskNavn') === 'barnepensjonFraFolketrygden'
                && inntektsaar >= 2022
                && post.get('barnepensjonPostStatus') === BarnepensjonPostStatus.EGEN_BARNEPENSJON)
            .map(post => {

              vistBarnepensjon = false;
              return (
                  <div key={post.get('id')}>
                    <EgenBarnepensjon
                        key={post.get('id')}
                        post={post}
                        oppdaterPostInnhold={oppdaterPostInnhold}
                        onDelete={onDelete}
                    />
                  </div>
              )
            })
            .filter(post => post !== null)
            .toJS()
        }

        {props.kategoriId === 'arbeidTrygdPensjon' && props.skattegrunnlagsobjekt && props.skattegrunnlagsobjekt.map(post => {
          if (post.get('tekniskNavn') === 'minstefradragIInntekt') {
            return (
              <Minstefradrag
                key={'sum_minstefradragIInntekt'}
                post={post}
                tekniskNavn={'minstefradragIInntekt'}
                labeltekst={i18n('ledetekst.sumMinstefradrag')}
              />
            );
          }
          return null;
        })}
        {props.kategoriId === 'arbeidTrygdPensjon' && props.skattegrunnlagsobjekt && props.skattegrunnlagsobjekt.map(post => {
          if (post.get('tekniskNavn') === 'minstefradragIEktefelletillegg') {
            return (
              <Minstefradrag
                key={'sum_minstefradragIEktefelletillegg'}
                post={post}
                tekniskNavn={'minstefradragIEktefelletillegg'}
                labeltekst={i18n('ledetekst.sumMinstefradragEktefelletillegg')}
              />
            );
          }
          return null;
        })}

        {props.kategoriId === 'arbeidTrygdPensjon' && inntektsaar < 2022 && (
          <Reisefradrag
            antallKmReiseFradrag={antallKmReiseFradrag}
            reisefradrag={reisefradrag}
            inntektsaar={inntektsaar}
          />
        )}

        {inntektsaar >= 2022 && props.kategoriId === 'familieOgHelse' && props.skattegrunnlagsobjekt &&
            <MinstefradragBarnepensjon
                skattegrunnlagsobjekt={props.skattegrunnlagsobjekt}
            />
        }

        {tilgjengeligePoster && <><PostvelgerKnapp
          postvelgerAapen={postvelgerAapen}
          kategoriId={kategoriId}
          gruppeTittel={tittelKey}
          togglePostvelgerAapen={togglePostvelgerAapen}
        />
        {postvelgerAapen && (
          <Postvelger
            tilgjengeligePoster={tilgjengeligePoster}
            onLeggTilPost={onLeggTilPost}
            postvelgerAapen={postvelgerAapen}
            kategoriId={kategoriId}
            disabledMessage={utledFeilmelding(poster, inntektsaar)}
          />
        )}</>}
      </div>
    </React.Fragment>
  );
}
