import React from 'react';
import { i18n } from '../../../i18n/tekster';
import Styles from './gruppeheader.module.css';

const GruppeHeader = (props) => {

  const { tittelKey, img, viserEktefelle  } = props;

  return (
    <div className={viserEktefelle ? Styles.headerEktefelle : Styles.headerHovedperson}>
      <img
        aria-hidden="true"
        src={img}
        width="40px"
        height="40px"
        alt=""
      />
      <h2 className={Styles.header}>
        {i18n('ledetekst.' + tittelKey)}
      </h2>
    </div>
  )

}

export default GruppeHeader;