export function utledFritidsbaatForFastsetting(poster) {

  const formuesverdiForFritidsbaat = poster.find(post => post.get('tekniskNavn') === 'formuesverdiForFritidsbaatMedSalgsverdiOverSalgsverdigrense');

  if (!formuesverdiForFritidsbaat) {
    return undefined;
  }
  const beloep = formuesverdiForFritidsbaat.get('beloep');

  return !beloep
    ? undefined
    : {"fritidsbaatMedSalgsverdiOverSalgsverdigrense": [{
        "antattSalgsverdi": {
          "beloep": beloep
        }
      }]};
}