import React from 'react';
import { i18n } from '../../../i18n/tekster';
import PostLabel from './../PostLabel/PostLabel';
import PropTypes from 'prop-types';
import formatNumber from '../../../felles/formatNumber';
import { fjernFormateringKroner } from '../../../felles/utils';
import fjernUgyldigeTegn from '../../../validering/ugyldigeTegn';
import Immutable from 'immutable';
import TextField from '@skatteetaten/frontend-components/TextField';

export const BSU = ({ post, onChange, onDelete }) => {

  return (
    <div className="post">
      <PostLabel
        post={post}
        onDelete={onDelete}
        id={post.get('id')}
        ledetekst={i18n(post.get('tekniskNavn') + '.ledetekst')}
        subtekst={i18n('ledetekst.BSUInfo')}
      />
      <TextField
        id={post.get('id')}
        value={formatNumber(post.get('beloep'))}
        maxLength={12}
        onChange={(event, value) => {
          onChange(post.get('id'), fjernFormateringKroner(fjernUgyldigeTegn(value)))
        }}
        autoFocus={post.get('autofokus')}
      />
    </div>
  );

};

BSU.propTypes = {
  post: PropTypes.instanceOf(Immutable.Map),
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default BSU;