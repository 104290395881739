import React from 'react';
import { getValideringsmelding } from '../../../validering/poster/valideringsmeldinger';
import PropTypes from 'prop-types';
import { i18n } from '../../../i18n/tekster';
import TextField from '@skatteetaten/frontend-components/TextField';
import PostLabel from './../PostLabel/PostLabel';
import { fjernFormateringKroner } from '../../../felles/utils';
import fjernUgyldigeTegn from '../../../validering/ugyldigeTegn';
import minVerdi from '../../../validering/minVerdi';
import maxVerdi from '../../../validering/maxVerdi';
import formatNumber from '../../../felles/formatNumber';
import Styles from "./primaerbolig.module.css";

const Primaerbolig = (props) => {

  const { post, onDelete, oppdaterPostInnhold, valideringer } = props;

  const validingsmelding = getValideringsmelding(valideringer, post.get('tekniskNavn'));
  const postBeloep = post.get('beloep');
  const postAndel = post.get('andelAvFormuesverdiPrEiendom');

  return (
    <div className="post">
      <PostLabel
        id={post.get('id')}
        post={post}
        onDelete={onDelete}
        ledetekst={i18n('formuesverdiForPrimaerbolig.ledetekst')}
        subtekst={i18n('ledetekst.ledetekstPrimaerbolig')}
      />
      <TextField
        id={post.get('id')}
        type={'tel'}
        autoFocus={post.get('autofokus')}
        value={postBeloep ? formatNumber(postBeloep) : ''}
        onChange={(event, value) => oppdaterPostInnhold(post.set('beloep', fjernFormateringKroner(fjernUgyldigeTegn(value))))}
      />
      <div className={Styles.pctContainer}>
        <TextField
          label={i18n('ledetekst.andelAvFormuesverdiPrEiendom')}
          type={'tel'}
          value={postAndel ? formatNumber(postAndel) : ''}
          onChange={(evt, value) => {
            const nyValue = maxVerdi(minVerdi(fjernFormateringKroner(fjernUgyldigeTegn(value)), 0), 100) + "";
            oppdaterPostInnhold(post.set('andelAvFormuesverdiPrEiendom', nyValue));
          }}
          errorMessage={validingsmelding}
        />
      </div>
    </div>
  )

}

Primaerbolig.propTypes = {
  post: PropTypes.shape().isRequired,
  onDelete: PropTypes.func.isRequired,
  oppdaterPostInnhold: PropTypes.func.isRequired,
  valideringer: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]).isRequired,
};

export default Primaerbolig