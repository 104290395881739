/**
 * @prettier
 */
import React from 'react';
import PostLabel from '../PostLabel/PostLabel';
import { i18n } from '../../../i18n/tekster';
import { formaterTall } from '../../../felles/numberFormatter';
import { fjernFormateringKroner } from '../../../felles/utils';
import fjernUgyldigeTegn from '../../../validering/ugyldigeTegn';
import TextField from '@skatteetaten/frontend-components/TextField';
import Styles from '../Pensjon/pensjon.module.css';
import {
  GammelNorskIPSUndertekst,
  GammelUtenlanskIPSUndertekst,
  NyNorskIPSUndertekst,
  NyUtenlanskIPSUndertekst,
} from '../Undertekster/IPSUndertekstFra2022';

const FradragForIndividuellPensjonssparing = (props) => {
  const {
    post,
    onDelete,
    oppdaterPostInnhold,
    fastsettingsberegningsresultat,
  } = props;

  let totaltFradrag = fastsettingsberegningsresultat.getIn([
    'individuellPensjonsordning',
  ]);

  if (totaltFradrag) {
    totaltFradrag = totaltFradrag
      .get('fradragForIndividuellSparingTilPensjon')
      .get(0)
      .get('beloep');
  }

  const visIpsUndertekst =
    post.get('tekniskNavn') === 'fradragForIndividuellSparingTilPensjon' &&
    totaltFradrag;

  return (
    <div className="post">
      <PostLabel
        id={post.get('id')}
        post={post}
        onDelete={() => onDelete(post)}
        ledetekst={i18n(post.get('tekniskNavn') + '.ledetekst')}
      />

      <div className={Styles.container}>
        <TextField
          id={post.get('id') + 'gammelIPS'}
          label={i18n('ledetekst.pensjonsinnbetalingTilIPS')}
          maxLength={12}
          value={formaterTall(post.get('beloep'))}
          autoFocus
          onChange={(event, value) =>
            oppdaterPostInnhold(
              post.set(
                'beloep',
                fjernFormateringKroner(fjernUgyldigeTegn(value))
              )
            )
          }
        />
      </div>
      {visIpsUndertekst && (
        <GammelNorskIPSUndertekst post={post} totaltFradrag={totaltFradrag} />
      )}

      <div className={Styles.container}>
        <TextField
          id={post.get('id') + 'nyIPS'}
          label={i18n(
            'ledetekst.pensjonsinnbetalingTilAvtaleOmSkattefavorisertIndividuellSparingTilPensjonTegnetFomNov2017'
          )}
          value={formaterTall(post.get('beloepNyIPS'))}
          maxLength={12}
          onChange={(event, value) =>
            oppdaterPostInnhold(
              post.set(
                'beloepNyIPS',
                fjernFormateringKroner(fjernUgyldigeTegn(value))
              )
            )
          }
        />
      </div>

      {visIpsUndertekst && (
        <NyNorskIPSUndertekst post={post} totaltFradrag={totaltFradrag} />
      )}

      <div className={Styles.container}>
        <TextField
          id={post.get('id') + 'gammelEOES'}
          label={i18n('ledetekst.pensjonsinnbetalingTilIPSIEOES')}
          value={formaterTall(post.get('beloepGammelEOES'))}
          maxLength={12}
          onChange={(event, value) =>
            oppdaterPostInnhold(
              post.set(
                'beloepGammelEOES',
                fjernFormateringKroner(fjernUgyldigeTegn(value))
              )
            )
          }
        />
      </div>

      {visIpsUndertekst && (
        <GammelUtenlanskIPSUndertekst
          post={post}
          totaltFradrag={totaltFradrag}
        />
      )}

      <div className={Styles.container}>
        <TextField
          id={post.get('id') + 'nyEOES'}
          label={i18n(
            'ledetekst.pensjonsinnbetalingTilAvtaleOmSkattefavorisertIndividuellSparingTilPensjonIEOESTegnetFomNov2017'
          )}
          value={formaterTall(post.get('beloepNyEOES'))}
          maxLength={12}
          onChange={(event, value) =>
            oppdaterPostInnhold(
              post.set(
                'beloepNyEOES',
                fjernFormateringKroner(fjernUgyldigeTegn(value))
              )
            )
          }
        />
      </div>

      {visIpsUndertekst && (
        <NyUtenlanskIPSUndertekst post={post} totaltFradrag={totaltFradrag} />
      )}
    </div>
  );
};

export default FradragForIndividuellPensjonssparing;
