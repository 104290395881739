import { getInntektsaar } from '../../felles/utils';
import { postHarTallverdi } from './felles/postHarTallverdi';

export function validerPrimaerbolig(post) {

  const inntektsaar = getInntektsaar();

  if (post === null || post === undefined || inntektsaar < 2022) {
    return null;
  }

  const andel = postHarTallverdi(post, 'andelAvFormuesverdiPrEiendom');
  const beloep = postHarTallverdi(post, 'beloep');

  if (beloep && beloep !== 0 && !andel) {
    return {
      gjelderFelt: post.get('tekniskNavn'),
      kode: 'validering.formuesverdiEiendom.manglerAndelAvFormuesverdiPrEiendom',
      alvorlighet: 'fatal'
    };
  }

  return null;

}