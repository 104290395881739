
export function summeMinstefradragIBarnepensjonTilBarn(skattegrunnlagsobjekt) {
  let sumMinstefradragIBarnepensjonTilBarn = 0;

  skattegrunnlagsobjekt.forEach(post => {
    if (post.get('tekniskNavn') === 'minstefradragIBarnepensjonTilBarn') {
      sumMinstefradragIBarnepensjonTilBarn += post.get('beloep');
    }
  })
  return sumMinstefradragIBarnepensjonTilBarn;
}

