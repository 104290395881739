import React from 'react';
import { i18n } from '../../../i18n/tekster';
import ReiseMellomHjemOgArbeid from '../Reise/ReiseMellomHjemOgArbeid';
import ActionButton from '@skatteetaten/frontend-components/ActionButton';
import Styles from './reisefradrag.module.css';
import ReiseBesoekInnenforEU from '../Reise/ReiseBesoekInnenforEU';
import ReiseBesoekUtenforEU from '../Reise/ReiseBesoekUtenforEU';
import BompengerOgFerjeutgifter from '../Reise/BompengerOgFerjeutgifter';

const ReisefradragDelfradrag = (props) => {

  const {
    post,
    poster,
    onDelete,
    oppdaterPostInnhold,
    onLeggTilPostFraPost,
    kategoriId
  } = props;

  return (
    <div className="post">
      {/* Fradrag for reise mellom hjem og arbeid */}
      {poster.filter(post => post.get('underpost') === 'arbeidsreise').map(reiseMellomHjemOgArbeid => (
        <ReiseMellomHjemOgArbeid
          key={reiseMellomHjemOgArbeid.get('id')}
          post={reiseMellomHjemOgArbeid}
          onChange={oppdaterPostInnhold}
          onDelete={onDelete}
        />
      ))}
      <div className={Styles.btnContainer}>
        <ActionButton
          className={Styles.leggTilReiseKnapp}
          iconSize={ActionButton.NORMAL}
          icon={'AddOutline'}
          title={i18n('ledetekst.legg_til_arbeidsreise')}
          onClick={() => onLeggTilPostFraPost(kategoriId, post.set('underpost', 'arbeidsreise'))}
        >
          <div className={Styles.leggTilReiseKnappLabel}>
            {i18n('ledetekst.legg_til_arbeidsreise')}
          </div>
        </ActionButton>
      </div>

      {/* Fradrag for besøksreiser hjem innenfor EU/EØS */}
      {poster.filter(post => post.get('underpost') === 'besoeksreiseTilHjemInnenforEOeS').map(besoeksreise => (
        <ReiseBesoekInnenforEU
          key={besoeksreise.get('id')}
          post={besoeksreise}
          onChange={oppdaterPostInnhold}
          onDelete={onDelete}
        />
      ))}
      <div className={Styles.btnContainer}>
        <ActionButton
          className={Styles.leggTilReiseKnapp}
          iconSize={ActionButton.NORMAL}
          icon={'AddOutline'}
          title={i18n('ledetekst.besoeksreiseTilHjemInnenforEOeS')}
          onClick={() => onLeggTilPostFraPost(kategoriId, post.set('underpost', 'besoeksreiseTilHjemInnenforEOeS'))}
        >
          <div className={Styles.leggTilReiseKnappLabel}>
            {i18n('ledetekst.leggTilBesoeksreiseInnenforEUEOS')}
          </div>
        </ActionButton>
      </div>

      {/* Fradrag for besøksreiser hjem utenfor EU/EØS */}
      {poster.filter(post => post.get('underpost') === 'besoeksreiseTilHjemUtenforEOeS').map(besoeksreise => (
        <ReiseBesoekUtenforEU
          key={besoeksreise.get('id')}
          post={besoeksreise}
          onChange={oppdaterPostInnhold}
          onDelete={onDelete}
        />
      ))}
      <div className={Styles.btnContainer}>
        <ActionButton
          className={Styles.leggTilReiseKnapp}
          iconSize={ActionButton.NORMAL}
          icon={'AddOutline'}
          title={i18n('ledetekst.besoeksreiseTilHjemUtenforEOeS')}
          onClick={() => onLeggTilPostFraPost(kategoriId, post.set('underpost', 'besoeksreiseTilHjemUtenforEOeS'))}
        >
          <div className={Styles.leggTilReiseKnappLabel}>
            {i18n('ledetekst.leggTilBesoeksreiseUtenforEUEOS')}
          </div>
        </ActionButton>
      </div>

      {/* Bompenger og fergeutgifter */}
      <BompengerOgFerjeutgifter
        post={post}
        onChange={oppdaterPostInnhold}
      />
    </div>
  );
}

export default ReisefradragDelfradrag;