import React from 'react';
import PropTypes from 'prop-types';
import { formaterTall } from '../../../../felles/numberFormatter';
import Styles from './rad.module.css';

const Rad = (props) => {
  const { grunnlag, beloep, label } = props;
  return (
    <tr className={Styles.container}>
      <td className={Styles.rowLabel}>
        {label}
      </td>
      <td className={Styles.rowNumber}>
        {formaterTall(grunnlag)}
      </td>
      <td className={Styles.rowNumber}>
        {formaterTall(beloep)}
      </td>
    </tr>
  );
};

Rad.propTypes = {
  grunnlag: PropTypes.any,
  beloep: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};

export default Rad;