import { List } from 'immutable';

export const skattegrunnlagsobjektXmlType = 'ske.fastsetting.skatt.skattegrunnlag.v2_0.SkattegrunnlagsobjektXml';
export const alderspensjonXmlType = 'ske.fastsetting.skatt.skattegrunnlag.v2_0.AlderspensjonXml';
export const ufoeretrygdXmlType = 'ske.fastsetting.skatt.skattegrunnlag.v2_0.UfoeretrygdXml';
export const reisefradragXmlType = 'ske.fastsetting.skatt.skattegrunnlag.v2_0.ReiseutgiftXml';
export const ensligForsoergerXmlType = 'ske.fastsetting.skatt.skattegrunnlag.v2_0.EnsligForsoergerXml';

export const alderspensjonPoster = List([
  // 2018-typer
  'supplerendeStoenad',
  'avtalefestetPensjonIPrivatSektor',
  'alderspensjonFraFolketrygden',
  'pensjonsinntektIEOESTilsvarendeAlderspensjonEllerAFP',
  'pensjonFraUtlandetSkattepliktigINorge',
  'pensjonFraUtlandetSkattefriINorge',
  'avtalefestetPensjon',
]);

export const reisefradragsposter = List([
  'inntektsfradragReiseutgifterBesoeksreise',
  'inntektsfradragReiseutgifterReiseHjemArbeid',
  // 2018-typer
  'besoeksreise',
  'arbeidsreise',
  'reisefradrag'
]);

export const ensligForsoergerPoster = List([
  'inntektsfradragSaerfradragEnslig',
  // 2018-typer
  'saerfradragForEnsligForsoerger',
]);

export const barnepensjonPoster = List([
  // 2022-typer
  'barnepensjonFraFolketrygden'
]);

export const IndividuellSparingTilPensjonPoster = List([
  // 2022-typer
  'fradragForIndividuellSparingTilPensjon'
]);

export function xmlType(skatteobjektstype) {
  if (alderspensjonPoster.includes(skatteobjektstype)) {
    return alderspensjonXmlType;
  }
  if (reisefradragsposter.includes(skatteobjektstype)) {
    return reisefradragXmlType;
  }
  if (ensligForsoergerPoster.includes(skatteobjektstype)) {
    return ensligForsoergerXmlType;
  }
  return skattegrunnlagsobjektXmlType;
}