import React from 'react';
import { i18n } from '../../../i18n/tekster';
import PropTypes from 'prop-types';
import formatNumber from '../../../felles/formatNumber';
import { hentPostFraListe } from '../../../felles/HentPostFraListe';

const gyldigeTekniskeNavn = [
  "overskuddAvEnkeltpersonforetakInnenReindrift",
  "overskuddAvEnkeltpersonforetakInnenJordbrukGartneriPelsdyrMv",
  "overskuddAvEnkeltpersonforetakInnenSkiferproduksjon",
];

export const JordbruksfradragUndertekst = (props) => {

  const { post, skattegrunnlagsobjekt } = props;

  if (gyldigeTekniskeNavn.includes(post.get('tekniskNavn'))) {
    const skvisPost = hentPostFraListe('samletSaerskiltFradragForJordbrukReindriftOgSkiferproduksjon', skattegrunnlagsobjekt);
    const skvisBeloep = skvisPost && parseInt(skvisPost.get('beloep'), 10);
    const postBeloep = parseInt(post.get('beloep'), 10);
    if (skvisBeloep && postBeloep) {
      return (
        <div className="postbeholder_undertekst">
          { i18n('ledetekst.fradrag.blir').replace("{0}", formatNumber(skvisBeloep)) }
        </div>
      )
    }
  }

  return null;

};

JordbruksfradragUndertekst.propTypes = {
  post: PropTypes.shape().isRequired,
  skattegrunnlagsobjekt: PropTypes.object,
};

JordbruksfradragUndertekst.defaultProps = {
  skattegrunnlagsobjekt: null,
};

export default JordbruksfradragUndertekst;
