import { fromJS } from 'immutable';
import { UtledSkvisVersjon } from '../../felles/UtledSkvisVersjon';
import { utledSkattegrunnlagsobjektversjon } from '../beregning/utledSkattegrunnlagsobjektversjon';

export async function hentAlminneligInntektForFastsetting(urlBeregning, beregningKeyword, argsBeregning, inntektsaar) {
  let alminneligInntektForFastsetting = 0;
  const skattegrunnlagsobjekt = utledSkattegrunnlagsobjektversjon(inntektsaar);

  await fetch(urlBeregning, argsBeregning)
    .then(response => {
      if (response.status !== 200) {
        throw new Error("Uventet statuskode " + response.status);
      }
      return response.json()
    })
    .then(response => fromJS(response))
    .then(response => {

      const alminneligInntektFraBeregning = response.getIn([beregningKeyword, UtledSkvisVersjon(inntektsaar), skattegrunnlagsobjekt])
        .find(post => post.get('tekniskNavn') === 'alminneligInntektFoerSaerfradrag');

      const fradragForPengetilskudd = response.getIn([beregningKeyword, UtledSkvisVersjon(inntektsaar), skattegrunnlagsobjekt])
        .find(post => post.get('tekniskNavn') === 'fradragForPengetilskudd');

      if (fradragForPengetilskudd && alminneligInntektFraBeregning) {
        alminneligInntektForFastsetting = alminneligInntektFraBeregning.get('beloep') !== 0
          ? alminneligInntektFraBeregning.get('beloep') + fradragForPengetilskudd.get('beloep')
          : 0;
      }
    });
  return alminneligInntektForFastsetting;
}