import {
  hentArbeidsreiserListe,
  hentBesoeksreiserInnenforEUListe,
  hentBesoeksreiserUtenforEUListe,
  hentBompengerEllerFergeutgifter
} from './reiserIArbeidsforholdObjekter';

export function utledReiserIArbeidsforhold(poster) {

  const utfylteArbeidsreiser = hentArbeidsreiserListe(poster)
  const utfylteBesoeksreiserInnenforEU = hentBesoeksreiserInnenforEUListe(poster)
  const utfylteBesoeksreiserUtenforEU = hentBesoeksreiserUtenforEUListe(poster)
  const bompengerEllerFergeutgifter = hentBompengerEllerFergeutgifter(poster)

  if (utfylteArbeidsreiser.size === 0 &&
    utfylteBesoeksreiserInnenforEU.size === 0 &&
    utfylteBesoeksreiserUtenforEU.size === 0 &&
    bompengerEllerFergeutgifter === 0) {
    
    return undefined
  }

  function opprettJSONarbeidsreise(arbeidsreise) {
    return {
      "antallDager": arbeidsreise.get('antallDagerMedReiseIArbeidsforhold'),
      "lengdeTurRetur": arbeidsreise.get('lengdeAvReiseIArbeidsforholdTurRetur'),
      "egenandelVedArbeidsreiseDekketAvArbeidsgiver": arbeidsreise.get('egenandelReise'),
      "klassifiseringAvReiseIArbeidsforhold": "arbeidsreise"
    }
  }

  function opprettJSONbesoeksreiseInnenforEU(besoeksreiseInnenforEU) {
    return {
      "antallDager": besoeksreiseInnenforEU.get('antallDagerMedReiseIArbeidsforhold'),
      "lengdeTurRetur": besoeksreiseInnenforEU.get('lengdeAvReiseIArbeidsforholdTurRetur'),
      "egenandelVedBesoeksreiseTilHjemInnenforEOeSDekketAvArbeidsgiver": besoeksreiseInnenforEU.get('egenandelReise'),
      "klassifiseringAvReiseIArbeidsforhold": "besoeksreiseTilHjemInnenforEOeS",
      "utgiftTilFlybilletterForBesoeksreiserTilHjemmet": besoeksreiseInnenforEU.get('reiseutgifterTilFlybilletter'),
      "underskuddVedUtbetaltGodtgjoerelseEllerRefusjon": besoeksreiseInnenforEU.get('underskuddReise')
    }
  }

  function opprettJSONbesoeksreiseUtenforEU(besoeksreiseUtenforEU) {
    return {
      "antallDager": besoeksreiseUtenforEU.get('antallDagerMedReiseIArbeidsforhold'),
      "lengdeTurRetur": besoeksreiseUtenforEU.get('lengdeAvReiseIArbeidsforholdTurRetur'),
      "kostnaderVedBesoeksreiseTilHjemmetUtenforEOeS": besoeksreiseUtenforEU.get('faktiskeKostnaderReise'),
      "klassifiseringAvReiseIArbeidsforhold": "besoeksreiseTilHjemUtenforEOeS"
    };
  }

  function opprettJSONreiserIArbeidsforhold() {
    let reiserIArbeidsforholdJSON = []
    utfylteArbeidsreiser.forEach(arbeidsreise => reiserIArbeidsforholdJSON.push(opprettJSONarbeidsreise(arbeidsreise)))
    utfylteBesoeksreiserInnenforEU.forEach(besoeksreiseInnenforEU => reiserIArbeidsforholdJSON.push(opprettJSONbesoeksreiseInnenforEU(besoeksreiseInnenforEU)))
    utfylteBesoeksreiserUtenforEU.forEach(besoeksreiseUtenforEU => reiserIArbeidsforholdJSON.push(opprettJSONbesoeksreiseUtenforEU(besoeksreiseUtenforEU)))

    return reiserIArbeidsforholdJSON
  }

  return {
    "reiseIArbeidsforhold": opprettJSONreiserIArbeidsforhold(),
    "bompengeutgift": [bompengerEllerFergeutgifter]
  };
}