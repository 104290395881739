
export function utledSaerfradragForLettereNedsattErvervsevne(poster, erEktefelle, queryParams) {

  const tilskudd = poster.find(post => post.get('tekniskNavn') === 'saerfradragForLettereNedsattErvervsevne');
  const tolvdelVedArbeidsoppholdINorge = queryParams && queryParams[erEktefelle ? 'tolvdelVedArbeidsoppholdINorgeEF' : 'tolvdelVedArbeidsoppholdINorge'] || "12"

  if (!tilskudd) {
    return undefined;
  }

  return !tilskudd.get('beloep')
    ? undefined
    : {
      "id": "1",
      "kreverSaerfradrag": true,
      "tolvdelVedArbeidsoppholdINorge": tolvdelVedArbeidsoppholdINorge
    };
}

