export function utledGrunnlagForFiskeOgFangst(poster) {

  const grunnlagForFiskeOgFangst = poster.find(post => post.get('tekniskNavn') === 'saerskiltFradragForFiskereOgFangstfolk');

  if (!grunnlagForFiskeOgFangst) {
    return undefined;
  }
  const beloep = grunnlagForFiskeOgFangst.get('beloep');

  return !beloep
    ? undefined
    : {
      "grunnlagForSaerskiltFradragForFiskereOgFangstfolkKnyttetTilLoennsOgHyremottaker": {
        "inntektKnyttetTilAktivDeltakelseIFiskeSomLoennsOgHyremottaker": [
          beloep
        ]
      }
    }
}

