import postliste2018 from './postliste2018';
import postliste2019 from './postliste2019';
import postliste2020 from './postliste2020';
import postliste2021 from './postliste2021';
import { getInntektsaar } from '../felles/utils';
import { i18n } from '../i18n/tekster';
import { fromJS, Iterable } from 'immutable';
import postliste2022 from './postliste2022';
import {
  utledPostnavnForAntallMaenederMedPensjon,
  utledPostnavnForPensjonsgrad,
} from '../felles/utledAarsavhengigPostnavn';
import postliste2023 from './postliste2023';


export function hentPostdata() {
  switch (getInntektsaar()) {
  case 2018:
    return byggPostdata(postliste2018);
  case 2019:
    return byggPostdata(postliste2019);
  case 2020:
    return byggPostdata(postliste2020);
  case 2021:
    return byggPostdata(postliste2021);
  case 2022:
    return byggPostdata(postliste2022);
  case 2023:
    return byggPostdata(postliste2023);
  default:
    return byggPostdata(postliste2023);
  }
}

function byggPostdata(postliste) {
  const postdata = {};
  Object.keys(postliste).map(key => postliste[key]).forEach(post => {
    post.ledetekst = i18n(post.tekniskNavn + '.ledetekst');
    postdata[post.tekniskNavn] = berikPostMedStandardVerdier(post || {});
  });
  return fromJS(postdata, (key, value) => Iterable.isKeyed(value) ? value.toOrderedMap() : value.toList());
}

// Vi ønsker at noen felt i GUI skal være ferdig utfylt med data
function berikPostMedStandardVerdier(post) {
  switch(post.tekniskNavn) {
    case 'alderspensjonFraFolketrygden':
    case 'avtalefestetPensjon':
    case 'supplerendeStoenad':
    case 'pensjonsinntektIEOESTilsvarendeAlderspensjonEllerAFP':
      return {
        [utledPostnavnForPensjonsgrad()]: '100',
        [utledPostnavnForAntallMaenederMedPensjon()]: 12,
        ...post,
      };

    case 'formuesverdiForPrimaerbolig':
      return {
        'andelAvFormuesverdiPrEiendom': 100,
        ...post,
      }
    default:
      return post;
  }
}
