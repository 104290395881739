import React from 'react';
import { i18n } from '../../../i18n/tekster';

export const PersoninntektUndertekst = ({ post }) => {
  const beloepNaeringspost = post.get('beloep') ? parseInt(post.get('beloep'), 10) : 0;
  const brukIKalkulator = post.get('brukIKalkulator');
  if (brukIKalkulator === 'meldingompersoninntekt' && beloepNaeringspost > 0) {
    return (
      <div className="postbeholder_undertekst">
        { i18n('ledetekst.overskudd.naering.uten.personinntekt') }
      </div>
    );
  }
  return null;
};

export default PersoninntektUndertekst;
