import React from 'react';
import { i18n } from '../../../i18n/tekster';
import PropTypes from 'prop-types';
import { hentPostFraListe } from '../../../felles/HentPostFraListe';
import { formaterTall } from '../../../felles/numberFormatter';

export const FagforeningUndertekst = (props) => {

  const gyldigeTekniskeNavn = ['betaltFagforeningskontingent', 'inntektsfradragForFagforeningskontingent'];

  const { post, skattegrunnlagsobjekt } = props;

  if (gyldigeTekniskeNavn.includes(post.get('tekniskNavn'))) {
    const skvisPost = hentPostFraListe('fradragForFagforeningskontingent', skattegrunnlagsobjekt);
    const skvisBeloep = skvisPost && parseInt(skvisPost.get('beloep'), 10)
    const postBeloep = parseInt(post.get('beloep'), 10)
    if (postBeloep && skvisBeloep) {
      return (
        <div className="postbeholder_undertekst">
          { i18n('ledetekst.fradrag.blir').replace("{0}", formaterTall(skvisBeloep)) }
        </div>
      );
    }
  }

  return null;

};

FagforeningUndertekst.propTypes = {
  post: PropTypes.object.isRequired,
  skattegrunnlagsobjekt: PropTypes.object,
};

FagforeningUndertekst.defaultProps = {
  skattegrunnlagsobjekt: undefined,
};

export default FagforeningUndertekst;
