import React, { useRef, useState } from "react";
import { Callout } from '@skatteetaten/frontend-components/Callout';
import IconButton from "@skatteetaten/frontend-components/IconButton";

export default function Hjelpelenk({postnummer, children}) {
  const [calloutVisible, setCalloutVisible] = useState(false);
  const buttonElement = useRef(null);

    let url;
    const formatertPostnummer = postnummer.replace(/\./g, '/');

    switch (document.documentElement.lang) {
        case 'nn':
            url = 'https://www.skatteetaten.no/nn/person/skatt/skattemelding/finn-post/';
        break;
        case 'en':
            url = 'https://www.skatteetaten.no/en/person/taxes/tax-return/find-item/';
        break;
        default: 
            url = 'https://www.skatteetaten.no/person/skatt/skattemelding/finn-post/';
        break;
    }

    return (
      <div ref={buttonElement}>
        <IconButton
          aria-expanded={calloutVisible}
          icon="HelpOutline"
          onClick={() => setCalloutVisible(!calloutVisible)}
          title={children}
        />
        {calloutVisible && (
          <Callout
            target={buttonElement}
            gapSpace={5}
            directionalHint={Callout.POS_BOTTOM_CENTER}
            color={Callout.HELP}
            onClose={() => setCalloutVisible(false)}
            onDismiss={() => setCalloutVisible(false)}
          >
            <h3>Hjelpeartikkel</h3>
            <p>
              <a href={url + formatertPostnummer} target="_blank" rel="noreferrer">
                {children}
              </a>
            </p>
          </Callout>
        )}
      </div>
    );
}
