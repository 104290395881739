export function hentArbeidsreiserListe(poster) {
  return poster
    .filter(post => post.get('underpost') === 'arbeidsreise')
    .filter(post =>
      post.get('antallDagerMedReiseIArbeidsforhold') ||
      post.get('lengdeAvReiseIArbeidsforholdTurRetur') ||
      post.get('egenandelReise'))
}

export function hentBesoeksreiserInnenforEUListe(poster) {
  return poster
    .filter(post => post.get('underpost') === 'besoeksreiseTilHjemInnenforEOeS')
    .filter(e =>
      e.get('antallDagerMedReiseIArbeidsforhold') ||
      e.get('lengdeAvReiseIArbeidsforholdTurRetur') ||
      e.get('egenandelReise') ||
      e.get('reiseutgifterTilFlybilletter') ||
      e.get('underskuddReise'))
}

export function hentBesoeksreiserUtenforEUListe(poster) {
  return poster
    .filter(post => post.get('underpost') === 'besoeksreiseTilHjemUtenforEOeS')
    .filter(e =>
      e.get('antallDagerMedReiseIArbeidsforhold') ||
      e.get('lengdeAvReiseIArbeidsforholdTurRetur') ||
      e.get('faktiskeKostnaderReise'))
}

export function hentBompengerEllerFergeutgifter(poster) {
  return poster
    .filter(post => post.get('tekniskNavn') === 'reisefradrag')
    ?.get(0)
    ?.get('bompengerEllerFergeutgifter') || 0
}