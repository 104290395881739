import { utledSkattesubjekt } from './utledSkattesubjekt';
import { utledSkattepliktversjon } from './utledSkattepliktversjon';
import { utledSkattegrunnlagversjon } from './utledSkattegrunnlagversjon';
import { nullstillPostbeloep } from './nullstillPostbeloep';
import { utledSkattegrunnlagsobjektversjon } from './utledSkattegrunnlagsobjektversjon';
import { byggNyePoster } from './byggNoedvendigeFelterForBeregning';
import { nyePosterForSjoefolk } from './nyePosterForSjoefolk';

export function utledBody(queryParams, data) {

  const inntektsaar = parseInt(data.get('inntektsaar'), 10);
  if (inntektsaar < 2016 || !inntektsaar) {
    throw new Error("Ugyldig inntektsaar: " + inntektsaar);
  }
  const skatteplikt = utledSkattepliktversjon(inntektsaar);
  const skattegrunnlag = utledSkattegrunnlagversjon(inntektsaar);
  const skattegrunnlagsobjekt = utledSkattegrunnlagsobjektversjon(inntektsaar);

  const skattesubjekt = utledSkattesubjekt(
    inntektsaar,
    queryParams.alder,
    queryParams.tolvdelVedArbeidsoppholdINorge,
    queryParams.kommune
  );

  let oppdatertPoster;

  if (inntektsaar === 2022) {
    oppdatertPoster = nyePosterForSjoefolk(nullstillPostbeloep(data.get('poster')));
  } else if (inntektsaar >= 2023) {
    oppdatertPoster = byggNyePoster(nullstillPostbeloep(data.get('poster')));
  } else {
    oppdatertPoster = nullstillPostbeloep(data.get('poster')).toJS();
  }

  const requestBody = {
    [skattegrunnlag]: { [skattegrunnlagsobjekt]: oppdatertPoster },
    [skatteplikt]: { skattesubjekt: skattesubjekt },
  };

  if (data.get('erGift')) {

    const skattesubjektEktefelle = utledSkattesubjekt(
      inntektsaar,
      queryParams.alderEF,
      queryParams.tolvdelVedArbeidsoppholdINorgeEF,
      queryParams.kommune
    );

    let oppdatertEktefellePoster;

    if (inntektsaar === 2022) {
      oppdatertEktefellePoster = nyePosterForSjoefolk(nullstillPostbeloep(data.get('posterEktefelle')));
    } else if (inntektsaar >= 2023) {
      oppdatertEktefellePoster = byggNyePoster(nullstillPostbeloep(data.get('posterEktefelle')));
    } else {
      oppdatertEktefellePoster = nullstillPostbeloep(data.get('posterEktefelle')).toJS();
    }
    requestBody.ektefelle = {
      [skattegrunnlag]: { [skattegrunnlagsobjekt]: oppdatertEktefellePoster },
      [skatteplikt]: { skattesubjekt: skattesubjektEktefelle },
    };

  }

  return requestBody;

}

