export default function maxVerdi(inputverdi, max, min) {
  const parsetInput = parseFloat(inputverdi);
  if (!isNaN(parsetInput)) {
    if (parsetInput > max) {
      return max;
    }
    if (parsetInput < min) {
      return min;
    }
  }
  return inputverdi;
}