import React from 'react';
import PostLabel from '../PostLabel/PostLabel';
import ReisefradragDelfradrag from './ReisefradragDelfradrag';
import { i18n } from '../../../i18n/tekster';
import Reisefradrag from './Reisefradrag';

const ReisefradragContainer = (props) => {

  const {
    post,
    poster,
    onDelete,
    oppdaterPostInnhold,
    onLeggTilPostFraPost,
    kategoriId,
    inntektsaar,
    reisefradrag,
    antallKmReiseFradrag
  } = props;

  return (
    <div className="post">
      <PostLabel
        id={post.get('id')}
        post={post}
        onDelete={() => onDelete(post)}
        ledetekst={i18n('reisefradrag.ledetekst')}
      />
      <ReisefradragDelfradrag
        post={post}
        poster={poster}
        onDelete={onDelete}
        oppdaterPostInnhold={oppdaterPostInnhold}
        onLeggTilPostFraPost={onLeggTilPostFraPost}
        kategoriId={kategoriId}
      />
      <Reisefradrag
        antallKmReiseFradrag={antallKmReiseFradrag}
        reisefradrag={reisefradrag}
        inntektsaar={inntektsaar}
      />
    </div>
  );
}

export default ReisefradragContainer;