import { gyldigeSpraak, NB_NO } from './spraak';
import { fromJS } from 'immutable';

export function getLocale() {

  const sessionStorageSkattesubjekt = typeof Storage !== 'undefined'
    ? sessionStorage.getItem('skattesubjekt')
    : null;

  const locale = sessionStorageSkattesubjekt && fromJS(JSON.parse(sessionStorageSkattesubjekt)).get('locale');

  if (gyldigeSpraak.includes(locale)) {
    return locale
  }

  return NB_NO;

}