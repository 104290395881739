import utledResultatversjon from './utledResultatversjon';

export function hentSaerfradragEnsligForsoerger(inntektsaar, response) {
    if (!response) {
        return undefined;
    }
    return response.getIn([
        utledResultatversjon(inntektsaar),
        'saerfradragForEnsligForsoerger',
        'saerfradragForEnsligForsoerger'
    ]);
}