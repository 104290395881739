import React from 'react';
import { formaterTall } from '../../../felles/numberFormatter';
import { i18n } from '../../../i18n/tekster';

const gyldigeTekniskeNavn = [
  'pensjonsinnbetalingTilIPS',
  'pensjonsinnbetalingTilAvtaleOmSkattefavorisertIndividuellSparingTilPensjonTegnetFomNov2017'
]

const parseTall = (tall) => tall && parseInt(tall, 10) || undefined;

// Gjelder til og med 2021
// Basert paa reglene gitt her https://jira.sits.no/browse/SPAP-11914
const IpsUndertekst = (props) => {

  const { post, poster, skattegrunnlagsobjekt } = props;

  const postTekniskNavn = post.get('tekniskNavn');

  if (!gyldigeTekniskeNavn.includes(postTekniskNavn) || !skattegrunnlagsobjekt) {
    return null;
  }

  const gammelPost = poster.find(p => p.get('tekniskNavn') === 'pensjonsinnbetalingTilIPS')
  const nyPost = poster.find(p => p.get('tekniskNavn') === 'pensjonsinnbetalingTilAvtaleOmSkattefavorisertIndividuellSparingTilPensjonTegnetFomNov2017')
  const fradragPost = skattegrunnlagsobjekt.find(p => p.get('tekniskNavn') === 'fradragForIndividuellSparingTilPensjon');

  const gammeltBeloep = gammelPost && parseTall(gammelPost.get('beloep'))
  const nyttBeloep = nyPost && parseTall(nyPost.get('beloep'))
  const fradragBeloep = fradragPost && parseTall(fradragPost.get('beloep'));

  const totalOppgittBeloep = (gammeltBeloep || 0) + (nyttBeloep || 0);

  if (!fradragBeloep) {
    return null;
  }

  if (gammeltBeloep && nyttBeloep && totalOppgittBeloep === fradragBeloep && postTekniskNavn === 'pensjonsinnbetalingTilIPS') {
    return (
      <div className={'postbeholder_undertekst'}>
        { i18n('ledetekst.fradrag.blir').replace("{0}", formaterTall(gammeltBeloep)) }
      </div>
    )
  }

  if (gammeltBeloep && nyttBeloep && totalOppgittBeloep === fradragBeloep && postTekniskNavn === 'pensjonsinnbetalingTilAvtaleOmSkattefavorisertIndividuellSparingTilPensjonTegnetFomNov2017') {
    return (
      <div className={'postbeholder_undertekst'}>
        { i18n('ledetekst.fradrag.blir').replace("{0}", formaterTall(nyttBeloep)) }
      </div>
    )
  }

  if (gammeltBeloep && nyttBeloep && totalOppgittBeloep > fradragBeloep && postTekniskNavn === 'pensjonsinnbetalingTilAvtaleOmSkattefavorisertIndividuellSparingTilPensjonTegnetFomNov2017') {
    return (
      <div className={'postbeholder_undertekst'}>
        { i18n('ledetekst.totaltFradrag').replace("{0}", formaterTall(fradragBeloep)) }
      </div>
    )
  }

  if (gammeltBeloep && !nyttBeloep && postTekniskNavn === 'pensjonsinnbetalingTilIPS') {
    return (
      <div className={'postbeholder_undertekst'}>
        { i18n('ledetekst.fradrag.blir').replace("{0}", formaterTall(fradragBeloep)) }
      </div>
    )
  }

  if (!gammeltBeloep && nyttBeloep && postTekniskNavn === 'pensjonsinnbetalingTilAvtaleOmSkattefavorisertIndividuellSparingTilPensjonTegnetFomNov2017') {
    return (
      <div className={'postbeholder_undertekst'}>
        { i18n('ledetekst.fradrag.blir').replace("{0}", formaterTall(fradragBeloep)) }
      </div>
    )
  }

  return null;

};

export default IpsUndertekst;
