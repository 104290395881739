import React from 'react';
import TextField from '@skatteetaten/frontend-components/TextField';
import { i18n } from '../../../i18n/tekster';
import minVerdi from '../../../validering/minVerdi';
import fjernUgyldigeTegn from '../../../validering/ugyldigeTegn';
import PostLabel from '../PostLabel/PostLabel';
import Styles from './reise.module.css';

const ReiseBesoekUtenforEU = (props) => {

  const { post, onChange } = props;

  return (
    <>
      <div className={Styles.reiseTopText} style={{ marginTop: "24px" }}>
        <PostLabel
          id={post.get('id')}
          post={post}
          ledetekst={i18n('ledetekst.bompengeEllerFergeutgifterUnderArbeidsreise')}/>
      </div>
      <div>
        <TextField
          type={'tel'}
          id={post.get('id')}
          value={post.get('bompengerEllerFergeutgifter') || ''}
          onChange={(evt, value) => {
            const nyValue = minVerdi(fjernUgyldigeTegn(value), 0) + "";
            onChange(post.set('bompengerEllerFergeutgifter', nyValue));
          }}/>
      </div>
    </>
  );
}

export default ReiseBesoekUtenforEU;