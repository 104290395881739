import React, { useRef } from 'react';
import IconButton from '@skatteetaten/frontend-components/IconButton';
import TextField from '@skatteetaten/frontend-components/TextField';
import { i18n } from '../../../i18n/tekster';
import fjernUgyldigeTegn from '../../../validering/ugyldigeTegn';
import Styles from './barnepensjon.module.css';
import { fjernFormateringKroner } from '../../../felles/utils';
import formatNumber from '../../../felles/formatNumber';
import { BarnepensjonPostStatus } from './BarnepensjonPostStatus.enum';
import giForrigeElementFokus from '../../../felles/giForrigeElementFokus';

const Barnepensjon = (props) => {

  const ref = useRef(null);

  const { post, onChange, onDelete, barnNr, antallBarn } = props;

  const fiktivIdentifikator = Math.random().toString(36).substring(2);


  function slett() {
    giForrigeElementFokus(ref.current);
    onDelete({ id: post.get('id') });
  }

  return (

    <div className={Styles.container} ref={ref}>
        <div className={Styles.textFieldContainer}>
            <TextField
                className={Styles.antall}
                label={i18n('ledetekst.barnepensjon.tilBarn').replace('{barnNr}', barnNr)}
                value={post.get('beloep') ? formatNumber(post.get('beloep')) : ''}
                maxLength={12}
                onChange={(evt, value) => {
                    onChange(post
                        .set('beloep', fjernFormateringKroner(fjernUgyldigeTegn(value)))
                        .set('barnepensjonPostStatus', BarnepensjonPostStatus.BARNEPENSJON_TIL_BARN)
                        .set('barnetsPersonidentifikator', fiktivIdentifikator));
                }}
                autoFocus={post.get('autofokus')}
            />

        </div>

        <div className={Styles.btnContainer}>
            <IconButton
              className={Styles.btnSlett}
              title={i18n('ledetekst.barnepensjon.slett.knapp')}
              icon={'delete'}
              disabled={antallBarn === 1}
              circle={false}
              onClick={slett}
            />
        </div>
    </div>
  );
}

export default Barnepensjon;