export default function fjernUgyldigeTegn(inputverdi) {
  return inputverdi.replace(/[,.a-zA-Z\\\-<>|§'+!"#¤%&/()=?*\[\]{}$´@æÆøØåÅ;:_£€Ⓒ℗®©]/g, ''); // eslint-disable-line no-useless-escape
}

export function erPensjonsgradGyldig(inputverdi) {
  const regexp = /^(\d+\d?)([,.]\d{0,2})?$/;
  return regexp.test(inputverdi) && inputverdi <= 100.00;
}

export function ertstatteKomma(inputverdi) {
  return inputverdi.replace(/,/g, '.');
}