import utledGrunnlagversjon from './utledGrunnlagversjon';
import { utledSaerfradragEnsligForsoerger } from './utledSaerfradragEnsligForsoerger';
import { utledPrimaerboligForFastsetting } from './utledPrimaerboligForFastsetting';
import queryString from 'query-string';
import { utledReiserIArbeidsforhold } from './reiserIArbeidsforhold/utledReiserIArbeidsforhold';
import { utledFritidsbaatForFastsetting } from './utledFritidsbaatForFastsetting';
import { utledTilskuddTilVitenskapeligForskning } from './utledTilskuddTilVitenskapeligForskning';
import { utledSaerfradragForLettereNedsattErvervsevne } from './utledSaerfradragForLettereNedsattErvervsevne';
import { utledSaerfradragForStoreSykdomskostnader } from "./utledSaerfradragForStoreSykdomskostnader";
import { utledGaverTilFrivilligeOrganisasjoner } from './utledGaverTilFrivilligeOrganisasjoner';
import { utledFagforeningskontigent } from './utledFagforeningskontigent';
import { utledLoennForSjoefolk } from './utledLoennForSjoefolk';
import { utledIndividuellPensjonssparing } from './utledIndividuellPensjonsordning';
import { utledGrunnlagForFiskeOgFangst } from './utledGrunnlagForFiskeOgFangst';

export function utledArgumenterForFastsetting(poster, inntektsaar, erEktefelle, alminneligInntektForFastsetting) {

  const fastEiendomSomFormuesobjekt = utledPrimaerboligForFastsetting(poster);
  const saerfradragForEnsligForsoerger = utledSaerfradragEnsligForsoerger(poster, erEktefelle, queryString.parse(window.location.search));
  const reiserIArbeidsforhold = utledReiserIArbeidsforhold(poster);
  const fritidsbaaterMedSalgsverdiOverSalgsverdigrense = utledFritidsbaatForFastsetting(poster);
  const tilskuddTilVitenskapeligForskning = utledTilskuddTilVitenskapeligForskning(poster, alminneligInntektForFastsetting);
  const saerfradragForLettereNedsattErvervsevne = utledSaerfradragForLettereNedsattErvervsevne(poster, erEktefelle, queryString.parse(window.location.search))
  const saerfradragForStoreSykdomskostnader = utledSaerfradragForStoreSykdomskostnader(poster, erEktefelle, queryString.parse(window.location.search))
  const gaverTilFrivilligeOrganisasjoner = utledGaverTilFrivilligeOrganisasjoner(poster)
  const fagforeningskontingent = utledFagforeningskontigent(poster, erEktefelle, queryString.parse(window.location.search))
  const saerskiltFradragForSjoefolk = utledLoennForSjoefolk(poster, queryString.parse(window.location.search), erEktefelle)
  const individuellPensjonsordning = utledIndividuellPensjonssparing(poster, erEktefelle, queryString.parse(window.location.search), inntektsaar)
  const oevrigeFradragFraVirksomhetInnenPrimaernaering = utledGrunnlagForFiskeOgFangst(poster);

  return JSON.stringify({
    [utledGrunnlagversjon(inntektsaar)]: {
      fastEiendomSomFormuesobjekt,
      saerfradragForEnsligForsoerger,
      reiserIArbeidsforhold,
      fritidsbaaterMedSalgsverdiOverSalgsverdigrense,
      tilskuddTilVitenskapeligForskning,
      saerfradragForLettereNedsattErvervsevne,
      saerfradragForStoreSykdomskostnader,
      gaverTilFrivilligeOrganisasjoner,
      fagforeningskontingent,
      saerskiltFradragForSjoefolk,
      individuellPensjonsordning,
      oevrigeFradragFraVirksomhetInnenPrimaernaering
    }
  });

}

