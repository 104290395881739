export function utledSkattegrunnlagversjon(inntektsaar) {
  if (inntektsaar === 2018) {
    return 'skattegrunnlagV5';
  }
  if (inntektsaar === 2019) {
    return 'skattegrunnlagV5';
  }
  if (inntektsaar === 2020) {
    return 'skattegrunnlagV6';
  }
  if (inntektsaar === 2021) {
    return 'skatteberegningsgrunnlagV6';
  }
  if (inntektsaar === 2022) {
    return 'skatteberegningsgrunnlagV7';
  }
  if (inntektsaar === 2023) {
    return 'skatteberegningsgrunnlag';
  }
  return 'skatteberegningsgrunnlag';
}
