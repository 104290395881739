import React from 'react';
import IconButton from '@skatteetaten/frontend-components/IconButton';
import TextField from '@skatteetaten/frontend-components/TextField';
import { i18n } from '../../../i18n/tekster';
import minVerdi from '../../../validering/minVerdi';
import fjernUgyldigeTegn from '../../../validering/ugyldigeTegn';
import Styles from './reise.module.css';

const Reise = (props) => {

  const { post, onChange, onDelete } = props;

  return (
    <div className={Styles.container}>
      <div className={Styles.textFieldContainer}>
        <TextField
          className={Styles.antall}
          type={'tel'}
          label={i18n('ledetekst.antallReiser')}
          value={post.get('antallDagerMedReiseIArbeidsforhold') || ''}
          autoFocus
          onChange={(evt, value) => {
            const nyValue = minVerdi(fjernUgyldigeTegn(value), 0) + "";
            onChange(post.set('antallDagerMedReiseIArbeidsforhold', nyValue));
          }}
        />
        <TextField
          className={Styles.lengde}
          type={'tel'}
          label={'Km'}
          value={post.get('lengdeAvReiseIArbeidsforholdTurRetur') || ''}
          onChange={(evt, value) => {
            const nyValue = minVerdi(fjernUgyldigeTegn(value), 0) + "";
            onChange(post.set('lengdeAvReiseIArbeidsforholdTurRetur', nyValue));
          }}
        />
      </div>
      <div className={Styles.btnContainer}>
        <IconButton
          className={Styles.btnSlett}
          title={i18n('ledetekst.slett').toLowerCase() + ' ' + i18n(post.get('tekniskNavn') + '.ledetekst').toLowerCase()}
          icon={'delete'}
          onClick={() => onDelete({ id: post.get('id') })}
        />
      </div>
    </div>
  );
}

export default Reise;