const utledBeregnetSkattVersjon = (inntektsaar)  => {
  if (inntektsaar === 2018) {
    return 'beregnetSkattV2';
  }
  if (inntektsaar === 2019) {
    return 'beregnetSkattV2';
  }
  if (inntektsaar === 2020) {
    return 'beregnetSkattV3';
  }
  if (inntektsaar === 2021) {
    return 'beregnetSkattV3';
  }
  if (inntektsaar === 2022) {
    return 'beregnetSkattV4';
  }
  if (inntektsaar === 2023) {
    return 'beregnetSkatt';
  }
  return 'beregnetSkatt';
}

export default utledBeregnetSkattVersjon;