import queryString from 'query-string';
import formatNumber from './formatNumber';
import godkjentOrganisasjonsnummer from '../postlister/godkjentOrganisasjonsnummer';

export function getQueryParameterByName(nam, location = window.location) {
  const name = nam.replace(/[[]/, '[').replace(/[\]]/, ']');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function fjernFormateringKroner(kroner) {
  return `${kroner}`.replace(/ /g, '');
}

export function getInntektsaar() {
  const pAar = getQueryParameterByName('aar');
  if (pAar && pAar !== '') {
    return parseInt(pAar, 10);
  }
  return new Date().getFullYear() - 1;
}

export function reisefradragAntallKm(inputObjekter) {
  let totaltAntallKm = 0;
  inputObjekter.forEach(elm => {
    elm.forEach(elm2 => {
      if (elm2.getIn(['lengdeAvReiseIArbeidsforholdTurRetur']) != null) {
        const antallKm = parseInt(elm2.get('lengdeAvReiseIArbeidsforholdTurRetur'), 10);
        const antallDager = parseInt(elm2.get('antallDagerMedReiseIArbeidsforhold'), 10);
        totaltAntallKm += antallKm * antallDager;
      }
    });
  });
  return `${formatNumber(totaltAntallKm)} km`;
}

export function hentGodkjentInstituttOrganisasjonsnummer() {
  return godkjentOrganisasjonsnummer;
}

/**
 * UU: Når vi endrer hvilken side vi er må, må skjermleser vite om dette.
 * Vi sier i fra ved å flytte fokus til main (som får skjermleser til å
 * lese opp aria-label på main-taggen).
 */
export function giMainFokus() {
  window.setTimeout(function() {
    document.querySelector('main').focus({
      preventScroll: true
    });
  }, 40);
}
/**
 * Sjekk etter spesifikke query-parametere for å avgjøre om vi skal vise forsiden, så
 * lenker fra sosiale medier (som ofte legger på query-parametre i URLer) ikke
 * hopper over forsiden.
 */
export function viserForsiden() {
  const queryParams = queryString.parse(window.location.search);
  // Hvis vi ikke vet år, alder eller sivilstatus, må bruker velge dette
  return !('aar' in queryParams && 'sivilstand' in queryParams && 'alder' in queryParams);
}
