export function utledSaerfradragForStoreSykdomskostnader(poster, erEktefelle, queryParams) {

  const tilskudd = poster.find(post => post.get('tekniskNavn') === 'saerfradragForStoreSykdomskostnader');
  const tolvdelVedArbeidsoppholdINorge = queryParams && queryParams[erEktefelle ? 'tolvdelVedArbeidsoppholdINorgeEF' : 'tolvdelVedArbeidsoppholdINorge'] || "12"

  if (!tilskudd) {
    return undefined;
  }
  const beloep = tilskudd.get('beloep');

  return !beloep
    ? undefined
    : {
      "id": "1",
      "rettTilAaKreveSaerfradrag": true,
      "tolvdelVedArbeidsoppholdINorge": tolvdelVedArbeidsoppholdINorge,
      "sykdomskostnad": [
        {
          "beloep": beloep,
          "sykdomskostnadstype": "egenandelOffentligHelsevesen"
        }
      ]
    };
}

