import postliste2018 from '../../postlister/postliste2018';
import postliste2019 from '../../postlister/postliste2019';
import postliste2020 from '../../postlister/postliste2020';
import postliste2021 from '../../postlister/postliste2021';
import { getInntektsaar } from '../../felles/utils';
import { NB_NO } from '../spraak';
import postliste2022 from '../../postlister/postliste2022';
import postliste2023 from '../../postlister/postliste2023';

function oversettInntektsaarTilTekstlistear(inntektsaar) {
  switch (inntektsaar) {
  case 2018:
  case 2019:
  case 2020:
    return 2018;
  case 2021:
    return 2021;
  case 2022:
    return 2022;
  case 2023:
    return 2023;
  default:
    return 2023;
  }
}

function utledPostliste(inntektsaar) {
  switch (inntektsaar) {
  case 2018:
    return postliste2018;
  case 2019:
    return postliste2019;
  case 2020:
    return postliste2020;
  case 2021:
    return postliste2021;
  case 2022:
    return postliste2022;
  case 2023:
    return postliste2023;
  default:
    return postliste2023;
  }
}

export function byggPostTekster(locale) {

  const postTekster = {};

  const inntektsaar = getInntektsaar();
  const tekstlisteaar = oversettInntektsaarTilTekstlistear(inntektsaar);
  const postliste = utledPostliste(inntektsaar);

  const ledetekstliste = JSON.parse(JSON.stringify(
    require('./' + tekstlisteaar + '/ledetekst_' + (locale || NB_NO) + '.json')
  ));

  Object.keys(postliste).map(key => postliste[key]).forEach(post => {
    postTekster[post.tekniskNavn + '.ledetekst'] = inntektsaar >= 2021
      ? ledetekstliste['ledetekst.' + post.tekniskNavn]
      : (post.postnummer || '') + ' ' + ledetekstliste['ledetekst.' + post.tekniskNavn];
  });

  return postTekster;

}


