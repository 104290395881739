import React from 'react';
import { i18n } from '../../../i18n/tekster';
import PropTypes from 'prop-types';
import { formaterTall } from '../../../felles/numberFormatter';

const gyldigeTekniskeNavn = [
  "pensjonsinnbetalingTilAvtaleOmSkattefavorisertIndividuellSparingTilPensjonIEOESTegnetFomNov2017",
  "pensjonsinnbetalingTilIPSIEOES",
];

const parseTall = (tall) => tall && parseInt(tall, 10) || undefined;

// Basert paa reglene gitt her https://jira.sits.no/browse/SPAP-11914
export const IpsUtenlandskUndertekst = (props) => {

  const { post, poster, skattegrunnlagsobjekt } = props;

  const postTekniskNavn = post.get('tekniskNavn');

  if (!gyldigeTekniskeNavn.includes(postTekniskNavn) || !skattegrunnlagsobjekt) {
    return null;
  }

  const gammelNorskPost = poster.find(p => p.get('tekniskNavn') === 'pensjonsinnbetalingTilIPS');
  const nyNorskPost = poster.find(p => p.get('tekniskNavn') === 'pensjonsinnbetalingTilAvtaleOmSkattefavorisertIndividuellSparingTilPensjonTegnetFomNov2017');

  const gammeltNorskBeloep = gammelNorskPost && parseTall(gammelNorskPost.get('beloep'))
  const nyttNorskBeloep = nyNorskPost && parseTall(nyNorskPost.get('beloep'))

  const gammelUtenlandskPost = poster.find(p => p.get('tekniskNavn') === 'pensjonsinnbetalingTilIPSIEOES')
  const nyUtenlandskPost = poster.find(p => p.get('tekniskNavn') === 'pensjonsinnbetalingTilAvtaleOmSkattefavorisertIndividuellSparingTilPensjonIEOESTegnetFomNov2017')
  const fradragPost = skattegrunnlagsobjekt.find(p => p.get('tekniskNavn') === 'fradragForIndividuellSparingTilPensjon');

  const gammeltUtenlandskBeloep = gammelUtenlandskPost && parseTall(gammelUtenlandskPost.get('beloep'))
  const nyttUtenlandskBeloep = nyUtenlandskPost && parseTall(nyUtenlandskPost.get('beloep'))
  const fradragBeloep = fradragPost && parseTall(fradragPost.get('beloep'));

  const totalOppgittUtenlandskBeloep = (gammeltUtenlandskBeloep || 0) + (nyttUtenlandskBeloep || 0);

  if (!fradragBeloep) {
    return null;
  }

  if (gammeltUtenlandskBeloep && (nyttNorskBeloep || gammeltNorskBeloep) && postTekniskNavn === 'pensjonsinnbetalingTilIPSIEOES') {
    return (
      <div className="postbeholder_undertekst">
        { i18n('ledetekst.fradrag.blir').replace("{0}", 0) }
      </div>
    )
  }

  if (nyttUtenlandskBeloep && (nyttNorskBeloep || gammeltNorskBeloep) && postTekniskNavn === 'pensjonsinnbetalingTilAvtaleOmSkattefavorisertIndividuellSparingTilPensjonIEOESTegnetFomNov2017') {
    return (
      <div className="postbeholder_undertekst">
        { i18n('ledetekst.fradrag.blir').replace("{0}", 0) }
      </div>
    )
  }

  if (gammeltUtenlandskBeloep && nyttUtenlandskBeloep && totalOppgittUtenlandskBeloep === fradragBeloep && postTekniskNavn === 'pensjonsinnbetalingTilIPSIEOES') {
    return (
      <div className={'postbeholder_undertekst'}>
        { i18n('ledetekst.fradrag.blir').replace("{0}", formaterTall(gammeltUtenlandskBeloep)) }
      </div>
    )
  }

  if (gammeltUtenlandskBeloep && nyttUtenlandskBeloep && totalOppgittUtenlandskBeloep === fradragBeloep && postTekniskNavn === 'pensjonsinnbetalingTilAvtaleOmSkattefavorisertIndividuellSparingTilPensjonIEOESTegnetFomNov2017') {
    return (
      <div className={'postbeholder_undertekst'}>
        { i18n('ledetekst.fradrag.blir').replace("{0}", formaterTall(nyttUtenlandskBeloep)) }
      </div>
    )
  }

  if (gammeltUtenlandskBeloep && nyttUtenlandskBeloep && totalOppgittUtenlandskBeloep > fradragBeloep && postTekniskNavn === 'pensjonsinnbetalingTilAvtaleOmSkattefavorisertIndividuellSparingTilPensjonIEOESTegnetFomNov2017') {
    return (
      <div className={'postbeholder_undertekst'}>
        { i18n('ledetekst.totaltFradrag').replace("{0}", formaterTall(fradragBeloep)) }
      </div>
    )
  }

  if (gammeltUtenlandskBeloep && !nyttUtenlandskBeloep && postTekniskNavn === 'pensjonsinnbetalingTilIPSIEOES') {
    return (
      <div className={'postbeholder_undertekst'}>
        { i18n('ledetekst.fradrag.blir').replace("{0}", formaterTall(fradragBeloep)) }
      </div>
    )
  }

  if (!gammeltUtenlandskBeloep && nyttUtenlandskBeloep && postTekniskNavn === 'pensjonsinnbetalingTilAvtaleOmSkattefavorisertIndividuellSparingTilPensjonIEOESTegnetFomNov2017') {
    return (
      <div className={'postbeholder_undertekst'}>
        { i18n('ledetekst.fradrag.blir').replace("{0}", formaterTall(fradragBeloep)) }
      </div>
    )
  }

  return null;

};

IpsUtenlandskUndertekst.propTypes = {
  post: PropTypes.object.isRequired,
  poster: PropTypes.object.isRequired,
  skattegrunnlagsobjekt: PropTypes.object,
};

IpsUtenlandskUndertekst.defaultProps = {
  skattegrunnlagsobjekt: undefined,
};

export default IpsUtenlandskUndertekst;
