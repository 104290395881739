import { validerPensjonspost } from './valider.pensjon';
import { validerPrimaerbolig } from './valider.primaerbolig';

export function validerPoster(poster) {
  const meldinger = [];

  meldinger.push(
      ...validerPensjonspost(poster.find(post => post.get('tekniskNavn') === 'avtalefestetPensjon')),
      ...validerPensjonspost(poster.find(post => post.get('tekniskNavn') === 'pensjonsinntektIEOESTilsvarendeAlderspensjonEllerAFP')),
      ...validerPensjonspost(poster.find(post => post.get('tekniskNavn') === 'alderspensjonFraFolketrygden')),
      validerPrimaerbolig(poster.find(post => post.get('tekniskNavn') === 'formuesverdiForPrimaerbolig'))
  );

  return meldinger.filter(m => m !== null && m !== undefined && m.length !== 0);
}