import React from 'react';
import { Grid } from '@skatteetaten/frontend-components/Grid';
import FooterContent from '@skatteetaten/frontend-components/FooterContent';
import { LinkGroup } from '@skatteetaten/frontend-components/LinkGroup';

import Styles from './footer.module.css';
import { i18n } from '../../i18n/tekster';

const Footer = () => {
  const links = [
    {
      text: i18n('ledetekst.kontakt.oss'),
      path: i18n('ledetekst.kontakt.oss.url'),
    },
    {
      text: i18n('ledetekst.sikkerhet.personvern'),
      path: i18n('ledetekst.sikkerhet.personvern.url'),
    },
    {
      text: i18n('ledetekst.tilgjengelighetserklaering'),
      path: i18n('ledetekst.tilgjengelighetserklaering.url'),
    },
  ];
  return (
    <FooterContent>
      <Grid>
        <Grid.Row>
          <Grid.Col lg={1} />
          <Grid.Col lg={2}>
            <FooterContent.Logo />
          </Grid.Col>
          <Grid.Col lg={6} className="footer-linkgroup">
            <LinkGroup links={links} className={Styles.linkGroup} />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </FooterContent>
  );
};

export default Footer;
