const utledGrunnlagversjon = (inntektsaar) => {
  switch (inntektsaar) {
  case 2022:
    return 'grunnlagV4';
  case 2023:
    return 'grunnlag';
  default:
    return 'grunnlag';
  }
}

export default utledGrunnlagversjon;