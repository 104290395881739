import { utledBody } from './utledBody';
import queryString from 'query-string';

export function utledArgumenterBeregning(queryParams, data) {

  return {
    method: 'POST',
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    body: JSON.stringify(utledBody(queryString.parse(window.location.search), data))
  };

}

