import React from "react";
import Minstefradrag from '../Minstefradrag/Minstefradrag';
import { i18n } from '../../../i18n/tekster';
import { summeMinstefradragIBarnepensjonTilBarn } from './summeMinstefradragIBarnepensjonTilBarn';

const MinstefradragBarnepensjon = (props) => {

    const { skattegrunnlagsobjekt } = props;

    const fradragPoster = skattegrunnlagsobjekt.filter(p => p.get('tekniskNavn') === 'minstefradragIBarnepensjonTilBarn');
    const fradrag = summeMinstefradragIBarnepensjonTilBarn(skattegrunnlagsobjekt);

    return (
        <div>
            {fradragPoster.size > 0 && fradrag &&
              <Minstefradrag
                key={'minstefradragIBarnepensjonTilBarn'}
                post={fradragPoster.get(0)}
                tekniskNavn={'minstefradragIBarnepensjonTilBarn'}
                labeltekst={i18n('ledetekst.barnepensjon.sumMinstefradrag')}
                minstefradragIBarnepensjonTilBarn={fradrag}
              />
            }

            {skattegrunnlagsobjekt.map(post => {
                if (post.get('tekniskNavn') === 'minstefradragIBarnepensjon') {
                    return (
                        <Minstefradrag
                            key={'minstefradragIBarnepensjon'}
                            post={post}
                            tekniskNavn={'minstefradragIBarnepensjon'}
                            labeltekst={i18n('ledetekst.egenBarnepensjon.sumMinstefradrag')}
                        />
                    );
                }
                return null;
            })}

            {skattegrunnlagsobjekt.map(post => {
                if (post.get('tekniskNavn') === 'saerskiltInntektsfradragIBarnsInntekt' && post.get('beloep') !== 0) {
                    return (
                        <Minstefradrag
                            key={'saerskiltInntektsfradragIBarnsInntekt'}
                            post={post}
                            tekniskNavn={'saerskiltInntektsfradragIBarnsInntekt'}
                            labeltekst={i18n('ledetekst.egenBarnepensjon.saerskiltInntektsfradrag')}
                        />
                    );
                }
                return null;
            })}

        </div>
    )
}

export default MinstefradragBarnepensjon;
