export const kategori = {
  arbeidTrygdPensjon: {
    id: 'arbeidTrygdPensjon',
    underkategorier: {
      loennsinntekter: { id: 'loennsinntekter' },
      pensjonTrygdInntekt: { id: 'pensjonTrygdInntekt' },
      arbeidOgPensjonFradrag: { id: 'arbeidOgPensjonFradrag' },
    },
  },
  naering: {
    id: 'naering',
    underkategorier: {
      naeringInntekt: { id: 'naeringInntekt' },
      fradrag: { id: 'naeringFradrag' },
      eiendelerINaering: { id: 'eiendelerINaering' },
      naeringFormue: { id: 'naeringFormue' },
      personNaeringInntekt: { id: 'personNaeringInntekt' },
      naeringGjeld: { id: 'naeringGjeld' },
    },
  },
  boligOgEiendeler: {
    id: 'boligOgEiendeler',
    underkategorier: {
      barn: { id: 'barn' },
      boligOgTomt: { id: 'boligOgTomt' },
      utleie: { id: 'utleie' },
      salgAvEiendom: { id: 'salgAvEiendom' },
      eiendeler: { id: 'eiendeler' },
      boligEindelerFormue: { id: 'boligEindelerFormue' },
      boligEindelerFradrag: { id: 'boligEindelerFradrag' },
      boligEindelerInntekt: { id: 'boligEindelerInntekt' },
      boligEindelerGjeld: { id: 'boligEindelerGjeld' },
    },
  },
  bankLaanForsikring: {
    id: 'bankLaanForsikring',
    underkategorier: {
      bankLaanForsikringGjeld: { id: 'bankLaanForsikringGjeld' },
      bankLaanForsikringFradrag: { id: 'bankLaanForsikringFradrag' },
      bankLaanForsikringFormue: { id: 'bankLaanForsikringFormue' },
      bankInntekt: { id: 'bankInntekt' },
      bankinnskudd: { id: 'bankinnskudd' },
    },
  },
  finans: {
    id: 'finans',
    underkategorier: {
      finansAnnetFradrag: { id: 'finansAnnetFradrag' },
      finansAnnetInntekt: { id: 'finansAnnetInntekt' },
      finansAnnetFormue: { id: 'finansAnnetFormue' },
      aksjeFormue: { id: 'aksjeFormue' },
      aksjeInntekt: { id: 'aksjeInntekt' },
      aksjeFradrag: { id: 'aksjeFradrag' },
    },
  },
  familieOgHelse: {
    id: 'familieOgHelse',
    underkategorier: {
      barn: { id: 'barn' },
      underholdsbidrag: { id: 'underholdsbidrag' },
      helse: { id: 'helse' },
      fHFormue: { id: 'fHFormue' },
      fHGjeld: { id: 'fHGjeld' },
      fHInntekt: { id: 'fHInntekt' },
      fHFradrag: { id: 'fHFradrag' },
    },
  },
};
