import { i18n } from '../i18n/tekster';
import { getInntektsaar } from './utils';

export const utledFeilmelding = (poster, inntektsaar) =>
  inntektsaar >= 2022 && poster.find(
    post => post.get('tekniskNavn') === "saerfradragForStoreSykdomskostnader") ? i18n('ledetekst.validering.nedsattErvervsevneTo')
    : poster.find(post => post.get('tekniskNavn') === "saerfradragForLettereNedsattErvervsevne") ? i18n('ledetekst.validering.saerfradragStoreSykdomskostnader')
      : null

export function utledFeilmeldingMedTekniskNavn(tilgjengeligePoster, tekniskNavn) {
  const lettereNedsattErvervsevneErValgtFraFoer = !postErTilgjengelig(tilgjengeligePoster, 'saerfradragForLettereNedsattErvervsevne')
  const storeSykdomskostnaderErValgtFraFoer = !postErTilgjengelig(tilgjengeligePoster, 'saerfradragForStoreSykdomskostnader')
  const harValgtSaerfradrag = saerfradrag.includes(tekniskNavn);

  if(getInntektsaar() >= 2022 && harValgtSaerfradrag) {
    if(storeSykdomskostnaderErValgtFraFoer) {
      return i18n('ledetekst.validering.nedsattErvervsevneTo')
    } else if(lettereNedsattErvervsevneErValgtFraFoer) {
      return i18n('ledetekst.validering.saerfradragStoreSykdomskostnader')
    }
  }
}

const saerfradrag = [
  "saerfradragForLettereNedsattErvervsevne",
  "saerfradragForStoreSykdomskostnader",
];

function postErTilgjengelig(tilgjengeligePoster, tekniskNavn) {
  return tilgjengeligePoster.some(e => e.tekniskNavn === tekniskNavn)
}
