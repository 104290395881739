/**
 * @prettier
 */
import React from 'react';
import { formaterTall } from '../../../felles/numberFormatter';
import { i18n } from '../../../i18n/tekster';
import Styles from '../Pensjon/pensjon.module.css';

export function SubIPSUndertekst({ children }) {
  return <div className={Styles.ips_undertekst}>{children}</div>;
}

const parseTall = (tall) => (tall && parseInt(tall, 10)) || 0;

export const GammelNorskIPSUndertekst = (props) => {
  const { post, totaltFradrag } = props;

  const beloepGammelIPS = parseTall(post.get('beloep'));
  const beloepNyIPS = parseTall(post.get('beloepNyIPS'));

  const beloepIPS = beloepGammelIPS + beloepNyIPS;

  if (beloepGammelIPS && beloepIPS <= totaltFradrag) {
    return (
      <SubIPSUndertekst>
        {i18n('ledetekst.fradrag.blir').replace(
          '{0}',
          formaterTall(beloepGammelIPS)
        )}
      </SubIPSUndertekst>
    );
  }
  return null;
};

export const GammelUtenlanskIPSUndertekst = (props) => {
  const { post, totaltFradrag } = props;

  const beloepGammelIPS = parseTall(post.get('beloep'));
  const beloepNyIPS = parseTall(post.get('beloepNyIPS'));
  const beloepGammelEOES = parseTall(post.get('beloepGammelEOES'));
  const beloepNyEOES = parseTall(post.get('beloepNyEOES'));

  const beloepIPS = beloepGammelIPS + beloepNyIPS;
  const beloepEOES = beloepGammelEOES + beloepNyEOES;

  if (beloepGammelEOES) {
    if (!beloepIPS && beloepEOES <= totaltFradrag) {
      return (
        <SubIPSUndertekst>
          {i18n('ledetekst.fradrag.blir').replace(
            '{0}',
            formaterTall(beloepGammelEOES)
          )}
        </SubIPSUndertekst>
      );
    }
    if (beloepIPS) {
      return (
        <SubIPSUndertekst>
          {i18n('ledetekst.fradrag.blir').replace('{0}', '0')}
        </SubIPSUndertekst>
      );
    }
  }
  return null;
};

export const NyNorskIPSUndertekst = (props) => {
  const { post, totaltFradrag } = props;

  const beloepGammelIPS = parseTall(post.get('beloep'));
  const beloepNyIPS = parseTall(post.get('beloepNyIPS'));
  const beloepIPS = beloepGammelIPS + beloepNyIPS;

  if (beloepNyIPS && beloepIPS <= totaltFradrag) {
    return (
      <SubIPSUndertekst>
        {i18n('ledetekst.fradrag.blir').replace(
          '{0}',
          formaterTall(beloepNyIPS)
        )}
      </SubIPSUndertekst>
    );
  }

  if (beloepIPS > totaltFradrag) {
    return (
      <SubIPSUndertekst>
        {i18n('ledetekst.totaltFradrag').replace(
          '{0}',
          formaterTall(totaltFradrag)
        )}
      </SubIPSUndertekst>
    );
  }
  return null;
};

export const NyUtenlanskIPSUndertekst = (props) => {
  const { post, totaltFradrag } = props;

  const beloepGammelIPS = parseTall(post.get('beloep'));
  const beloepNyIPS = parseTall(post.get('beloepNyIPS'));
  const beloepGammelEOES = parseTall(post.get('beloepGammelEOES'));
  const beloepNyEOES = parseTall(post.get('beloepNyEOES'));

  const beloepIPS = beloepGammelIPS + beloepNyIPS;
  const beloepEOES = beloepGammelEOES + beloepNyEOES;

  if (beloepNyEOES && beloepEOES <= totaltFradrag) {
    if (!beloepIPS) {
      return (
        <SubIPSUndertekst>
          {i18n('ledetekst.fradrag.blir').replace(
            '{0}',
            formaterTall(beloepNyEOES)
          )}
        </SubIPSUndertekst>
      );
    }
    return (
      <SubIPSUndertekst>
        {i18n('ledetekst.fradrag.blir').replace('{0}', '0')}
      </SubIPSUndertekst>
    );
  }

  if (beloepEOES > totaltFradrag) {
    if (!beloepIPS) {
      return (
        <SubIPSUndertekst>
          {i18n('ledetekst.totaltFradrag').replace(
            '{0}',
            formaterTall(totaltFradrag)
          )}
        </SubIPSUndertekst>
      );
    }
    if (beloepIPS && beloepNyEOES) {
      return (
        <SubIPSUndertekst>
          {i18n('ledetekst.fradrag.blir').replace('{0}', '0')}
        </SubIPSUndertekst>
      );
    }
  }
  return null;
};
