export function nyePosterForSjoefolk(poster) {

  const loennTilSjoefolkTekniskeNavn = ['saerskiltFradragForSjoefolk', 'loennsinntekt'];

  const tekniskeNavnSomHindrerBSU = [
    "formuesverdiForPrimaerbolig",
    "formuesverdiForSekundaerbolig",
    "formuesverdiForGaardsbruk"
  ];

  let harFastEiendomSomFormuesobjekt = poster
    .filter(post => post.get('beloep') !== '')
    .find(post => tekniskeNavnSomHindrerBSU.includes(post.get('tekniskNavn')));
  const erPostForBSUGyldig = (post) => {
    return harFastEiendomSomFormuesobjekt
      ? post.get('tekniskNavn') !== 'beloepSpartIBSUIInntektsaar'
      : post;
  }

  let loennPoster = [];
  poster
    .filter(post => erPostForBSUGyldig(post))
    .map(post => {
    if (post.get('tekniskNavn') === 'saerskiltFradragForSjoefolk' && !post.get('tekniskNavnFraFastsetting')) {
      const nyPost = { tekniskNavn: 'loennsinntekt', beloep: post.get('beloep') }
      loennPoster.push(nyPost);
    }
    if (post.get('tekniskNavn') === 'loennsinntekt' && post.get('beloepFraFastsetting') !== undefined) {
      const nyPost = { tekniskNavn: post.get('tekniskNavnFraFastsetting'), beloep: post.get('beloepFraFastsetting') };
      const gammelpost = post.set('tekniskNavnFraFastsetting', undefined)
        .set('beloepFraFastsetting', undefined);
      loennPoster.push(nyPost, gammelpost);
    }

    if (!loennTilSjoefolkTekniskeNavn.includes(post.get('tekniskNavn'))) {
      loennPoster.push(post);
    }
    return null;
  })
  return loennPoster;

}