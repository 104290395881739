import React, { useRef, useState } from 'react';
import { i18n } from '../../../i18n/tekster';
import ActionButton from '@skatteetaten/frontend-components/ActionButton';
import Callout from '@skatteetaten/frontend-components/Callout';
import Styles from './postvelger.module.css';

const Postvelger = (props) => {

  const { tilgjengeligePoster, onLeggTilPost, kategoriId, disabledMessage } = props;

  const [calloutVisible, setCalloutVisible] = useState(false)
  const calloutButton = useRef(null)

  const tekniskeNavnSomKanDisables = [
    "saerfradragForLettereNedsattErvervsevne",
    "saerfradragForStoreSykdomskostnader",
  ];

  return (
    <ul className={Styles.container} role="menu">
      {tilgjengeligePoster
        .map((poster, underkategori) => (
          <React.Fragment key={`kategori.${underkategori}`}>
            <li className={Styles.tittel} role="none">
              <h3>
                {i18n(`ledetekst.kategori.${underkategori}`)}
              </h3>
            </li>
            {poster.map((post) => {
              if (
                i18n(post.get('tekniskNavn') + '.ledetekst') === null ||
                i18n(post.get('tekniskNavn') + '.ledetekst') === undefined
              ) {
                return (
                  <li className={Styles.manglendeTekst} role="alert">
                    {`MANGLER TEKST: ${post.get('tekniskNavn')}`}
                  </li>
                );
            }

            if (disabledMessage && tekniskeNavnSomKanDisables.includes(post.get('tekniskNavn'))) {
              return (
                <div key={post.get('tekniskNavn') + underkategori} ref={calloutButton}>
                  <ActionButton
                    buttonStyle="secondary"
                    icon="InfoOutline"
                    color="red"
                    aria-expanded={calloutVisible}
                    aria-haspopup
                    onClick={() => setCalloutVisible(!calloutVisible)}
                    style={{ padding: '5px', minHeight: '10px' }}
                  >
                    <div className={Styles.btnText} style={{ color: "rgb(223, 70, 97)" }}>
                      {i18n(post.get('tekniskNavn') + '.ledetekst')}
                    </div>
                  </ActionButton>

                  {calloutVisible && (
                    <Callout
                      target={calloutButton}
                      directionalHint={Callout.POS_BOTTOM_LEFT}
                      color={Callout.INFO}
                      onClose={() => setCalloutVisible(false)}
                      onDismiss={() => setCalloutVisible(false)}
                    >
                      <p>{disabledMessage}</p>
                    </Callout>
                  )}

                </div>
              )
            }
            return (
              <li key={post.get('tekniskNavn') + underkategori} role="none">
                  <ActionButton
                    onClick={() =>
                      onLeggTilPost(kategoriId, post.get('tekniskNavn'))
                    }
                    style={{ padding: '5px', minHeight: '10px' }}
                    role="menuitem"
                  >
                    <div className={Styles.btnText}>
                      {i18n(post.get('tekniskNavn') + '.ledetekst')}
                    </div>
                  </ActionButton>
                </li>
              );
            })}
          </React.Fragment>
        ))
        .toArray()}
    </ul>
  );
}

export default Postvelger;