import { byggFasteTekster } from './fasteTekster/fasteTekster';
import { byggPostTekster } from './postTekster/postTekster';
import { byggForsideTekster } from './forside/forsideTekster';

let fasteTekster = {};
let postTekster = {};
let forsideTekster = {};

export function i18n(key) {
  if (fasteTekster[key]) {
    return fasteTekster[key]
  }
  if (postTekster[key]) {
    return postTekster[key];
  }
  if (forsideTekster[key]) {
    return forsideTekster[key];
  }
  return null;
}

export function byggTekster(locale) {
  fasteTekster = Object.assign({}, byggFasteTekster(locale));
  postTekster = Object.assign({}, byggPostTekster(locale));
  forsideTekster = Object.assign({}, byggForsideTekster(locale));
}