import React from 'react';
import ActionButton from '@skatteetaten/frontend-components/ActionButton';
import { i18n } from '../../../i18n/tekster';
import Styles from './postvelgerknapp.module.css';

const PostvelgerKnapp = (props) => {

  const { postvelgerAapen, kategoriId, gruppeTittel, togglePostvelgerAapen } = props;

  const tittel = postvelgerAapen
    ? i18n('ledetekst.lukk').toLowerCase() + ' ' + i18n('ledetekst.' + gruppeTittel).toLowerCase()
    : i18n('ledetekst.legg_til').toLowerCase() + ' ' + i18n('ledetekst.' + gruppeTittel).toLowerCase();

  return (
    <ActionButton
      iconSize={ActionButton.LARGE}
      icon={postvelgerAapen ? 'Cancel' : 'AddOutline'}
      title={tittel}
      onClick={() => togglePostvelgerAapen(kategoriId)}
      aria-haspopup="true"
      aria-expanded={postvelgerAapen}
    >
      <span className={Styles.btnLabel}>
        {i18n(postvelgerAapen ? 'ledetekst.lukk' : 'ledetekst.legg_til') +
          ' ' +
          i18n('ledetekst.' + gruppeTittel).toLowerCase()}
      </span>
    </ActionButton>
  );

}

export default PostvelgerKnapp;