import React, { useEffect, useRef } from 'react';
import { byggMaaneder } from '../../../felles/byggStandardposter';
import { i18n } from '../../../i18n/tekster';
import PropTypes from 'prop-types';
import PostLabel from './../PostLabel/PostLabel';
import formatNumber from '../../../felles/formatNumber';
import Dropdown from '@skatteetaten/frontend-components/Dropdown';
import Styles from './ensligforsoerger.module.css';
import { getInntektsaar } from '../../../felles/utils';


function byggMaanederForsoerger() {
  const aar = getInntektsaar();

  if(aar <= 2022) {
    return byggMaaneder();
  }

  if(aar === 2023) {
    return [
      {
        key: '',
        text: i18n('ledetekst.velgISelectboks')
      },
      {key: 1, text: 1},
      {key: 2, text: 2}
    ]
  }

  // For årsversjon 2024 må denne posten bort fra listen :-)
  throw new Error('Særfradag enslig forsøker er ikke støttet etter 2023.');
}

export const EnsligForsoerger = (props) => {
  const {
    post,
    oppdaterPostInnhold,
    onDelete,
    saerfradragForEnsligForsoerger,
  } = props;

  const ref = useRef(null);

  useEffect(function () {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return (
    <div className="post" key={post.get('id')}>
      <PostLabel
        post={post}
        onDelete={onDelete}
        id={post.get('id')}
        ledetekst={i18n(post.get('tekniskNavn') + '.ledetekst')}
      />
      <div className={Styles.dropdownContainer}>
        <div className={Styles.dropdownInnslag}>
          <Dropdown
            componentRef={ref}
            label={i18n('ledetekst.omsorgsgrad')}
            requiredWithMark
            placeholder={i18n('ledetekst.velgISelectboks')}
            options={[
              { key: '', text: i18n('ledetekst.velgISelectboks') },
              { key: 'FULL', text: i18n('ledetekst.omsorgsgrad.type.full') },
              { key: 'DELT', text: i18n('ledetekst.omsorgsgrad.type.delt') },
            ]}
            selectedKey={post.get('omsorgsgradVedMottakAvUtvidetBarnetrygd')}
            onChange={(evt, value) =>
              oppdaterPostInnhold(
                post.set('omsorgsgradVedMottakAvUtvidetBarnetrygd', value.key)
              )
            }
          />
        </div>
        <div className={Styles.dropdownInnslag}>
          <Dropdown
            label={i18n('ledetekst.antallMaaneder')}
            requiredWithMark
            placeholder={i18n('ledetekst.velgISelectboks')}
            options={byggMaanederForsoerger()}
            selectedKey={post.get('antallMaanederMedMottakAvUtvidetBarnetrygd')}
            onChange={(evt, value) =>
              oppdaterPostInnhold(
                post.set(
                  'antallMaanederMedMottakAvUtvidetBarnetrygd',
                  value.key
                )
              )
            }
          />
        </div>
      </div>
      {saerfradragForEnsligForsoerger && (
        <div className={Styles.undertekst}>
          {i18n('ledetekst.fradrag.blir').replace(
            '{0}',
            formatNumber(saerfradragForEnsligForsoerger)
          )}
        </div>
      )}
    </div>
  );
};

EnsligForsoerger.propTypes = {
  post: PropTypes.object.isRequired,
  oppdaterPostInnhold: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  saerfradragForEnsligForsoerger: PropTypes.number,
};

EnsligForsoerger.defaultProps = {
  saerfradragForEnsligForsoerger: undefined,
};

export default EnsligForsoerger;
