import React from 'react';
import IconButton from '@skatteetaten/frontend-components/IconButton';
import TextField from '@skatteetaten/frontend-components/TextField';
import Grid from '@skatteetaten/frontend-components/Grid'
import { i18n } from '../../../i18n/tekster';
import minVerdi from '../../../validering/minVerdi';
import fjernUgyldigeTegn from '../../../validering/ugyldigeTegn';
import Styles from './reise.module.css';
import PostLabel from '../PostLabel/PostLabel';

const ReiseBesoekUtenforEU = (props) => {

  const { post, onChange, onDelete } = props;

  return (
    <>
      <div className={Styles.reiseTopText}>
        <PostLabel
          id={post.get('id')}
          post={post}
          ledetekst={i18n('ledetekst.besoeksreiseTilHjemUtenforEOeS')}/>
        <IconButton
          className={Styles.btnSlett}
          title={i18n('ledetekst.slett').toLowerCase() + ' ' + i18n(post.get('tekniskNavn') + '.ledetekst').toLowerCase()}
          icon={'delete'}
          onClick={() => onDelete({ id: post.get('id') })}/>
      </div>

      <Grid padding="0px">
        <Grid.Row>
          <Grid.Col lg={6}>
            <TextField
              type={'tel'}
              label={i18n('ledetekst.antallReiser')}
              value={post.get('antallDagerMedReiseIArbeidsforhold') || ''}
              autoFocus
              onChange={(evt, value) => {
                const nyValue = minVerdi(fjernUgyldigeTegn(value), 0) + "";
                onChange(post.set('antallDagerMedReiseIArbeidsforhold', nyValue));
              }}/>
          </Grid.Col>
          <Grid.Col lg={6}>
            <TextField
              type={'tel'}
              label={'Km'}
              value={post.get('lengdeAvReiseIArbeidsforholdTurRetur') || ''}
              onChange={(evt, value) => {
                const nyValue = minVerdi(fjernUgyldigeTegn(value), 0) + "";
                onChange(post.set('lengdeAvReiseIArbeidsforholdTurRetur', nyValue));
              }}/>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col lg={6}>
            <TextField
              type={'tel'}
              label={i18n('ledetekst.FaktiskeKostnaderReise')}
              value={post.get('faktiskeKostnaderReise') || ''}
              onChange={(evt, value) => {
                const nyValue = minVerdi(fjernUgyldigeTegn(value), 0) + "";
                onChange(post.set('faktiskeKostnaderReise', nyValue));
              }}/>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default ReiseBesoekUtenforEU;