/**
 * @prettier
 */
import { setFocusVisibility } from '@fluentui/utilities';

export default function giForrigeElementFokus(element) {
  if (!element) return;

  const forrige = findForrigeFokuserbareElement(element);

  if (forrige) {
    // Vi må gjøre dette for at knappen skal ha synlig fokus.
    // Bør fjernes når vi ikke har en transitiv avhengighet til @fluentui/utilities lenger-
    setFocusVisibility(true, forrige);
    forrige.focus();
  }
}

/**
 * Vi fant ingen standard måte å finne "forrige fokuserbare element" i DOM
 * på weben, og lagde derfor denne algoritmen i stedet:
 *
 * For et element E:
 *
 * 1. Hent elementets forrige søsken (.previousElementSibling), PES
 *    Ikke funnet: Bruk elementets parentElement som E og gå til steg 1
 * 2. Se om PES har fokuserbare barn og barnebarn.
 *    Ikke funnet: Gå til steg 1 med PES satt som E
 *    Funnet: Returner siste fokuserbare barn.
 *
 * Her implementert som en rekursiv funksjon:
 */
function findForrigeFokuserbareElement(E) {
  if (!E) return;

  const PES = E.previousElementSibling;

  if (PES === null) {
    return findForrigeFokuserbareElement(E.parentElement);
  }

  const focusableChildren = Array.from(
    PES.querySelectorAll('input, button, [tabindex="0"]')
  ).filter((n) => !n.disabled);

  if (focusableChildren.length === 0) {
    return findForrigeFokuserbareElement(PES);
  }

  // Foretrekk input-elementer, om de finnes
  const inputs = focusableChildren.filter((n) => n.nodeName === 'INPUT');
  if (inputs.length > 0) {
    return inputs[inputs.length - 1];
  }

  return focusableChildren[focusableChildren.length - 1];
}
