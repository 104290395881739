import React, { useCallback } from 'react';
import { i18n } from '../../../i18n/tekster';
import PostLabel from './../PostLabel/PostLabel';
import { ActionButton } from '@skatteetaten/frontend-components/ActionButton';
import Styles from './barnepensjon.module.css';
import Barnepensjon from './Barnepensjon';

const MAX_ANTALL_BARN = 10;

export const BarnepensjonForBarn = (props) => {

    const {
        post,
        poster,
        oppdaterPostInnhold,
        onLeggTilPost,
        kategoriId,
        onDelete,
    } = props;

    const leggTilBarn = useCallback(
        (antallBarn) => {
            if (antallBarn < MAX_ANTALL_BARN) {
                onLeggTilPost(kategoriId, 'barnepensjonFraFolketrygden');
            }
        },
        [onLeggTilPost, kategoriId],
    );

    const slettFraTekniskNavn = () => {
        onDelete({ tekniskNavn: post.get('tekniskNavn') });
    }

    return (
        <div className="post" key={'barnepensjonforbarn'}>
            <PostLabel
                id={post.get('id')}
                post={post}
                onDelete={slettFraTekniskNavn}
                ledetekst={i18n(post.get('tekniskNavn') + '.ledetekst')}
            />

            {poster.map((barnepensjon, index) => {
                return (
                    <Barnepensjon
                        key={index}
                        post={barnepensjon}
                        onChange={oppdaterPostInnhold}
                        onDelete={onDelete}
                        barnNr={index + 1}
                        antallBarn={poster.size}
                    />
                )

            })}

            <div className={Styles.btnContainer}>
                <ActionButton
                    className={Styles.leggTilBarnepensjonKnapp}
                    iconSize={ActionButton.NORMAL}
                    icon={'AddOutline'}
                    title={i18n('ledetekst.barnepensjon.leggTil.knapp').toLowerCase()}
                    onClick={() => leggTilBarn(poster.size)}
                    disabled={poster.size === MAX_ANTALL_BARN}
                >
                    {i18n('ledetekst.barnepensjon.leggTil.knapp')}
                </ActionButton>
            </div>

        </div>
    );

}

export default BarnepensjonForBarn;