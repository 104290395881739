import React from 'react';
import { i18n } from '../../../i18n/tekster';
import Reise from '../Reise/Reise';
import ActionButton from '@skatteetaten/frontend-components/ActionButton';
import PostLabel from './../PostLabel/PostLabel';
import Styles from './besoeksreise.module.css';

const Besoeksreise = (props) => {

  const {
    post,
    poster,
    onDelete,
    oppdaterPostInnhold,
    onLeggTilPost,
    kategoriId
  } = props;

  return (
    <div className="post">
      <PostLabel
        id={post.get('id')}
        post={post}
        onDelete={onDelete}
        ledetekst={i18n(post.get('tekniskNavn') + '.ledetekst')}
      />
      {poster.filter(post => post.get('tekniskNavn') === 'besoeksreise').map(besoeksreise => (
        <Reise
          key={besoeksreise.get('id')}
          post={besoeksreise}
          onChange={oppdaterPostInnhold}
          onDelete={onDelete}
        />
      ))}
      <div className={Styles.btnContainer}>
        <ActionButton
          className={Styles.leggTilReiseKnapp}
          iconSize={ActionButton.NORMAL}
          icon={'AddOutline'}
          title={i18n('ledetekst.leggTilBesoeksreise')}
          onClick={() => onLeggTilPost(kategoriId, 'besoeksreise')}
        >
          <div className={Styles.leggTilReiseKnappLabel}>
            {i18n('ledetekst.leggTilBesoeksreise')}
          </div>
        </ActionButton>
      </div>
    </div>
  )

}

export default Besoeksreise;